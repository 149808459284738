import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const SelectDropDown = ({ formEl }) => {
  return (
    <>
      {formEl.settings.isAutoSelect ? (
        <Autocomplete
          size="small"
          id="single-select-dropdown"
          options={formEl.settings.options}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField {...params} label={formEl.label} variant="outlined" />
          )}
        />
      ) : (
        <FormControl fullWidth size="small">
          <InputLabel id="simple-select-label">Select an Option</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            label="Select an Option"
          >
            {formEl.settings.options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SelectDropDown;
