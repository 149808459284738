// Import necessary libraries and icons
import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Slider,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import debounce from "lodash.debounce";
import "react-quill/dist/quill.snow.css";

// Import icons
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageIcon from "@mui/icons-material/Image";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import ShareIcon from "@mui/icons-material/Share";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CodeIcon from "@mui/icons-material/Code";
import InfoIcon from "@mui/icons-material/Info";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";

const BlockType = {
  TEXT: "text",
  IMAGE: "image",
  BUTTON: "button",
  DIVIDER: "divider",
  SPACER: "spacer",
  SOCIAL: "social",
  VIDEO: "video",
  HTML: "html",
};

const blockDefinitions = [
  {
    id: 1,
    type: BlockType.TEXT,
    label: "Text",
    icon: <TextFieldsIcon color="primary" />,
    description: "Add customizable text to your email.",
  },
  {
    id: 2,
    type: BlockType.IMAGE,
    label: "Image",
    icon: <ImageIcon color="primary" />,
    description: "Insert an image into your email.",
  },
  {
    id: 3,
    type: BlockType.BUTTON,
    label: "Button",
    icon: <SmartButtonIcon color="primary" />,
    description: "Add a clickable button to your email.",
  },
  {
    id: 4,
    type: BlockType.DIVIDER,
    label: "Divider",
    icon: <HorizontalRuleIcon color="primary" />,
    description: "Add a horizontal divider line.",
  },
  {
    id: 5,
    type: BlockType.SPACER,
    label: "Spacer",
    icon: <SpaceBarIcon color="primary" />,
    description: "Add vertical space between blocks.",
  },
  {
    id: 6,
    type: BlockType.SOCIAL,
    label: "Social Media",
    icon: <ShareIcon color="primary" />,
    description: "Include social media icons with links.",
  },
  {
    id: 7,
    type: BlockType.VIDEO,
    label: "Video",
    icon: <OndemandVideoIcon color="primary" />,
    description: "Embed a video link in your email.",
  },
  {
    id: 8,
    type: BlockType.HTML,
    label: "HTML",
    icon: <CodeIcon color="primary" />,
    description: "Add custom HTML code.",
  },
];

const templates = [
  {
    id: 1,
    title: "Registration Confirmation Email",
    description: "Confirm your registration with this email template.",
    body: "Dear {{name}},\n\nThank you for registering. Your account has been successfully created!",
  },
  {
    id: 2,
    title: "Password Reset Email",
    description: "Send password reset instructions to users.",
    body: "Hi {{name}},\n\nClick the link below to reset your password.\n\n{{reset_link}}",
  },
  {
    id: 3,
    title: "Forgot Password Email",
    description: "Remind users of their account details with this template.",
    body: "Dear {{name}},\n\nYou have requested to reset your password. Please follow the instructions.",
  },
  {
    id: 4,
    title: "OES Email Format",
    description: "Use this template for OES-related communications.",
    body: "Dear {{name}},\n\nThis is the official communication regarding OES.",
  },
];

const DraggableBlock = ({ block, index }) => (
  <Draggable draggableId={`available-${block.type}-${index}`} index={index}>
    {(provided) => (
      <Paper
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          padding: "12px",
          cursor: "move",
          marginBottom: "12px",
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)",
          "&:hover": {
            backgroundColor: "#f9f9f9",
          },
          ...provided.draggableProps.style,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {block.icon}
          <Typography variant="body1">{block.label}</Typography>
        </Box>
        <Tooltip title={block.description}>
          <InfoIcon color="action" />
        </Tooltip>
      </Paper>
    )}
  </Draggable>
);

// Enhanced EditableTextBlock with formatting options
const EditableTextBlock = ({ block, index, onDelete }) => {
  const [text, setText] = useState("Edit this text...");
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };
  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <ReactQuill
        value={text}
        onChange={setText}
        modules={modules}
        style={{ minHeight: "120px" }}
      />
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// Enhanced EditableImageBlock with alt text and link
const EditableImageBlock = ({ block, index, onDelete }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageAlt, setImageAlt] = useState("");
  const [imageLink, setImageLink] = useState("");

  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      {imageUrl ? (
        <Box>
          <a href={imageLink || "#"} target="_blank" rel="noopener noreferrer">
            <img src={imageUrl} alt={imageAlt} width="100%" />
          </a>
          <Box sx={{ marginTop: 1 }}>
            <TextField
              label="Alt Text"
              size="small"
              fullWidth
              value={imageAlt}
              onChange={(e) => setImageAlt(e.target.value)}
              sx={{ marginBottom: "8px" }}
            />
            <TextField
              label="Image Link URL"
              size="small"
              fullWidth
              value={imageLink}
              onChange={(e) => setImageLink(e.target.value)}
            />
          </Box>
        </Box>
      ) : (
        <Button variant="contained" component="label" sx={{ marginTop: "8px" }}>
          Upload Image
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) =>
              setImageUrl(URL.createObjectURL(e.target.files[0]))
            }
          />
        </Button>
      )}
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// Enhanced EditableButtonBlock with customization options
const EditableButtonBlock = ({ block, index, onDelete }) => {
  const [buttonText, setButtonText] = useState("Click Me");
  const [buttonUrl, setButtonUrl] = useState("");
  const [buttonColor, setButtonColor] = useState("#1976d2");

  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <Button
        variant="contained"
        fullWidth
        href={buttonUrl}
        sx={{
          backgroundColor: buttonColor,
          color: "#fff",
          "&:hover": {
            backgroundColor: buttonColor,
          },
        }}
      >
        {buttonText}
      </Button>
      <Box sx={{ marginTop: 1 }}>
        <TextField
          size="small"
          label="Button Text"
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          fullWidth
          sx={{ marginBottom: "8px" }}
        />
        <TextField
          size="small"
          label="Button URL"
          value={buttonUrl}
          onChange={(e) => setButtonUrl(e.target.value)}
          fullWidth
          sx={{ marginBottom: "8px" }}
        />
        <TextField
          size="small"
          label="Button Color"
          type="color"
          value={buttonColor}
          onChange={(e) => setButtonColor(e.target.value)}
          fullWidth
        />
      </Box>
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// New EditableDividerBlock
const EditableDividerBlock = ({ block, index, onDelete }) => {
  const [dividerColor, setDividerColor] = useState("#e0e0e0");
  const [dividerThickness, setDividerThickness] = useState(1);

  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <Divider
        sx={{
          borderColor: dividerColor,
          borderWidth: `${dividerThickness}px`,
        }}
      />
      <Box sx={{ marginTop: 1 }}>
        <TextField
          size="small"
          label="Divider Color"
          type="color"
          value={dividerColor}
          onChange={(e) => setDividerColor(e.target.value)}
          fullWidth
          sx={{ marginBottom: "8px" }}
        />
        <Slider
          value={dividerThickness}
          min={1}
          max={5}
          onChange={(e, newValue) => setDividerThickness(newValue)}
          valueLabelDisplay="auto"
          sx={{ width: "100%" }}
        />
      </Box>
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// New EditableSpacerBlock
const EditableSpacerBlock = ({ block, index, onDelete }) => {
  const [height, setHeight] = useState(20);

  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <Box sx={{ height: `${height}px` }} />
      <Slider
        value={height}
        min={0}
        max={100}
        onChange={(e, newValue) => setHeight(newValue)}
        valueLabelDisplay="auto"
        sx={{ width: "100%", marginTop: 1 }}
      />
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// New EditableSocialMediaBlock
const EditableSocialMediaBlock = ({ block, index, onDelete }) => {
  const [links, setLinks] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    pinterest: "",
  });

  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
        {links.facebook && (
          <IconButton
            component="a"
            href={links.facebook}
            target="_blank"
            rel="noopener"
          >
            <FacebookIcon color="primary" />
          </IconButton>
        )}
        {links.twitter && (
          <IconButton
            component="a"
            href={links.twitter}
            target="_blank"
            rel="noopener"
          >
            <TwitterIcon color="primary" />
          </IconButton>
        )}
        {links.linkedin && (
          <IconButton
            component="a"
            href={links.linkedin}
            target="_blank"
            rel="noopener"
          >
            <LinkedInIcon color="primary" />
          </IconButton>
        )}
        {links.instagram && (
          <IconButton
            component="a"
            href={links.instagram}
            target="_blank"
            rel="noopener"
          >
            <InstagramIcon color="primary" />
          </IconButton>
        )}
        {links.youtube && (
          <IconButton
            component="a"
            href={links.youtube}
            target="_blank"
            rel="noopener"
          >
            <YouTubeIcon color="primary" />
          </IconButton>
        )}
        {links.pinterest && (
          <IconButton
            component="a"
            href={links.pinterest}
            target="_blank"
            rel="noopener"
          >
            <PinterestIcon color="primary" />
          </IconButton>
        )}
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <TextField
          label="Facebook URL"
          size="small"
          fullWidth
          value={links.facebook}
          onChange={(e) => setLinks({ ...links, facebook: e.target.value })}
          sx={{ marginBottom: "8px" }}
        />
        <TextField
          label="Twitter URL"
          size="small"
          fullWidth
          value={links.twitter}
          onChange={(e) => setLinks({ ...links, twitter: e.target.value })}
          sx={{ marginBottom: "8px" }}
        />
        <TextField
          label="LinkedIn URL"
          size="small"
          fullWidth
          value={links.linkedin}
          onChange={(e) => setLinks({ ...links, linkedin: e.target.value })}
          sx={{ marginBottom: "8px" }}
        />
        <TextField
          label="Instagram URL"
          size="small"
          fullWidth
          value={links.instagram}
          onChange={(e) => setLinks({ ...links, instagram: e.target.value })}
          sx={{ marginBottom: "8px" }}
        />
        <TextField
          label="YouTube URL"
          size="small"
          fullWidth
          value={links.youtube}
          onChange={(e) => setLinks({ ...links, youtube: e.target.value })}
          sx={{ marginBottom: "8px" }}
        />
        <TextField
          label="Pinterest URL"
          size="small"
          fullWidth
          value={links.pinterest}
          onChange={(e) => setLinks({ ...links, pinterest: e.target.value })}
        />
      </Box>
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// New EditableVideoBlock
const EditableVideoBlock = ({ block, index, onDelete }) => {
  const [videoUrl, setVideoUrl] = useState("");

  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      {videoUrl ? (
        <Box sx={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
          <iframe
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            title="Embedded video"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </Box>
      ) : (
        <TextField
          label="Video Embed URL"
          size="small"
          fullWidth
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
        />
      )}
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

// New EditableHTMLBlock
const EditableHTMLBlock = ({ block, index, onDelete }) => {
  const [htmlContent, setHtmlContent] = useState("");

  return (
    <Box sx={{ marginBottom: "16px", position: "relative" }}>
      <ReactQuill
        value={htmlContent}
        onChange={setHtmlContent}
        modules={{ toolbar: [["code"]] }}
        formats={["code"]}
        style={{ minHeight: "120px" }}
      />
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => onDelete(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const BlockRenderer = ({ block, index, onDelete }) => {
  switch (block.type) {
    case BlockType.TEXT:
      return (
        <EditableTextBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.IMAGE:
      return (
        <EditableImageBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.BUTTON:
      return (
        <EditableButtonBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.DIVIDER:
      return (
        <EditableDividerBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.SPACER:
      return (
        <EditableSpacerBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.SOCIAL:
      return (
        <EditableSocialMediaBlock
          block={block}
          index={index}
          onDelete={onDelete}
        />
      );
    case BlockType.VIDEO:
      return (
        <EditableVideoBlock block={block} index={index} onDelete={onDelete} />
      );
    case BlockType.HTML:
      return (
        <EditableHTMLBlock block={block} index={index} onDelete={onDelete} />
      );
    default:
      return null;
  }
};

const EmailCanvas = ({ blocks, onDelete }) => {
  return (
    <Droppable droppableId="canvas">
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          sx={{
            padding: "20px",
            minHeight: "400px",
            backgroundColor: snapshot.isDraggingOver ? "#fafafa" : "#fff",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)",
          }}
          {...provided.droppableProps}
        >
          {blocks.length === 0 ? (
            <Typography variant="body2" align="center" color="textSecondary">
              Drag blocks from the left to add them here
            </Typography>
          ) : (
            blocks.map((block, index) => (
              <Draggable
                key={block.id}
                draggableId={`canvas-${block.id}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <BlockRenderer
                      block={block}
                      index={index}
                      onDelete={onDelete}
                    />
                  </div>
                )}
              </Draggable>
            ))
          )}
          {provided.placeholder}
        </Paper>
      )}
    </Droppable>
  );
};

const BlockBasedEmailTemplateBuilder = () => {
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [emailMeta, setEmailMeta] = useState({
    subject: "",
    fromEmail: "",
    fromName: "",
  });

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (
      source.droppableId === "canvas" &&
      destination.droppableId === "canvas"
    ) {
      const reorderedBlocks = Array.from(blocks);
      const [removed] = reorderedBlocks.splice(source.index, 1);
      reorderedBlocks.splice(destination.index, 0, removed);
      setBlocks(reorderedBlocks);
      saveHistory(reorderedBlocks);
    }
    if (
      source.droppableId === "availableBlocks" &&
      destination.droppableId === "canvas"
    ) {
      const availableBlock = blockDefinitions[source.index];
      const newBlock = { ...availableBlock, id: Date.now() }; // Generate unique ID
      const newBlocks = [...blocks];
      newBlocks.splice(destination.index, 0, newBlock);
      setBlocks(newBlocks);
      saveHistory(newBlocks);
    }
  };

  const handleDeleteBlock = (index) => {
    const newBlocks = blocks.filter((_, i) => i !== index);
    setBlocks(newBlocks);
    saveHistory(newBlocks);
  };

  const saveHistory = debounce((newBlocks) => {
    setHistory((prevHistory) => [...prevHistory, blocks]);
    setRedoStack([]);
  }, 300);

  const undo = () => {
    if (history.length > 0) {
      const prevBlocks = history[history.length - 1];
      setRedoStack((prevRedo) => [...prevRedo, blocks]);
      setBlocks(prevBlocks);
      setHistory((prevHistory) => prevHistory.slice(0, -1));
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const redoBlocks = redoStack[redoStack.length - 1];
      setHistory((prevHistory) => [...prevHistory, blocks]);
      setBlocks(redoBlocks);
      setRedoStack((prevRedo) => prevRedo.slice(0, -1));
    }
  };

  const handleDownloadTemplate = () => {
    const template = {
      subject: emailMeta.subject,
      fromEmail: emailMeta.fromEmail,
      fromName: emailMeta.fromName,
      blocks,
    };
    const blob = new Blob([JSON.stringify(template, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "email_template.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" gutterBottom>
            Blocks
          </Typography>
          <Droppable droppableId="availableBlocks" isDropDisabled={true}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {blockDefinitions.map((block, index) => (
                  <DraggableBlock key={block.id} block={block} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Email Template Builder</Typography>
              <Box>
                <Tooltip
                  title={history.length === 0 ? "Nothing to undo" : "Undo"}
                >
                  <span>
                    <IconButton onClick={undo} disabled={history.length === 0}>
                      <UndoIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={redoStack.length === 0 ? "Nothing to redo" : "Redo"}
                >
                  <span>
                    <IconButton
                      onClick={redo}
                      disabled={redoStack.length === 0}
                    >
                      <RedoIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadTemplate}
                  sx={{ marginLeft: 2 }}
                >
                  Download
                </Button>
              </Box>
            </Box>
            <FormControl fullWidth size="small">
              <InputLabel>Select Template</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                label="Select Template"
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              size="small"
              label="Subject"
              value={emailMeta.subject}
              onChange={(e) =>
                setEmailMeta({ ...emailMeta, subject: e.target.value })
              }
              fullWidth
            />
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                label="From Name"
                value={emailMeta.fromName}
                onChange={(e) =>
                  setEmailMeta({ ...emailMeta, fromName: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="From Email"
                size="small"
                value={emailMeta.fromEmail}
                onChange={(e) =>
                  setEmailMeta({ ...emailMeta, fromEmail: e.target.value })
                }
                fullWidth
              />
            </Stack>
            <EmailCanvas blocks={blocks} onDelete={handleDeleteBlock} />
          </Stack>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default BlockBasedEmailTemplateBuilder;
