import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Divider,
} from "@mui/material";
import {
  CloudUpload,
  GetApp,
  AddCircle,
  Delete,
  Category,
  Save,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import BackBtn from "../../widgets/BackBtn";

const UploadCategoryPage = () => {
  // State variables
  const [categoryType, setCategoryType] = useState("single");
  const [uploadedData, setUploadedData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manualCategory, setManualCategory] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Select Category Type",
    "Upload or Add Categories",
    "Review and Submit",
  ];

  // Handle category type change
  const handleCategoryTypeChange = (event) => {
    setCategoryType(event.target.value);
    // Reset data when category type changes
    setUploadedData([]);
    setCategories([]);
    setManualCategory({});
  };

  // Handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Read the Excel file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        // Assuming data is in the first sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setUploadedData(jsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  // Handle download template
  const handleDownloadTemplate = () => {
    let data = [];
    if (categoryType === "single") {
      data = [{ Category: "Example Category" }];
    } else if (categoryType === "double") {
      data = [{ MainCategory: "Example Main", SubCategory: "Example Sub" }];
    } else if (categoryType === "triple") {
      data = [
        {
          MainCategory: "Example Main",
          SubCategory: "Example Sub",
          SubSubCategory: "Example Sub-Sub",
        },
      ];
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
    XLSX.writeFile(workbook, `Category_Template_${categoryType}.xlsx`);
  };

  // Handle manual category input change
  const handleManualInputChange = (field, value) => {
    setManualCategory((prev) => ({ ...prev, [field]: value }));
  };

  // Handle add category manually
  const handleAddManualCategory = () => {
    if (Object.keys(manualCategory).length === 0) return;
    setCategories((prev) => [...prev, manualCategory]);
    setManualCategory({});
  };

  // Handle remove category
  const handleRemoveCategory = (index) => {
    setCategories((prev) => prev.filter((_, i) => i !== index));
  };

  // Handle submit
  const handleSubmit = () => {
    // Combine uploadedData and categories
    const allCategories = [...uploadedData, ...categories];
    // TODO: Submit allCategories to the system (e.g., API call)
    console.log("Submitting categories:", allCategories);
    // Reset states
    setUploadedData([]);
    setCategories([]);
    setManualCategory({});
    setActiveStep(0);
  };

  // Handle next and back steps
  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Box>
      <BackBtn />
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Upload Product Category List
        </Typography>

        {/* Note */}
        <Alert severity="info" sx={{ mb: 3 }}>
          These categories will be used across the system wherever product
          categories are required for both display and user input.
        </Alert>

        {/* Stepper */}
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Step Content */}
        {activeStep === 0 && (
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Select Category Type
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="category-type-label">Category Type</InputLabel>
                <Select
                  labelId="category-type-label"
                  id="category-type-select"
                  value={categoryType}
                  label="Category Type"
                  onChange={handleCategoryTypeChange}
                >
                  <MenuItem value="single">Single Category List</MenuItem>
                  <MenuItem value="double">Double Category List</MenuItem>
                  <MenuItem value="triple">Triple Category List</MenuItem>
                </Select>
              </FormControl>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                endIcon={<Category />}
                onClick={handleNext}
                disabled={!categoryType}
              >
                Next
              </Button>
            </CardActions>
          </Card>
        )}

        {activeStep === 1 && (
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Upload or Add Categories
              </Typography>
              <Grid container spacing={2}>
                {/* Download Template Button */}
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GetApp />}
                    onClick={handleDownloadTemplate}
                  >
                    Download Excel Template
                  </Button>
                </Grid>

                {/* File Upload */}
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    variant="outlined"
                    component="label"
                    startIcon={<CloudUpload />}
                  >
                    Upload Excel File
                    <input
                      type="file"
                      hidden
                      accept=".xlsx, .xls"
                      onChange={handleFileUpload}
                    />
                  </Button>
                </Grid>
              </Grid>

              <Divider sx={{ my: 3 }} />

              {/* Manual Form Input */}
              <Typography variant="h6" gutterBottom>
                Add Categories Manually
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {categoryType === "single" && (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Category"
                      fullWidth
                      value={manualCategory.Category || ""}
                      onChange={(e) =>
                        handleManualInputChange("Category", e.target.value)
                      }
                    />
                  </Grid>
                )}
                {categoryType === "double" && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Main Category"
                        fullWidth
                        value={manualCategory.MainCategory || ""}
                        onChange={(e) =>
                          handleManualInputChange(
                            "MainCategory",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Subcategory"
                        fullWidth
                        value={manualCategory.SubCategory || ""}
                        onChange={(e) =>
                          handleManualInputChange("SubCategory", e.target.value)
                        }
                      />
                    </Grid>
                  </>
                )}
                {categoryType === "triple" && (
                  <>
                    <Grid item xs={12} sm={4} md={3}>
                      <TextField
                        label="Main Category"
                        fullWidth
                        value={manualCategory.MainCategory || ""}
                        onChange={(e) =>
                          handleManualInputChange(
                            "MainCategory",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                      <TextField
                        label="Subcategory"
                        fullWidth
                        value={manualCategory.SubCategory || ""}
                        onChange={(e) =>
                          handleManualInputChange("SubCategory", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                      <TextField
                        label="Sub-Subcategory"
                        fullWidth
                        value={manualCategory.SubSubCategory || ""}
                        onChange={(e) =>
                          handleManualInputChange(
                            "SubSubCategory",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddCircle />}
                    onClick={handleAddManualCategory}
                    fullWidth
                    disabled={
                      categoryType === "single" && !manualCategory.Category
                    }
                  >
                    Add Category
                  </Button>
                </Grid>
              </Grid>

              {/* Uploaded Data Preview */}
              {(uploadedData.length > 0 || categories.length > 0) && (
                <>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" gutterBottom>
                    Review Categories
                  </Typography>
                  <Paper
                    variant="outlined"
                    sx={{ p: 2, maxHeight: 200, overflow: "auto" }}
                  >
                    {[...uploadedData, ...categories].map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: 1,
                        }}
                      >
                        <Typography variant="body1">
                          {Object.values(item).join(" > ")}
                        </Typography>
                        {index >= uploadedData.length && (
                          <IconButton
                            color="error"
                            onClick={() =>
                              handleRemoveCategory(index - uploadedData.length)
                            }
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                  </Paper>
                </>
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                onClick={handleBack}
                startIcon={<Category />}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                endIcon={<Category />}
                disabled={uploadedData.length === 0 && categories.length === 0}
              >
                Next
              </Button>
            </CardActions>
          </Card>
        )}

        {activeStep === 2 && (
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Review and Submit
              </Typography>
              <Paper
                variant="outlined"
                sx={{ p: 2, maxHeight: 300, overflow: "auto" }}
              >
                {[...uploadedData, ...categories].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography variant="body1">
                      {Object.values(item).join(" > ")}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                onClick={handleBack}
                startIcon={<Category />}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                endIcon={<Save />}
              >
                Submit Categories
              </Button>
            </CardActions>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default UploadCategoryPage;
