import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../utils/constants";
import MuPb from "../../widgets/MuPb";
import {
  Alert,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getErrorResponse } from "../../utils/util";
import BackBtn from "../../widgets/BackBtn";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import SimpleYesNoDialog from "../../widgets/SimpleYesNoDialog";

const ExhibitorDetail = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}user/a/get-exhibitor?id=${id}`
  );
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  const {
    user_id,
    username,
    companyName,
    contactSalutation,
    contactFirstName,
    contactLastName,
    contactDesignation,
    mobile,
    companyEmail,
    contactEmail,
    address1,
    address2,
    address3,
    country,
    state,
    city,
    pin,
    phone,
    fax,
    website,
    companyShortName,
    fascia,
    weAre,
    industrySector,
    exhibitorCategory,
    productCategories,
    hallNumber,
    standNumber,
    spaceType,
    exhibitArrival,
    exhibitArrivalTimeFrom,
    exhibitArrivalTimeTo,
    materialArrival,
    materialArrivalTimeFrom,
    materialArrivalTimeTo,
    dismantling,
    dismantlingTimeFrom,
    dismantlingTimeTo,
    standSize,
    profile_image,
    profile_banner,
  } = data;

  // Helper function to display values or "NA"
  const displayValue = (value) =>
    value !== null && value !== "" ? value : "NA";

  return (
    <Box>
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete this exhibitor?`}
        onYes={() => {
          setDeleting(true);
          setShowDelete(false);
          setTimeout(() => {
            setDeleting(false);
          }, 1000);
        }}
        show={showDelete}
        setShow={setShowDelete}
      />
      <BackBtn />
      <Container>
        <Card>
          {/* Profile Banner */}
          {profile_banner ? (
            <CardMedia
              component="img"
              height="200"
              image={profile_banner}
              alt="Profile Banner"
            />
          ) : (
            <Box sx={{ height: 200, backgroundColor: "grey.300" }} />
          )}
          {/* Profile Avatar */}
          <Box display="flex" justifyContent="center">
            <Avatar
              src={profile_image || ""}
              sx={{
                width: 120,
                height: 120,
                marginTop: "-60px",
                border: "3px solid white",
              }}
            />
          </Box>
          <CardContent>
            <Grid container spacing={2}>
              {/* Company Name and Contact Person */}
              <Grid item xs={12}>
                <Typography variant="h5">
                  {displayValue(companyName?.value)}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {`${displayValue(contactSalutation?.value)} ${displayValue(
                    contactFirstName?.value
                  )} ${displayValue(contactLastName?.value)}`}
                </Typography>
                <Typography variant="subtitle2">
                  {displayValue(contactDesignation?.value)}
                </Typography>
              </Grid>
              {/* Contact Details */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Mobile:</strong> {displayValue(mobile?.value)}
                </Typography>
                <Typography variant="body1">
                  <strong>Company Email:</strong>{" "}
                  {displayValue(companyEmail?.value)}
                </Typography>
                <Typography variant="body1">
                  <strong>Contact Email:</strong>{" "}
                  {displayValue(contactEmail?.value)}
                </Typography>
                <Typography variant="body1">
                  <strong>Phone:</strong> {displayValue(phone?.value)}
                </Typography>
                <Typography variant="body1">
                  <strong>Fax:</strong> {displayValue(fax?.value)}
                </Typography>
                <Typography variant="body1">
                  <strong>Website:</strong>{" "}
                  {website?.value ? (
                    <a
                      href={website.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {website.value}
                    </a>
                  ) : (
                    "NA"
                  )}
                </Typography>
              </Grid>
              {/* Address */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Address:</strong>{" "}
                  {[
                    displayValue(address1?.value),
                    displayValue(address2?.value),
                    displayValue(address3?.value),
                  ]
                    .filter((addr) => addr !== "NA")
                    .join(", ")}
                  {city?.value?.name && `, ${city.value.name}`}
                  {state?.value?.name && `, ${state.value.name}`}
                  {country?.value?.name && `, ${country.value.name}`}
                  {pin?.value && ` - ${pin.value}`}
                </Typography>
                {country?.value?.flag ? (
                  <img
                    src={country.value.flag}
                    alt={`${country.value.name} flag`}
                    style={{ width: "30px", marginTop: "5px" }}
                  />
                ) : (
                  <Typography variant="body2">Flag: NA</Typography>
                )}
              </Grid>
              {/* Additional Details */}
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {/* Company Details */}
                      <TableRow>
                        <TableCell>
                          <strong>User ID</strong>
                        </TableCell>
                        <TableCell>{displayValue(user_id)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Username</strong>
                        </TableCell>
                        <TableCell>{displayValue(username)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Company Short Name</strong>
                        </TableCell>
                        <TableCell>
                          {displayValue(companyShortName?.value)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Fascia</strong>
                        </TableCell>
                        <TableCell>{displayValue(fascia?.value)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>We Are</strong>
                        </TableCell>
                        <TableCell>{displayValue(weAre?.value)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Exhibitor Category</strong>
                        </TableCell>
                        <TableCell>
                          {displayValue(exhibitorCategory?.value)}
                        </TableCell>
                      </TableRow>
                      {/* Booth Details */}
                      <TableRow>
                        <TableCell>
                          <strong>Hall Number</strong>
                        </TableCell>
                        <TableCell>{displayValue(hallNumber?.value)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Stand Number</strong>
                        </TableCell>
                        <TableCell>
                          {displayValue(standNumber?.value)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Space Type</strong>
                        </TableCell>
                        <TableCell>{displayValue(spaceType?.value)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Stand Size</strong>
                        </TableCell>
                        <TableCell>{displayValue(standSize?.value)}</TableCell>
                      </TableRow>
                      {/* Arrival and Dismantling Dates */}
                      <TableRow>
                        <TableCell>
                          <strong>Exhibit Arrival</strong>
                        </TableCell>
                        <TableCell>
                          {exhibitArrival?.value
                            ? new Date(exhibitArrival.value).toLocaleString()
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Exhibit Arrival Time From</strong>
                        </TableCell>
                        <TableCell>
                          {exhibitArrivalTimeFrom?.value || "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Exhibit Arrival Time To</strong>
                        </TableCell>
                        <TableCell>
                          {exhibitArrivalTimeTo?.value || "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Material Arrival</strong>
                        </TableCell>
                        <TableCell>
                          {materialArrival?.value
                            ? new Date(materialArrival.value).toLocaleString()
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Material Arrival Time From</strong>
                        </TableCell>
                        <TableCell>
                          {materialArrivalTimeFrom?.value || "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Material Arrival Time To</strong>
                        </TableCell>
                        <TableCell>
                          {materialArrivalTimeTo?.value || "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Dismantling</strong>
                        </TableCell>
                        <TableCell>
                          {dismantling?.value
                            ? new Date(dismantling.value).toLocaleString()
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Dismantling Time From</strong>
                        </TableCell>
                        <TableCell>
                          {dismantlingTimeFrom?.value || "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Dismantling Time To</strong>
                        </TableCell>
                        <TableCell>
                          {dismantlingTimeTo?.value || "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {/* Industry Sector */}
              <Grid item xs={12}>
                <Typography variant="h6">Industry/Sector</Typography>
                {industrySector?.value && industrySector.value.length > 0 ? (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {industrySector.value.map((sector, index) => (
                      <Chip key={index} label={sector} />
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body2">NA</Typography>
                )}
              </Grid>
              {/* Product Categories */}
              <Grid item xs={12}>
                <Typography variant="h6">Product Categories</Typography>
                {productCategories?.value &&
                productCategories.value.length > 0 ? (
                  <List>
                    {productCategories.value.map((product, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={product.subSubCat || "NA"} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2">NA</Typography>
                )}
              </Grid>
            </Grid>

            <Box display={"flex"} gap={4} justifyContent={"center"} mt={2}>
              <LoadingButton
                size="small"
                loading={deleting}
                color="error"
                variant="outlined"
                startIcon={<DeleteOutlineIcon />}
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                Delete
              </LoadingButton>
              <Link to={`/home/edit-exhibitor-form/${id}`}>
                <LoadingButton
                  size="small"
                  variant="outlined"
                  startIcon={<EditIcon fontSize="small" />}
                >
                  Edit
                </LoadingButton>
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ExhibitorDetail;
