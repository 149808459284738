import React from "react";
import {
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
  Stack,
  Box,
} from "@mui/material";
import bgBanner from "../assets/gifs/ExpoBG.gif";
import { Link, useNavigate } from "react-router-dom";
import WorldMapChart from "./WorldMapChat";
import FlagIcon from "@mui/icons-material/Flag";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../utils/constants";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import MonthwiseRegistrationsCard from "./graphs/MonthwiseRegistrationsCard";
import CountrywiseRegistrationsCard from "./graphs/CountryWiseRegistrationsCard";
import CategorywiseRegistrationsCard from "./graphs/CategorywiseRegistrationsCard";
import DesignationwiseRegistrationsCard from "./graphs/DesignationWiseChart";
import IndustrySectorwiseRegistrationsCard from "./graphs/IndustrySectorwiseRegistrationsCard";

const Dashboard = () => {
  const navigate = useNavigate();
  const { data } = useCommonGetInit(`${API_ENDPOINT}user/a/get-visitors-count`);
  const { data: exhibitorCount } = useCommonGetInit(
    `${API_ENDPOINT}user/a/get-exhibitors-count`
  );
  const { data: countrywiseData } = useCommonGetInit(
    `${API_ENDPOINT}user/a/get-countrywise-registrations`
  );
  return (
    <div>
      <Grid container spacing={2}>
        <Grid xs={12} item md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent
                  sx={{
                    backgroundImage: `url(${bgBanner})`,
                    backgroundSize: "cover",
                    height: 192,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundRepeat: "no-repeat",
                    color: "white",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Welcome!
                  </Typography>
                  <Typography fontSize={17} variant="body1">
                    ExpoPlanner Manage Admin
                  </Typography>
                  <Typography mt={2} variant="body2">
                    Manage Exhibitors, Visitors, Regisrations, OES, Floor Plan
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 192,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Exhibitors
                  </Typography>
                  <Typography variant="h3" component="div" color="primary">
                    {exhibitorCount
                      ? exhibitorCount.count?.toLocaleString()
                      : "..."}
                  </Typography>
                  <Link to={"/home/exhibitors-list"}>
                    <Typography mt={1}>View List</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 192,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Visitors
                  </Typography>
                  <Typography variant="h3" component="div" color="primary">
                    {data ? data.count?.toLocaleString() : "..."}
                  </Typography>
                  <Link to={"/home/visitors-list"}>
                    <Typography mt={1}>View List</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#f5f5f5", // Light gray for Team Members
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Inventory2Icon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Product Uploads
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#e0f7fa", // Light blue for Countries
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <FlagIcon sx={{ fontSize: 40, color: "primary.main" }} />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Countries
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        {countrywiseData?.registrations.filter(
                          (item) => item.country
                        )?.length || 0}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#fff9c4", // Light yellow for Active Exhibitors
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <AirplanemodeActiveIcon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Active Exhibitors
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card>
                <CardContent
                  sx={{
                    height: 96,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#ffebee", // Light red for Inactive Exhibitors
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <AirplanemodeInactiveIcon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="body1" textAlign={"center"}>
                        Inactive Exhibitors
                      </Typography>
                      <Typography variant="h4" textAlign={"center"}>
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ height: 304 }}>
            <CardContent>
              <Typography mt={2} variant="h6" textAlign={"center"}>
                Current Event: <b>IMTEX 2025</b>
              </Typography>
              <Typography mt={2} variant="h6" textAlign={"center"}>
                <Link to={"/home/events-list"}> View Past Events</Link>
              </Typography>
              <Box mt={3} display={"flex"} justifyContent={"center"}>
                <Button
                  onClick={() => navigate("/home/add-event")}
                  startIcon={<BorderColorIcon />}
                  size="small"
                  variant="outlined"
                >
                  Add New Event
                </Button>
              </Box>
              <Typography mt={2} textAlign={"center"} variant="body1">
                You can create new event or clone from or import/export data
                from the previous events
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid mt={2} item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MonthwiseRegistrationsCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack direction={"row"}>
                    <Typography flex={1} variant="h6">
                      Distributions by country
                    </Typography>
                  </Stack>

                  <WorldMapChart />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <CountrywiseRegistrationsCard />
            </Grid>
            <Grid item xs={12}>
              <CategorywiseRegistrationsCard />
            </Grid>
            <Grid item xs={12}>
              <DesignationwiseRegistrationsCard />
            </Grid>
            <Grid item xs={12}>
              <IndustrySectorwiseRegistrationsCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
