import { Box, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BackBtn from "../../widgets/BackBtn";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FileStorage = () => {
  const [ctab, setctab] = useState(0);
  const navigate = useNavigate();
  const handleChangeTab = (event, newValue) => {
    setctab(newValue);
  };
  return (
    <Box>
      <BackBtn />
      <Container>
        <Grid container>
          <Grid sm={6} item>
            <Typography variant="h6">File Storage</Typography>
          </Grid>
          <Grid sm={6} item>
            <LoadingButton
              onClick={() => navigate("/home/upload-files")}
              startIcon={<CloudUploadIcon />}
              variant="outlined"
            >
              Upload Files
            </LoadingButton>
          </Grid>
        </Grid>

        <Tabs value={ctab} onChange={handleChangeTab}>
          <Tab sx={{ textTransform: "none" }} label="PDFs" />
          <Tab sx={{ textTransform: "none" }} label="Images" />
        </Tabs>
      </Container>
    </Box>
  );
};

export default FileStorage;
