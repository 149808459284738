import { AppBar, Toolbar } from "@mui/material";
import React from "react";

const OnsiteHeader = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: "#054054" }}>
      <Toolbar style={{ justifyContent: "center" }}>
        <img
          alt="header"
          src="https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Banner_Visitor+registration.png"
          style={{
            display: "block",
            maxWidth: "100%",
            height: "auto",
            maxHeight: "120px",
            borderRadius: "8px",
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default OnsiteHeader;
