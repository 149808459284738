// SearchBar.js
import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

function SearchBar({ value, onChange, placeholder, ...props }) {
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={placeholder || "Search..."}
      variant="outlined"
      fullWidth
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBar;
