import { Box, Button } from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";

const EmailBuilder = () => {
  const [value, setValue] = useState("");
  const quillRef = useRef(null);

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("image", file);

      // Replace with your API endpoint
      const response = await fetch(
        "http://localhost:5002/user/upload-image-file-tmp",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      const imageUrl = data.url;
      console.log(imageUrl);

      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      editor.insertEmbed(range.index, "image", imageUrl);
    };
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const onSubmit = async () => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    console.log(range, range.index);
    editor.insertEmbed(
      range.index,
      "image",
      "https://www.prabhatkhabar.com/wp-content/uploads/2024/07/tripti-dimri-photoshoot.jpg"
    );

    // try {
    //   const { data } = await axios.post(
    //     "http://localhost:5002/event/email-test",
    //     { email: value }
    //   );
    //   console.log(data);
    // } catch (error) {}
  };
  return (
    <Box m={2}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={setValue}
      />
      <Box mt={2} display={"flex"} justifyContent={"end"}>
        <Button onClick={onSubmit} variant="contained">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default EmailBuilder;
