import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef } from "react";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { BadgeToPrint } from "./BadgeToPrint";
import SaveIcon from "@mui/icons-material/Save";

const BadgeBuilder = () => {
  const fileRef = useRef();
  const fileClick = () => {
    fileRef.current.click();
  };

  const onSave = () => {};
  return (
    <Box>
      <input ref={fileRef} type="file" style={{ display: "none" }} />
      <Grid container spacing={2}>
        <Grid item md={7}>
          <Box display={"flex"} justifyContent={"center"}>
            <BadgeToPrint
              user={{
                firstName: `{name}`,
                lastName: "",
                companyName: `{company}`,
              }}
              isPreview={true}
            />
          </Box>
        </Grid>
        <Grid item md={5}>
          <Box p={2}>
            <Typography variant="h5" sx={{ mb: 4 }} textAlign={"center"}>
              Badge Update
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              label="Width(in cm)"
              fullWidth
            />
            <TextField
              sx={{ mt: 3 }}
              variant="outlined"
              size="small"
              label="Height(in cm)"
              fullWidth
            />
            <TextField
              sx={{ mt: 3 }}
              variant="outlined"
              size="small"
              label="Distance of text from top(in cm)"
              fullWidth
            />
            <Button
              onClick={fileClick}
              fullWidth
              sx={{ mt: 3 }}
              variant="outlined"
              startIcon={<FilePresentIcon />}
            >
              Upload bg image icon
            </Button>
            <Button
              onClick={onSave}
              variant="contained"
              fullWidth
              sx={{ mt: 3 }}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BadgeBuilder;
