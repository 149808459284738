import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const OtpInput = ({ onComplete }) => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const inputRefs = useRef(new Array(4).fill(null));

  useEffect(() => {
    // Check if all OTP fields are filled
    if (otp.every((num) => num.length === 1)) {
      onComplete(otp.join(""));
    }
  }, [otp, onComplete]); // Dependency on otp and onComplete to trigger the effect

  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return; // Ensure only numbers are entered

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus next input on valid input, focus previous if backspace on empty field
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyUp = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      {otp.map((data, index) => (
        <TextField
          key={index}
          type="text"
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onFocus={(e) => e.target.select()}
          onKeyUp={(e) => handleKeyUp(e, index)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: "center", width: "50px" },
            ref: (el) => (inputRefs.current[index] = el),
          }}
          variant="outlined"
        />
      ))}
    </Box>
  );
};

export default OtpInput;
