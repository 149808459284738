import React from "react";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
export class ImtexBadgeDimension extends React.Component {
  render() {
    const { row } = this.props;
    const vCard = row.barcode_id;
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
          >
            {row.firstName?.toUpperCase()} {row.lastName?.toUpperCase()}
          </div>
          <div style={{ fontSize: "19px", marginTop: "12px" }}>
            {row.companyName?.toUpperCase()}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <QRCode value={vCard} size={130} />
        </div>
      </div>
    );
  }
}
export const printRow = (row) => {
  const printableContent = ReactDOMServer.renderToString(
    <ImtexBadgeDimension row={row} />
  );
  const printWindow = window.open("", "_blank");
  printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
  printWindow.document.close();
  printWindow.focus(); // For some browsers to trigger the print dialog
  printWindow.print();
  printWindow.close();
};
