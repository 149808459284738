import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import axios from "axios";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import { getHeader, toastError } from "../../utils/util";
import { toast } from "react-toastify";

export default function StandZoneForm({
  show,
  setShow,
  color,
  strokeWidth,
  hallId,
  shape,
  onUpdated,
  resetDrawing,
}) {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setName("");
  }, [show]);
  const addStand = async (eventId, hallId, name, shape) => {
    setSaving(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}admin/add-stand`,
        {
          eventId: eventId,
          hallId,
          color: color,
          strokeWidth: strokeWidth,
          name: name,
          shape: shape,
        },
        getHeader(ADMIN)
      );
      toast.success(data.message);
      onUpdated();
    } catch (error) {
      toastError(error);
    } finally {
      setSaving(false);
      resetDrawing();
    }
  };
  const onSave = () => {
    if (name === "") {
      toast.error("Please enter stand name");
    } else {
      addStand(1, hallId, name, shape);
    }
  };
  return (
    <Box p={2} pl={4} pr={4} component="form" noValidate autoComplete="off">
      <TextField
        required
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        id="stand-zone-name"
        label="Stand/Zone Name"
        variant="outlined"
      />
      <FormGroup>
        <FormControlLabel control={<Checkbox />} label="Conference Room" />
        <FormControlLabel control={<Checkbox />} label="Check-in zone" />
        <FormControlLabel control={<Checkbox />} label="Lounge Meeting Room" />
        <FormControlLabel control={<Checkbox />} label="Buyer Lounge" />
      </FormGroup>
      <FormControlLabel control={<Switch />} label="Hide on Front-end" />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          onClick={() => setShow(false)}
          variant="outlined"
          color="inherit"
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={onSave}
          loading={saving}
          variant="contained"
          color="primary"
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
}
