import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import BackBtn from "../../widgets/BackBtn";

const NotifyExhibitorsUpdateProfile = () => {
  const [message, setMessage] = useState(`Dear Exhibitor,

  We hope this message finds you well.
  
  As we prepare for the upcoming event, we kindly request that you take a moment to review and update your exhibitor profile to ensure all relevant information is up-to-date. Having accurate details will help us provide a smooth experience for both attendees and exhibitors, while enhancing your visibility and engagement at the event.
  
  Please update the following details as necessary:
  
  Company Information
  Products and Services
  Contact Information
  Any other relevant details
  Your prompt action on this matter would be greatly appreciated. If you have any questions or require assistance, please do not hesitate to reach out.
  
  Thank you for your cooperation, and we look forward to your continued participation.`);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message) {
      toast.error("Please enter the message here");
    }
    toast.success("Your message is submitted successfully");
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        mx: "auto",
        p: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        mt: 4,
      }}
    >
      <BackBtn />
      <Typography variant="h6" gutterBottom>
        Type your message that will be sent to all relevant exhibitors to update
        their profile
      </Typography>

      <TextField
        label="Message"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{ mb: 3 }}
      />

      <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button>
    </Box>
  );
};

export default NotifyExhibitorsUpdateProfile;
