import React from "react";
import { useParams } from "react-router-dom";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import MuPb from "../../widgets/MuPb";
import {
  Alert,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Container,
} from "@mui/material";
import { getErrorResponse } from "../../utils/util";
import BackBtn from "../../widgets/BackBtn";
import { LinkedIn, Facebook, Twitter, Instagram } from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";

const VisitorDetail = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}user/a/get-visitor?id=${id}`
  );

  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  const {
    username,
    user_id,
    barcode_id,
    title,
    firstName,
    lastName,
    email,
    designation,
    mobileNo,
    telephoneNo,
    website,
    companyName,
    address,
    country,
    stateRegion,
    city,
    pinZip,
    industrySector,
    department,
    eventDiscovery,
    eventCategory,
    productCategories,
    socialLinks,
    preview,
    termsAccepted,
    qrLink,
    createdOn,
    updatedOn,
    profile_image,
    lookingFor,
    profile_banner,
  } = data;

  return (
    <Box>
      <BackBtn />
      <Container maxWidth="md">
        <Card>
          {/* Profile Banner */}
          {profile_banner ? (
            <CardMedia
              component="img"
              height="200"
              image={profile_banner}
              alt="Profile Banner"
            />
          ) : (
            <Box sx={{ height: 200, backgroundColor: "grey.300" }} />
          )}
          {/* Profile Avatar */}
          <Box display="flex" justifyContent="center">
            <Avatar
              src={profile_image || ""}
              sx={{
                width: 120,
                height: 120,
                marginTop: "-60px",
                border: "3px solid white",
              }}
            />
          </Box>
          <CardContent>
            <Grid container spacing={2}>
              {/* Name and Designation */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  {title} {firstName} {lastName}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {designation}
                </Typography>
                <Typography variant="subtitle1">{companyName}</Typography>
              </Grid>
              {/* Contact Details */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Email:</strong> {email}
                </Typography>
                <Typography variant="body1">
                  <strong>Mobile:</strong> {mobileNo}
                </Typography>
                {telephoneNo && telephoneNo !== "" && (
                  <Typography variant="body1">
                    <strong>Telephone:</strong> {telephoneNo}
                  </Typography>
                )}
                {website && website !== "" && (
                  <Typography variant="body1">
                    <strong>Website:</strong>{" "}
                    <a href={website} target="_blank" rel="noopener noreferrer">
                      {website}
                    </a>
                  </Typography>
                )}
                <Typography variant="body1">
                  <strong>Address:</strong> {address}
                  {city?.name && `, ${city.name}`}
                  {stateRegion?.name && `, ${stateRegion.name}`}
                  {country?.name && `, ${country.name}`}
                  {pinZip && ` - ${pinZip}`}
                </Typography>
                {country?.flag && (
                  <img
                    src={country.flag}
                    alt={`${country.name} flag`}
                    style={{ width: "30px", marginTop: "5px" }}
                  />
                )}
              </Grid>
              {/* Additional Details */}
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>Username</strong>
                        </TableCell>
                        <TableCell>{username}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>User ID</strong>
                        </TableCell>
                        <TableCell>{user_id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Barcode ID</strong>
                        </TableCell>
                        <TableCell>{barcode_id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Industry Sector</strong>
                        </TableCell>
                        <TableCell>{industrySector}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Department</strong>
                        </TableCell>
                        <TableCell>{department}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Event Discovery</strong>
                        </TableCell>
                        <TableCell>{eventDiscovery}</TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell>
                          <strong>Preview</strong>
                        </TableCell>
                        <TableCell>{preview ? "Yes" : "No"}</TableCell>
                      </TableRow> */}
                      <TableRow>
                        <TableCell>
                          <strong>Terms Accepted</strong>
                        </TableCell>
                        <TableCell>{termsAccepted ? "Yes" : "No"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Created On</strong>
                        </TableCell>
                        <TableCell>
                          {new Date(createdOn).toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Updated On</strong>
                        </TableCell>
                        <TableCell>
                          {new Date(updatedOn).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {/* Event Categories */}
              {eventCategory && eventCategory.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Event Categories</Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {eventCategory.map((category, index) => (
                      <Chip key={index} label={category} />
                    ))}
                  </Box>
                </Grid>
              )}
              {/* Product Categories */}
              {productCategories && productCategories.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Product Categories</Typography>
                  <List>
                    {productCategories.map((product, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={product} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
              {/* Looking For */}
              {lookingFor && (
                <Grid item xs={12}>
                  <Typography variant="h6">Looking For</Typography>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: lookingFor }}
                  />
                </Grid>
              )}
              {/* Social Links */}
              {socialLinks && Object.keys(socialLinks).length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Social Links</Typography>
                  <Box>
                    {socialLinks.fb && socialLinks.fb !== "" && (
                      <IconButton
                        component="a"
                        href={socialLinks.fb}
                        target="_blank"
                        sx={{ marginRight: 1, color: "#3b5998" }} // Facebook color
                      >
                        <Facebook />
                      </IconButton>
                    )}
                    {socialLinks.ln && socialLinks.ln !== "" && (
                      <IconButton
                        component="a"
                        href={socialLinks.ln}
                        target="_blank"
                        sx={{ marginRight: 1, color: "#0e76a8" }} // LinkedIn color
                      >
                        <LinkedIn />
                      </IconButton>
                    )}
                    {socialLinks.in && socialLinks.in !== "" && (
                      <IconButton
                        component="a"
                        href={socialLinks.in}
                        target="_blank"
                        sx={{ marginRight: 1, color: "#E4405F" }} // Instagram color
                      >
                        <Instagram />
                      </IconButton>
                    )}
                    {socialLinks.x && socialLinks.x !== "" && (
                      <IconButton
                        component="a"
                        href={socialLinks.x}
                        target="_blank"
                        sx={{ marginRight: 1 }} // X (Twitter) color
                      >
                        <XIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              )}

              {/* QR Code */}
              {qrLink && (
                <Grid item xs={12}>
                  <Typography variant="h6">QR Code</Typography>
                  <img
                    src={qrLink}
                    alt="QR Code"
                    style={{ width: "150px", height: "150px" }}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default VisitorDetail;
