import {
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import BackBtn from "../../widgets/BackBtn";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadFiles = () => {
  const [ctab, setctab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setctab(newValue);
  };
  return (
    <Box>
      <BackBtn />
      <Container>
        <Tabs value={ctab} onChange={handleChangeTab}>
          <Tab sx={{ textTransform: "none" }} label="Upload PDF" />
          <Tab sx={{ textTransform: "none" }} label="Upload Image" />
        </Tabs>
      </Container>
    </Box>
  );
};

export default UploadFiles;
