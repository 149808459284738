import { TextField, Typography } from "@mui/material";
import React from "react";

const Labelinput = ({ label, type, required, disabled }) => {
  return (
    <div>
      <Typography variant="body1">{label}</Typography>
      <TextField
        required={required}
        type={type}
        fullWidth
        disabled={disabled}
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Labelinput;
