import React, { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"; // Make sure to install @mui/x-date-pickers for date picker support.
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import AddSupplierItem from "./AddSupplierItem";
import FormItems from "./FormItems";
import BackBtn from "../../widgets/BackBtn";
import LogoutButton from "../../widgets/LogoutButton";
import SupplierItems from "./SupplierItems";

const OesDataForm = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const [showAddSupplier, setShowAddSupplier] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box maxWidth={1000} m={"16px auto"}>
        <BackBtn bstyle={{ position: "fixed", left: 2, top: 2 }} />
        <LogoutButton bstyle={{ position: "fixed", top: 2, right: 2 }} />

        <RdMyModal
          showModal={showAddSupplier}
          setShowModal={setShowAddSupplier}
          modalC={<AddSupplierItem />}
        />
        <Typography textAlign={"center"} variant="h5">
          Form name and suppliers
        </Typography>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Add Form Names" />
          <Tab label="Add Suppliers" />
        </Tabs>
        <Box sx={{ padding: 2 }}>
          {tabIndex === 0 && <FormItems />}
          {tabIndex === 1 && <SupplierItems />}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default OesDataForm;
