import { LoginOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const LogoutButton = ({ bstyle }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate("/logout");
      }}
      startIcon={<LoginOutlined />}
      size="small"
      sx={{ ...bstyle }}
      variant="outlined"
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
