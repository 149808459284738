import React, { useState } from "react";
import {
  Typography,
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const PropertyListPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [copiedItem, setCopiedItem] = useState(null);

  const listOfProperties = [
    "companyName",
    "contactSalutation",
    "contactFirstName",
    "contactLastName",
    "contactDesignation",
    "mobile",
    "companyEmail",
    "contactEmail",
    "address1",
    "address2",
    "address3",
    "country",
    "state",
    "city",
    "pin",
    "phone",
    "fax",
    "website",
    "companyShortName",
    "fascia",
    "weAre",
    "industrySector",
    "exhibitorCategory",
    "productCategories",
    "hallNumber",
    "standNumber",
    "spaceType",
    "exhibitArrival",
    "exhibitArrivalTimeFrom",
    "exhibitArrivalTimeTo",
    "materialArrival",
    "materialArrivalTimeFrom",
    "materialArrivalTimeTo",
    "dismantling",
    "dismantlingTimeFrom",
    "dismantlingTimeTo",
    "standSize",
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setCopiedItem(null);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={handleClick}
        sx={{ textTransform: "none", borderRadius: 2 }}
      >
        variable list for exhibitor info
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            p: 2,
            boxShadow: 4,
            borderRadius: 2,
            minWidth: "300px",
            maxHeight: "400px",
            overflow: "auto",
          },
        }}
      >
        <Typography variant="h6" gutterBottom>
          List of variables
        </Typography>
        <List dense>
          {listOfProperties.map((property, index) => (
            <ListItem key={index} divider>
              <ListItemText primary={property} />
              <CopyToClipboard
                text={property}
                onCopy={() => setCopiedItem(property)}
              >
                <IconButton
                  size="small"
                  color={copiedItem === property ? "success" : "primary"}
                  sx={{ ml: 1 }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </CopyToClipboard>
              {copiedItem === property && (
                <Typography
                  variant="caption"
                  color="success.main"
                  sx={{ ml: 1 }}
                >
                  Copied!
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default PropertyListPopover;
