export const OTP_VERIFY = "otp_verify";
export const saveOtpVerify = (token) => {
  localStorage.setItem(OTP_VERIFY, token);
};
export const getOtpVerify = () => {
  return localStorage.getItem(OTP_VERIFY);
};
export const deleteOtpVerify = () => {
  localStorage.removeItem(OTP_VERIFY);
};
export const saveOnsite = (type, token) => {
  localStorage.setItem(type, token);
};
export const getOnsite = (type) => {
  return localStorage.getItem(type);
};
export const deleteOnsite = (type) => {
  localStorage.removeItem(type);
};
export const isOnsiteActivated = (type) => {
  return localStorage.getItem(type) !== null;
};
export const getOnsiteHeader = (type) => {
  return {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${getOnsite(type)}`,
      type: type,
    },
  };
};
export const getOnsiteMultipartHeader = (type) => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${getOnsite(type)}`,
      type: type,
    },
  };
};
