import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  InputLabel,
  FormControl,
} from "@mui/material";

const MobileAppForm = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [androidForm, setAndroidForm] = useState({
    appName: "",
    brandName: "",
    appVersion: "",
    supportedDevices: "",
    developerContact: "",
    description: "",
    icon: null,
    banner: null,
    screenshots: null,
  });
  const [iosForm, setIosForm] = useState({
    appName: "",
    brandName: "",
    appVersion: "",
    supportedDevices: "",
    developerContact: "",
    description: "",
    icon: null,
    banner: null,
    screenshots: null,
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (e, form, setForm) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e, form, setForm, field) => {
    setForm({
      ...form,
      [field]: e.target.files[0],
    });
  };

  const handleSubmit = (platform) => {
    if (platform === "android") {
      console.log("Android Form Submitted:", androidForm);
    } else {
      console.log("iOS Form Submitted:", iosForm);
    }
  };

  const renderForm = (form, setForm, platform) => (
    <Box component="form" sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="App Name"
            name="appName"
            value={form.appName}
            onChange={(e) => handleInputChange(e, form, setForm)}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Brand Name"
            name="brandName"
            value={form.brandName}
            onChange={(e) => handleInputChange(e, form, setForm)}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="App Version"
            name="appVersion"
            value={form.appVersion}
            onChange={(e) => handleInputChange(e, form, setForm)}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Supported Devices"
            name="supportedDevices"
            value={form.supportedDevices}
            onChange={(e) => handleInputChange(e, form, setForm)}
            fullWidth
            margin="normal"
            placeholder="e.g., Android 5.0+, iOS 12+"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Developer Contact"
            name="developerContact"
            value={form.developerContact}
            onChange={(e) => handleInputChange(e, form, setForm)}
            fullWidth
            margin="normal"
            placeholder="email@example.com"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={form.description}
            onChange={(e) => handleInputChange(e, form, setForm)}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </Grid>

        {/* File Uploads */}
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel shrink>App Icon</InputLabel>
            <Button variant="outlined" component="label">
              Upload Icon
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, form, setForm, "icon")}
              />
            </Button>
            {form.icon && (
              <Typography variant="caption">{form.icon.name}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel shrink>Banner</InputLabel>
            <Button variant="outlined" component="label">
              Upload Banner
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, form, setForm, "banner")}
              />
            </Button>
            {form.banner && (
              <Typography variant="caption">{form.banner.name}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel shrink>Screenshots</InputLabel>
            <Button variant="outlined" component="label">
              Upload Screenshots
              <input
                type="file"
                hidden
                onChange={(e) =>
                  handleFileChange(e, form, setForm, "screenshots")
                }
              />
            </Button>
            {form.screenshots && (
              <Typography variant="caption">{form.screenshots.name}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSubmit(platform)}
        sx={{ mt: 3 }}
      >
        Submit {platform === "android" ? "Android" : "iOS"} Form
      </Button>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: "center" }}>
        Mobile App Development Form
      </Typography>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Android" />
        <Tab label="iOS" />
      </Tabs>

      {/* Android Form */}
      {activeTab === 0 && renderForm(androidForm, setAndroidForm, "android")}

      {/* iOS Form */}
      {activeTab === 1 && renderForm(iosForm, setIosForm, "ios")}
    </Box>
  );
};

export default MobileAppForm;
