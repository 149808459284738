import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import { getMultipartHeader, toastError } from "../../utils/util";
import { toast } from "react-toastify";

const AddProductItem = ({ onAdded }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    image: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = async () => {
    if (!imageFile) {
      toast.error("Please select an image to upload.");
      return;
    }
    setLoading(true);
    try {
      const formDataUpload = new FormData();
      formDataUpload.append("image", imageFile);
      const response = await axios.post(
        `${API_ENDPOINT}user/upload-image-file`,
        formDataUpload,
        getMultipartHeader(ADMIN)
      );
      setFormData((prev) => ({
        ...prev,
        image: response.data.message, // Adjust based on your API's response
      }));
      setImageUploaded(true); // Disable the Upload button after successful upload
    } catch (error) {
      toast.error("Image upload failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdded(formData);
    setImageFile(null);
    setImageUploaded(false);
    setFormData({
      name: "",
      description: "",
      price: "",
      image: "",
    });
  };

  const handleClearImage = () => {
    setFormData((prev) => ({ ...prev, image: "" }));
    setImageFile(null);
    setImageUploaded(false); // Re-enable the Upload button
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        margin: "auto",
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Add New Item
      </Typography>

      <TextField
        label="Name"
        name="name"
        size="small"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
        sx={{ mb: 2 }}
      />

      <TextField
        size="small"
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        fullWidth
        required
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />

      <TextField
        size="small"
        label="Price"
        name="price"
        value={formData.price}
        onChange={handleChange}
        type="number"
        fullWidth
        required
        sx={{ mb: 2 }}
        InputProps={{ inputProps: { min: 0 } }}
      />

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          size="small"
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          sx={{ mr: 2 }}
          disabled={imageUploaded}
        >
          {imageFile ? "Change Image" : "Upload Image"}
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => {
              setImageFile(e.target.files[0]);
              setImageUploaded(false); // Allow re-upload if a new file is selected
            }}
          />
        </Button>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            size="small"
            variant="contained"
            onClick={handleImageUpload}
            disabled={!imageFile || imageUploaded}
          >
            Upload
          </Button>
        )}
      </Box>

      {imageFile && !imageUploaded && (
        <Typography variant="body2" sx={{ mb: 2 }}>
          Selected file: {imageFile.name}
        </Typography>
      )}

      {formData.image && (
        <Card sx={{ mb: 2 }}>
          <CardMedia
            component="img"
            height="200"
            image={formData.image}
            alt="Uploaded"
            sx={{ objectFit: "cover" }}
          />
          <CardContent>
            <Typography variant="body2">Image Preview</Typography>
            <Button
              size="small"
              variant="text"
              color="secondary"
              onClick={handleClearImage}
            >
              Clear Image
            </Button>
          </CardContent>
        </Card>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!formData.image}
        sx={{ mt: 2 }}
      >
        Add Item
      </Button>
    </Box>
  );
};

export default AddProductItem;
