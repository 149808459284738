import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  Divider,
  Alert,
  Container,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  Checkbox,
  Paper,
  TableContainer,
  Grid,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadingButton } from "@mui/lab";
const SeminarHalls = () => {
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [items, setItems] = useState(HALLS);
  const [total, setTotal] = useState(0);

  const handleCheckboxChange = (areaIndex, shiftIndex, dateIndex) => {
    const updatedItems = [...items];
    const shift = updatedItems[areaIndex].shifts[shiftIndex];

    // Toggle the date's checked state
    const dateKeys = Object.keys(shift.dates);
    shift.dates[dateKeys[dateIndex]] = !shift.dates[dateKeys[dateIndex]];

    // Calculate the total for the shift based on the dates selected
    const daysChecked = Object.values(shift.dates).filter(Boolean).length;
    shift.total = shift.rate * daysChecked;

    setItems(updatedItems);
  };
  const onSubmit = async () => {};
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          5.5 Seminar Halls
        </Typography>
        <Box mb={4}>
          <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert>
        </Box>

        <TableContainer sx={{ maxWidth: 400 }} component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Seating Capacity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Jacaranda</TableCell>
                <TableCell>1368</TableCell>
                <TableCell>1300</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gulmohar</TableCell>
                <TableCell>679</TableCell>
                <TableCell>700</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Parijatha</TableCell>
                <TableCell>168</TableCell>
                <TableCell>80</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1">
            Date starts from 19/01/2024 to 23/01/2024
          </Typography>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Area</TableCell>
                <TableCell>Shift</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>
                  <Typography variant="caption">19 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">20 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">21 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">22 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">23 Jan</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">24 Jan</Typography>
                </TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((areaData, index) => (
                <>
                  {areaData.shifts.map((shift, shiftIndex) => (
                    <TableRow key={`${index}${shiftIndex}`}>
                      <TableCell>
                        <Box sx={{ fontWeight: 600 }}>{areaData.area}</Box>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Box sx={{ fontWeight: 600 }}>{shift.shift}</Box>
                        <Box sx={{ fontStyle: "italic" }}>
                          <div style={{ fontSize: "11px" }}>
                            {shift.shiftTiming}
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell>{shift.rate.toLocaleString()}</TableCell>
                      {Object.values(shift.dates).map(
                        (isChecked, dateIndex) => (
                          <TableCell key={dateIndex}>
                            <Checkbox
                              size="small"
                              checked={isChecked}
                              onChange={() =>
                                handleCheckboxChange(
                                  index,
                                  shiftIndex,
                                  dateIndex
                                )
                              }
                            />
                          </TableCell>
                        )
                      )}
                      <TableCell>{shift.total.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </LocalizationProvider>
        <Paper sx={{ padding: "1rem", mt: 2, elevation: 0 }}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">Total Amount</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">18% GST</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(0.18 * total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body1">Total</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(1.18 * total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>
          </Grid>
        </Paper>

        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

const HALLS = [
  {
    area: "Jacaranda",
    shifts: [
      {
        shift: "Shift 1",
        area: 1368,
        seatingCapacity: 1300,
        shiftTiming: "10:00 AM to 1:00 PM",
        rate: 114068,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 2",
        area: 1368,
        seatingCapacity: 1300,
        shiftTiming: "2:00 PM to 5:00 PM",
        rate: 114068,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 3",
        area: 1368,
        seatingCapacity: 1300,
        shiftTiming: "10:00 AM to 5:00 PM",
        rate: 228135,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
    ],
  },
  {
    area: "Gulmohar",
    shifts: [
      {
        shift: "Shift 1",
        area: 679,
        seatingCapacity: 700,
        shiftTiming: "10:00 AM to 1:00 PM",
        rate: 68440,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 2",
        area: 679,
        seatingCapacity: 700,
        shiftTiming: "2:00 PM to 5:00 PM",
        rate: 68440,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 3",
        area: 679,
        seatingCapacity: 700,
        shiftTiming: "10:00 AM to 5:00 PM",
        rate: 136880,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
    ],
  },
  {
    area: "Parijatha",
    shifts: [
      {
        shift: "Shift 1",
        area: 168,
        seatingCapacity: 80,
        shiftTiming: "10:00 AM to 1:00 PM",
        rate: 22815,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 2",
        area: 168,
        seatingCapacity: 80,
        shiftTiming: "2:00 PM to 5:00 PM",
        rate: 22815,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
      {
        shift: "Shift 3",
        area: 168,
        seatingCapacity: 80,
        shiftTiming: "10:00 AM to 5:00 PM",
        rate: 45630,
        dates: {
          "19/01/24": false,
          "20/01/24": false,
          "21/01/24": false,
          "22/01/24": false,
          "23/01/24": false,
          "24/01/24": false,
        },
        total: 0,
      },
    ],
  },
];

export default SeminarHalls;
