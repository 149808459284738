import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  Card,
  List,
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import {
  Group,
  Image as KonvaImage,
  Layer,
  Line,
  Rect,
  Stage,
} from "react-konva";
import { toast } from "react-toastify";
import FpCropEdit, { MAP_IMAGE } from "./FpCropEditor";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import SimpleYesNoDialog from "../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../widgets/CustomCircularP";
import BackBtn from "../../widgets/BackBtn";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import {
  getHeader,
  getHoverColor,
  hexToRGBA,
  toastError,
} from "../../utils/util";
const TRANSPARENCY = 0.8;
export function getImageDimen(imgWidth, imgHeight) {
  const width = Math.min(imgWidth, 1280);
  const height = width * (imgHeight / imgWidth);
  return { width, height };
}

export function getScales(containerWidth, imgWidth, imgHeight) {
  const scaleX =
    Math.min(imgWidth, containerWidth) /
    getImageDimen(imgWidth, imgHeight).width;
  const scaleY =
    (Math.min(imgWidth, containerWidth) * (imgHeight / imgWidth)) /
    getImageDimen(imgWidth, imgHeight).height;

  return { scaleX, scaleY };
}
function GeneralFloorPlan() {
  const [reloadFp, setReloadFp] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-floor?id=1&r=${reloadFp}`,
    0
  );
  const navigate = useNavigate();
  const [showImageCrop, setShowImageCrop] = useState(false);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(null);
  const [gMenuEl, setGmenuEl] = useState(null);
  const [hMenuEl, setHmenuEl] = useState(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [newHalls, setNewHalls] = useState([]);
  const [showHallDelete, setShowHallDelete] = useState(false);
  const [showFloorDelete, setShowFloorDelete] = useState(false);
  const [targetHall, setTargetHall] = useState();
  const [hallDeleting, setHallDeleting] = useState(false);
  const [floorDeleting, setFloorDeleting] = useState(false);
  const [confirmAllDelete, setConfirmAllDelete] = useState(false);

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.08;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    // Calculate the new scale
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Limit the scale
    const minScale = 0.5;
    const maxScale = 3;
    const finalScale = Math.max(minScale, Math.min(maxScale, newScale));

    setStageScale(finalScale);

    // Calculate the new position to keep the pointer position consistent
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: pointer.x - mousePointTo.x * finalScale,
      y: pointer.y - mousePointTo.y * finalScale,
    };

    setStagePos(newPos);
  };

  useEffect(() => {
    if (data && !image) {
      const img = new window.Image();
      img.src = data.image;
      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [data, image]);

  useEffect(() => {
    if (data && image && containerRef) {
      const containerWidth = containerRef.current.offsetWidth;
      setDimensions({
        width: containerWidth,
        height: 560,
      });
      const scale = getScales(containerWidth, image.width, image.height);
      const scaleX = scale.scaleX;
      const scaleY = scale.scaleY;

      const halls = data.halls.map((hall) => {
        if (hall.shape.type === "Rectangle") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: {
                ...hall.shape.shape,
                x: hall.shape.shape.x * scaleX,
                y: hall.shape.shape.y * scaleY,
                width: hall.shape.shape.width * scaleX,
                height: hall.shape.shape.height * scaleY,
              },
            },
          };
        } else if (hall.shape.type === "Polygon") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: hall.shape.shape.map((point, index) =>
                index % 2 === 0 ? point * scaleX : point * scaleY
              ),
            },
          };
        }
        return hall;
      });
      setNewHalls(halls);
    }
  }, [data, image, containerRef]);

  const hanldeHallMenuClick = (e, hall) => {
    setTargetHall(hall);
    setHmenuEl(e.currentTarget);
  };

  const handleGeneralPlanEdit = (e) => {
    setGmenuEl(e.currentTarget);
  };

  const handleGmenuClick = (event, action) => {
    setGmenuEl(null); // Close the menu

    switch (action) {
      case "Edit Image":
        setShowImageCrop(true);
        break;

      case "Replace Image":
        setShowImageCrop(true);
        break;

      case "Delete Image":
        setShowFloorDelete(true);
        break;

      default:
        console.warn("Unknown action:", action);
        break;
    }
  };

  const handleHmenuClick = (e, m) => {
    setHmenuEl(null); // Close the menu
    switch (m) {
      case "Edit Hall":
        navigate(`/home/fp-add-hall?id=${targetHall._id}`);
        break;
      case "Delete Hall":
        setShowHallDelete(true);
        break;
      default:
        console.warn("Unknown action:", m);
        break;
    }
  };

  const handleHallDelete = async (id) => {
    setHallDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}admin/delete-hall?id=${id}`,
        getHeader(ADMIN)
      );

      setReloadFp((prev) => !prev);
      toast.success(response.data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setHallDeleting(false);
    }
  };

  const handleFloorDelete = async (id) => {
    setFloorDeleting(true);
    try {
      const { data } = await axios.delete(
        `${API_ENDPOINT}user/delete-floor?id=${id}`,
        getHeader(ADMIN)
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      setFloorDeleting(false);
    }
  };

  const onYesHallDelete = () => {
    setShowHallDelete(false);
    handleHallDelete(targetHall._id);
  };

  const onYesFloorDelete = () => {
    setShowFloorDelete(false);
    handleFloorDelete(data?._id);
  };

  const onYesDeleteAllHalls = async () => {
    setHallDeleting(true);
    try {
      const { data } = await axios.delete(
        `${API_ENDPOINT}user/delete-all-halls?id=${data?.eventId}`,
        getHeader(ADMIN)
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      setHallDeleting(false);
    }
    setConfirmAllDelete(false);
  };

  return (
    <Box sx={{ maxWidth: "1280px", ml: "auto", mr: "auto", mt: 2 }}>
      <CustomCircularP show={isLoading || hallDeleting} />
      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={<FpCropEdit upload_type={"MAP_IMAGE"} />}
      />
      <CustomCircularP show={hallDeleting} />

      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete all halls?`}
        onYes={onYesDeleteAllHalls}
        show={confirmAllDelete}
        setShow={setConfirmAllDelete}
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete this Map?`}
        onYes={onYesFloorDelete}
        show={showFloorDelete}
        setShow={setShowFloorDelete}
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete ${targetHall?.name}? Please be aware that all data related to this hall will be deleted.`}
        onYes={onYesHallDelete}
        show={showHallDelete}
        setShow={setShowHallDelete}
      />
      <BackBtn />
      <Menu
        anchorEl={gMenuEl}
        open={Boolean(gMenuEl)}
        onClose={() => setGmenuEl(null)}
      >
        <MenuItem
          disabled={!image}
          onClick={(e) => handleGmenuClick(e, "Replace Image")}
        >
          <FindReplaceIcon fontSize="small" />
          Replace Image
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!image}
          sx={{ color: "red" }}
          onClick={(e) => handleGmenuClick(e, "Delete Image")}
        >
          <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          Delete Image
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={hMenuEl}
        open={Boolean(hMenuEl)}
        onClose={() => setHmenuEl(null)}
      >
        <MenuItem onClick={(e) => handleHmenuClick(e, "Edit Hall")}>
          <EditIcon fontSize="small" />
          Edit Hall
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: "red" }}
          onClick={(e) => handleHmenuClick(e, "Delete Hall")}
        >
          <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          Delete Hall
        </MenuItem>
      </Menu>

      <Grid mt={1} container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">General Plan</Typography>

                <IconButton
                  onClick={handleGeneralPlanEdit}
                  color="primary"
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
              </Box>
              {data?.image ? (
                <Box
                  ref={containerRef}
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {image ? (
                    <Stage
                      width={dimensions.width}
                      height={dimensions.height}
                      scaleX={stageScale}
                      scaleY={stageScale}
                      x={stagePos.x}
                      y={stagePos.y}
                      draggable={true}
                      onWheel={handleWheel}
                    >
                      <Layer>
                        <KonvaImage
                          image={image.img}
                          width={Math.min(image.width, dimensions.width)}
                          height={
                            Math.min(image.width, dimensions.width) *
                            (image.height / image.width)
                          }
                          x={0}
                          y={0}
                        />
                        {newHalls
                          ?.filter((hall) => hall.shape.type === "Polygon")
                          .map((hall, index) => (
                            <Group key={index}>
                              <Line
                                points={hall.shape.shape}
                                fill={hexToRGBA(hall.color, TRANSPARENCY)}
                                stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                                strokeWidth={hall.strokeWidth}
                                closed={true}
                                onClick={() => {
                                  navigate(`/home/hall-plan/${hall._id}`);
                                }}
                                onMouseEnter={(e) => {
                                  const shape = e.target;
                                  shape.fill(getHoverColor(hall.color));
                                  shape.getLayer().batchDraw();
                                }}
                                onMouseLeave={(e) => {
                                  const shape = e.target;
                                  shape.fill(
                                    hexToRGBA(hall.color, TRANSPARENCY)
                                  );
                                  shape.getLayer().batchDraw();
                                }}
                              />
                            </Group>
                          ))}
                        {newHalls
                          ?.filter((hall) => hall.shape.type === "Rectangle")
                          .map((hall, index) => (
                            <Rect
                              key={index}
                              x={hall.shape.shape.x}
                              y={hall.shape.shape.y}
                              width={hall.shape.shape.width}
                              height={hall.shape.shape.height}
                              fill={hexToRGBA(hall.color, TRANSPARENCY)}
                              onClick={() => {
                                navigate(`/home/hall-plan/${hall._id}`);
                              }}
                              onMouseEnter={(e) => {
                                const shape = e.target;
                                shape.fill(getHoverColor(hall.color));
                                shape.getLayer().batchDraw();
                              }}
                              onMouseLeave={(e) => {
                                const shape = e.target;
                                shape.fill(hexToRGBA(hall.color, TRANSPARENCY));
                                shape.getLayer().batchDraw();
                              }}
                            />
                          ))}
                      </Layer>
                    </Stage>
                  ) : (
                    <Box sx={{ p: 3, width: "100%", height: "100%" }}>
                      Loading ...
                    </Box>
                  )}
                </Box>
              ) : (
                <>
                  <Box
                    onClick={() => setShowImageCrop(true)}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "400px",
                      border: "2px dashed #054059",
                      borderRadius: 1,
                      padding: 4,
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="h6" color="#054059">
                      Click to upload Map
                    </Typography>
                    <Typography variant="body2">
                      Only images (JPG/JPEG, PNG) less than 2MB are allowed for
                      upload.
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6">Hall List</Typography>

                  <Box display={"flex"} gap={1}>
                    <LoadingButton
                      sx={{ textTransform: "none" }}
                      startIcon={<DeleteOutlineIcon />}
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setConfirmAllDelete(true);
                      }}
                    >
                      Delete All Halls
                    </LoadingButton>
                    <Button
                      sx={{ textTransform: "none" }}
                      color="primary"
                      startIcon={<AddCircleOutlineIcon />}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        navigate("/home/fp-add-hall", { replace: true });
                      }}
                    >
                      Add New Hall
                    </Button>
                  </Box>
                </Box>

                <Typography variant="caption" gutterBottom>
                  EXHIBITION HALLS
                </Typography>

                <List>
                  {data && data.halls.length > 0 ? (
                    data.halls.map((hall, index) => (
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{ padding: 1, mt: 1 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body1">{hall.name}</Typography>
                          <IconButton
                            onClick={(e) => hanldeHallMenuClick(e, hall)}
                            size="small"
                            aria-label="edit"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ mt: 3, textAlign: "center" }}
                    >
                      No halls available.
                    </Typography>
                  )}
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GeneralFloorPlan;
