import React, { useContext } from "react";
import { Toolbar, Box } from "@mui/material";
import { ExpoTheme } from "../themes/ExpoTheme";
import SideMenuBar from "./SideMenuBar";
import { Route, Routes } from "react-router-dom";
import { AppBarHeader } from "./header/AppBarHeader";
import Dashboard from "./Dashboard";
import EmailTemplateEditor from "../emails/EmailTemplateEditor";
import EmailTemplateCards from "../emails/ChooseEmailTemplates";
import EmailBlockEditor from "../emails/EmailBlockEditor";
import ChangePasswordForm from "./profile/PasswordUpdate";
import AdminProfilePage from "./profile/AdminProfile";
import { UserContext } from "../context/UserContext";
import OesDataForm from "../formbuilder/oesform/OesDataForm";
import FormBuilder from "../formbuilder/FormBuilder";
import RegistrationSettings from "./registration/RegistrationSettings";
import RegistrationPreview from "./registration/RegistrationPreview";
import RegistrationTermsConditions from "./registration/RegistrationTermsConditions";
import RegBadgePreview from "./registration/RegBadgePreview";
import OesFormBuilder from "../formbuilder/oesform/OesFromBuilder";
import MobileAppForm from "./mobileapps/MobileAppForm";
import BadgeBuilder from "./badge/BadgeBuilder";
import NotifyExhibitorsUpdateProfile from "./pages/NotifyExhibitorsUpdateProfile";
import GeneralFloorPlan from "./floorplan/GeneralFloorPlan";
import Visitors from "../components/visitors/Visitors";
import Exhibitors from "./exhibitors/Exhibitors";
import ChooseAddOptions from "./exhibitors/ChooseAddOptions";
import AddExhibitorForm from "./exhibitors/AddExhibitorForm";
import ExhibitorExcelReader from "./exhibitors/ExhibitorExcelReader";
import AddHall from "./floorplan/AddHall";
import AddEvent from "./events/AddEvent";
import EventsList from "./events/EventsList";
import ExportDataPage from "./exportdata/ExportDataPage";
import UploadCategoryPage from "./productcategory/UploadCategoryPage";
import VisitorDetail from "../components/visitors/VisitorDetail";
import ExhibitorDetail from "./exhibitors/ExhibitorDetail";
import { OesFormBuilderDemo } from "../formbuilder/oesform/OesFormBuilderDemo";
import AddEventForm from "./events/AddEventForm";
import OnsiteModule from "./onsitemodule/OnsiteModule";
import SuperPreview from "../pages/SuperPreview";
import EventDetail from "../pages/EventDetail";
import FileStorage from "./filestorage/FileStorage";
import UploadFiles from "./filestorage/UploadFiles";
import AddStand from "./floorplan/AddStand";
import HallPlan from "./floorplan/HallPlan";
import UpdateExcelReader from "./exhibitors/UpdateExcelReader";
function MainDash() {
  const { user, loading } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarHeader open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideMenuBar open={open} setOpen={setOpen} />
      <Box
        component="main"
        position={"fixed"}
        width={"100%"}
        height={"100vh"}
        pl={10}
        pr={3}
        pt={3}
        pb={3}
        sx={{
          overflowY: "auto",
        }}
      >
        <Toolbar />
        <Box>
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<AdminProfilePage />} />
            <Route path="change-password" element={<ChangePasswordForm />} />
            <Route path="badge-builder" element={<BadgeBuilder />} />
            <Route path="mobile-apps" element={<MobileAppForm />} />
            <Route path="floor-plan-creator" element={<GeneralFloorPlan />} />
            <Route path="fp-add-hall" element={<AddHall />} />
            <Route path="fp-add-stand/:id" element={<AddStand />} />
            <Route path="hall-plan/:id" element={<HallPlan />} />
            <Route path="add-event" element={<AddEventForm />} />
            <Route path="events-list" element={<EventsList />} />
            <Route path="export-data" element={<ExportDataPage />} />
            <Route path="product-category" element={<UploadCategoryPage />} />
            <Route
              path="registration-settings"
              element={<RegistrationSettings />}
            />
            <Route path="visitors-list" element={<Visitors />} />
            <Route path="visitor/:id" element={<VisitorDetail />} />
            <Route path="exhibitor/:id" element={<ExhibitorDetail />} />
            <Route path="exhibitors-list" element={<Exhibitors />} />
            <Route
              path="registration-page-preview"
              element={<RegistrationPreview />}
            />
            <Route
              path="registration-terms-conditions"
              element={<RegistrationTermsConditions />}
            />
            <Route
              path="registration-badge-preview"
              element={<RegBadgePreview />}
            />
            <Route
              path="oes-form-builder/:id"
              element={<OesFormBuilderDemo />}
            />
            <Route path="file-storage" element={<FileStorage />} />
            <Route path="upload-files" element={<UploadFiles />} />
            <Route path="oes-form-builder-2/:id" element={<OesFormBuilder />} />
            <Route
              path="notify-exhibitors-update-profile"
              element={<NotifyExhibitorsUpdateProfile />}
            />
            <Route path="add-exhibitor" element={<ChooseAddOptions />} />
            <Route path="add-exhibitor-form" element={<AddExhibitorForm />} />
            <Route
              path="edit-exhibitor-form/:id"
              element={<AddExhibitorForm />}
            />
            <Route
              path="import-exhibitor-form"
              element={<ExhibitorExcelReader />}
            />
            <Route
              path="update-exhibitor-excel"
              element={<UpdateExcelReader />}
            />
            <Route path="email-template" element={<EmailTemplateEditor />} />
            <Route path="oes-forms" element={<OesDataForm />} />
            <Route path="registration-form-builder" element={<FormBuilder />} />
            <Route path="super-manage-preview" element={<SuperPreview />} />
            <Route path="super-event-preview/:id" element={<EventDetail />} />
            <Route
              path="choose-email-template"
              element={<EmailTemplateCards />}
            />
            <Route path="email-block-editor" element={<EmailBlockEditor />} />
            <Route path="onsite-module/:id" element={<OnsiteModule />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

export default MainDash;
