import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import BackBtn from "../../widgets/BackBtn";

const ChooseAddOptions = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <BackBtn />
      <Container>
        <Typography variant="h5">Add Exhibitor</Typography>
        <Box p={3} textAlign="center">
          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <Card
                sx={{
                  minWidth: 275,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    Import from Excel
                  </Typography>
                  <Button
                    startIcon={<ImportExportIcon />}
                    onClick={() => navigate("/home/import-exhibitor-form")}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Select
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card
                sx={{
                  minWidth: 275,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6" component="div">
                    Add via form
                  </Typography>
                  <Button
                    startIcon={<FormatAlignJustifyIcon />}
                    onClick={() => navigate("/home/add-exhibitor-form")}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    Select
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ChooseAddOptions;
