import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const BackBtn = ({ bstyle = {} }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(-1)}
      variant="text"
      size="small"
      startIcon={<ArrowBackIosIcon />}
      sx={{
        textTransform: "none",
        ".MuiButton-startIcon": {
          marginRight: "0", // Or any desired spacing value, could be '2px', '4px', etc.
        },
        ...bstyle,
      }}
    >
      Back
    </Button>
  );
};

export default BackBtn;
