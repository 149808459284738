import { Box, Container, Grid, Pagination, Typography } from "@mui/material";
import React from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import { LoadingButton } from "@mui/lab";
import SearchBar from "../../widgets/searchBar";
import AllEvents from "./AllEvents";
import BackBtn from "../../widgets/BackBtn";
const EventsList = () => {
  return (
    <Box>
      <BackBtn />
      <Container>
        <Grid container alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={4} md={3}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                All Events
              </Typography>
              <Box ml={2}>
                <LoadingButton
                  startIcon={<GetAppIcon />}
                  variant="outlined"
                  disabled
                  color="primary"
                >
                  Export
                </LoadingButton>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Typography variant="body1">
              Total: <span style={{ fontWeight: "bold" }}>2</span>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <SearchBar label="Search Events" size="small" />
          </Grid>
        </Grid>
        <AllEvents />
        <Box display="flex" justifyContent="flex-end">
          <Pagination page={1} count={1} />
        </Box>
      </Container>
    </Box>
  );
};

export default EventsList;
