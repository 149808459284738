import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  Card,
  List,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Drawer,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { useNavigate, useParams } from "react-router-dom";
import { Group, Image, Layer, Line, Rect, Stage } from "react-konva";
import { toast } from "react-toastify";
import FpCropEdit, { HALL_IMAGE } from "./FpCropEditor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import SimpleYesNoDialog from "../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../widgets/CustomCircularP";
import BackBtn from "../../widgets/BackBtn";
import { ADMIN, API_ENDPOINT, TRANSPARENCY } from "../../utils/constants";
import {
  getHeader,
  getHoverColor,
  hexToRGBA,
  toastError,
} from "../../utils/util";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import { getScales } from "./GeneralFloorPlan";
function HallPlan() {
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-stands?id=${id}&r=${reload}`,
    0
  );
  const navigate = useNavigate();
  const [showImageCrop, setShowImageCrop] = useState(false);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(null);
  const [gMenuEl, setGmenuEl] = useState(null);
  const [hMenuEl, setHmenuEl] = useState(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [newHalls, setNewHalls] = useState();
  const [showHallDelete, setShowHallDelete] = useState(false);
  const [targetHall, setTargetHall] = useState();
  const [hallDeleting, setHallDeleting] = useState(false);
  const [stallDeleting, setStallDeleting] = useState(false);

  const [chall, setChall] = useState();
  const [cstand, setCstand] = useState();

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.08;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    // Calculate the new scale
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Limit the scale
    const minScale = 0.5;
    const maxScale = 3;
    const finalScale = Math.max(minScale, Math.min(maxScale, newScale));

    setStageScale(finalScale);

    // Calculate the new position to keep the pointer position consistent
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: pointer.x - mousePointTo.x * finalScale,
      y: pointer.y - mousePointTo.y * finalScale,
    };

    setStagePos(newPos);
  };

  useEffect(() => {
    if (data && !image) {
      console.log("mak");
      const img = new window.Image();
      img.src = data.hall.image;
      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [data, image]);

  useEffect(() => {
    if (data && image && containerRef) {
      const containerWidth = containerRef.current.offsetWidth;
      setDimensions({
        width: containerWidth,
        height: 500,
      });
      const scale = getScales(containerWidth, image.width, image.height);
      const scaleX = scale.scaleX;
      const scaleY = scale.scaleY;

      const halls = data.stands.map((hall) => {
        if (hall.shape.type === "Rectangle") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: {
                ...hall.shape.shape,
                x: hall.shape.shape.x * scaleX,
                y: hall.shape.shape.y * scaleY,
                width: hall.shape.shape.width * scaleX,
                height: hall.shape.shape.height * scaleY,
              },
            },
          };
        } else if (hall.shape.type === "Polygon") {
          return {
            ...hall,
            shape: {
              ...hall.shape,
              shape: hall.shape.shape.map((point, index) =>
                index % 2 === 0 ? point * scaleX : point * scaleY
              ),
            },
          };
        }
        return hall;
      });
      setNewHalls(halls);
    }
  }, [data, image, containerRef]);

  const deleteAllStands = async () => {
    setStallDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}user/delete-stalls?id=${id}`,
        getHeader(ADMIN)
      );
      setReload((prev) => !prev);
      toast.success(response.data.message);
    } catch (error) {
      setHallDeleting(false);
      toastError(error);
    } finally {
      setStallDeleting(false);
    }
  };
  const hanldeHallMenuClick = (e, hall) => {
    setTargetHall(hall);
    setHmenuEl(e.target);
  };
  const handleGeneralPlanEdit = (e) => {
    setGmenuEl(e.target);
  };
  const handleGmenuClick = (event, action) => {
    setGmenuEl(null); // Close the menu

    switch (action) {
      case "Edit Image":
        break;

      case "Replace Image":
        setShowImageCrop(true);
        break;

      case "Delete Image":
        break;

      default:
        console.warn("Unknown action:", action);
        break;
    }
  };
  const handleHallDelete = async (id) => {
    setHallDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}user/delete-stand?id=${id}`,
        getHeader(ADMIN)
      );
      setReload((prev) => !prev);
      toast.success(response.data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setHallDeleting(false);
    }
  };
  const handleHmenuClick = (e, m) => {
    setHmenuEl(null); // Close the menu
    switch (m) {
      case "Edit Stand":
        navigate(`/home/fp-add-stand/${id}?editId=${targetHall._id}`, {
          replace: true,
        });
        break;

      case "Delete Stand":
        setShowHallDelete(true);
        console.log(m);
        break;

      default:
        console.warn("Unknown action:", m);
        break;
    }
  };
  const addNewHall = () => {
    navigate(`/home/fp-add-stand/${id}`, {
      replace: true,
    });
  };
  const onYesHallDelete = () => {
    setShowHallDelete(false);
    handleHallDelete(targetHall._id);
  };

  const onHallClick = (stand) => {
    setChall(data.hall.name);
    setCstand(stand.name);
    setIsOpen(true);
  };

  return (
    <Box sx={{ maxWidth: "1280px", ml: "auto", mr: "auto", mt: 2 }}>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: 300,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            boxSizing: "border-box",
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box>{chall && cstand && <Box>Exhibitor Details</Box>}</Box>
      </Drawer>

      <CustomCircularP show={isLoading || hallDeleting} />

      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={<FpCropEdit hallId={id} upload_type={HALL_IMAGE} />}
      />
      <CustomCircularP show={hallDeleting} />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete ${targetHall?.name}?`}
        onYes={onYesHallDelete}
        show={showHallDelete}
        setShow={setShowHallDelete}
      />
      <BackBtn />
      <Menu
        anchorEl={gMenuEl}
        open={Boolean(gMenuEl)}
        onClose={() => setGmenuEl(null)}
      >
        <MenuItem
          disabled={!image}
          onClick={(e) => handleGmenuClick(e, "Edit Image")}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Image
        </MenuItem>
        <MenuItem
          disabled={!image}
          onClick={(e) => handleGmenuClick(e, "Replace Image")}
        >
          <ListItemIcon>
            <FindReplaceIcon fontSize="small" />
          </ListItemIcon>
          Replace Image
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!image}
          sx={{ color: "red" }}
          onClick={(e) => handleGmenuClick(e, "Delete Image")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Image
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={hMenuEl}
        open={Boolean(hMenuEl)}
        onClose={() => setHmenuEl(null)}
      >
        <MenuItem onClick={(e) => handleHmenuClick(e, "Edit Stand")}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Stand
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: "red" }}
          onClick={(e) => handleHmenuClick(e, "Delete Stand")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Stand
        </MenuItem>
      </Menu>

      <Grid marginTop={1} container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // Adjust as per your needs
                }}
              >
                <Typography variant="h6">Hall Plan</Typography>

                <IconButton
                  onClick={handleGeneralPlanEdit}
                  color="primary"
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
              </Box>
              {data?.hall?.image ? (
                <Box
                  ref={containerRef}
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {image ? (
                    <Stage
                      width={dimensions.width}
                      height={dimensions.height}
                      scaleX={stageScale}
                      scaleY={stageScale}
                      x={stagePos.x}
                      y={stagePos.y}
                      draggable={true}
                      onWheel={handleWheel}
                    >
                      <Layer>
                        <Image
                          image={image.img}
                          width={Math.min(image.width, dimensions.width)}
                          height={
                            Math.min(image.width, dimensions.width) *
                            (image.height / image.width)
                          }
                          x={0}
                          y={0}
                        />
                        {newHalls
                          ?.filter((hall) => hall.shape.type === "Polygon")
                          .map((hall) => (
                            <Group>
                              <Line
                                points={hall.shape.shape}
                                fill={hexToRGBA(hall.color, TRANSPARENCY)}
                                stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                                strokeWidth={hall.strokeWidth}
                                closed={true}
                                onClick={() => {
                                  onHallClick(hall);
                                }}
                                onMouseEnter={(e) => {
                                  const shape = e.target;
                                  shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                                  shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                                }}
                                onMouseLeave={(e) => {
                                  const shape = e.target;
                                  shape.fill(
                                    hexToRGBA(hall.color, TRANSPARENCY)
                                  ); // Reset to the original color on mouse leave
                                  shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                                }}
                              />
                            </Group>
                          ))}
                        {newHalls
                          ?.filter((hall) => hall.shape.type === "Rectangle")
                          .map((hall) => (
                            <Group>
                              <Rect
                                x={hall.shape.shape.x}
                                y={hall.shape.shape.y}
                                width={hall.shape.shape.width}
                                height={hall.shape.shape.height}
                                fill={hexToRGBA(hall.color, TRANSPARENCY)}
                                onClick={(e) => {
                                  onHallClick(hall);
                                }}
                                onMouseEnter={(e) => {
                                  const shape = e.target;
                                  shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                                  shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                                }}
                                onMouseLeave={(e) => {
                                  const shape = e.target;
                                  shape.fill(
                                    hexToRGBA(hall.color, TRANSPARENCY)
                                  ); // Reset to the original color on mouse leave
                                  shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                                }}
                              />
                            </Group>
                          ))}
                      </Layer>
                    </Stage>
                  ) : (
                    <Box sx={{ p: 3, width: "100%", height: "100%" }}>
                      Loading ...
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  onClick={() => setShowImageCrop(true)}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    border: "2px dashed #054059",
                    borderRadius: 1,
                    padding: 4,
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="h6" color="#054059">
                    Click to upload Map
                  </Typography>
                  <Typography variant="body2">
                    Only images (JPG/JPEG, PNG) less than 2mb are allowed for
                    upload.
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // Adjust as per your needs
                  }}
                >
                  <Typography variant="h6">Stand List</Typography>

                  <Box display={"flex"} gap={1}>
                    <LoadingButton
                      startIcon={<DeleteOutlineIcon />}
                      size="small"
                      variant="contained"
                      color="error"
                      sx={{ textTransform: "none" }}
                      onClick={deleteAllStands}
                    >
                      Delete Stands
                    </LoadingButton>
                    <LoadingButton
                      startIcon={<AddCircleOutlineIcon />}
                      size="small"
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      onClick={addNewHall}
                    >
                      Add Stand
                    </LoadingButton>
                  </Box>
                </Box>

                <Typography variant="caption" gutterBottom>
                  EXHIBITION STANDS
                </Typography>

                <List sx={{ maxHeight: 450, overflowY: "auto" }}>
                  {data && data.stands.length > 0 ? (
                    data.stands
                      // Sort the stands alphabetically by their name
                      .sort((a, b) => a.name.localeCompare(b.name))
                      // Map each sorted hall to a Card component
                      .map((hall, index) => (
                        <Card
                          key={index}
                          variant="outlined"
                          sx={{ padding: 1, mt: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body1">{hall.name}</Typography>
                            <IconButton
                              onClick={(e) => hanldeHallMenuClick(e, hall)}
                              size="small"
                              aria-label="edit"
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Box>
                        </Card>
                      ))
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ mt: 3, textAlign: "center" }}
                    >
                      No stands available.
                    </Typography>
                  )}
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HallPlan;
