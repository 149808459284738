import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  Typography,
  TableFooter,
  FormControlLabel,
} from "@mui/material";
import MyDateRange from "../MyDateRange";

const RatedItemsRender = ({
  headers = [],
  itemsList = [],
  totalRequired,
  min,
  max,
  totalVar,
}) => {
  return (
    <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.key}>{header.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsList.map((row, index) => (
            <TableRow key={index}>
              {headers.map((header, i) => {
                if (header.type === "label") {
                  return (
                    <TableCell key={header.key}>
                      <Typography variant="body1">
                        {row[`key${i + 1}`]}
                      </Typography>
                    </TableCell>
                  );
                } else if (header.type === "label checkbox") {
                  return (
                    <TableCell key={header.key}>
                      <FormControlLabel
                        label={row[`key${i + 1}`]}
                        control={
                          <Checkbox
                            disabled={
                              row?.disabledItems &&
                              row.disabledItems.includes(i)
                            }
                          />
                        }
                      />
                    </TableCell>
                  );
                } else if (
                  header.type === "input text" ||
                  header.type === "input number"
                )
                  return (
                    <TableCell key={header.key}>
                      <TextField
                        type={
                          header.type === "input number" ? "number" : "text"
                        }
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    </TableCell>
                  );
                else if (header.type === "input date range")
                  return (
                    <TableCell key={header.key}>
                      <MyDateRange min={min} max={max} />
                    </TableCell>
                  );
                else if (header.type === "input checkbox")
                  return (
                    <TableCell key={header.key}>
                      <Checkbox
                        disabled={
                          row?.disabledItems && row.disabledItems.includes(i)
                        }
                      />
                    </TableCell>
                  );
                else if (header.type === "calc")
                  return (
                    <TableCell key={header.key}>
                      <Typography variant="body1">{""}</Typography>
                    </TableCell>
                  );
                else return <></>;
              })}
            </TableRow>
          ))}
        </TableBody>
        {totalRequired && (
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={headers.length - 1}>
                Total
              </TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" colSpan={headers.length - 1}>
                18% GST
              </TableCell>
              <TableCell>0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" colSpan={headers.length - 1}>
                Grand Total
              </TableCell>
              <TableCell>0</TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default RatedItemsRender;
