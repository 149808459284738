import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  Box,
  Popover,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryIcon from "@mui/icons-material/Category";
import DownloadIcon from "@mui/icons-material/Download";
import SupportIcon from "@mui/icons-material/Support";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import UpdateIcon from "@mui/icons-material/Update";
import { useNavigate } from "react-router-dom";

export const AppBarHeader = ({ open, handleDrawerOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isNotificationOpen = Boolean(anchorNotificationEl);
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationOpen = (event) => {
    setAnchorNotificationEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorNotificationEl(null);
  };

  // Example notification count
  const notificationCount = 3;

  // Sample notifications with icons and descriptions
  const sampleNotifications = [
    {
      id: 1,
      icon: <SupportIcon fontSize="small" />,
      text: "User Daya requested support",
      description: "Pending support request from Daya.",
      link: "#",
    },
    {
      id: 2,
      icon: <PersonAddIcon fontSize="small" />,
      text: "New user registration",
      description: "A new user registered.",
      link: "#",
    },
    {
      id: 3,
      icon: <UpdateIcon fontSize="small" />,
      text: "User Venil updated her profile",
      description: "Profile update request from Jane.",
      link: "#",
    },
  ];

  const handleNotificationClick = (link) => {
    handleNotificationClose();
    navigate(link);
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          IMTEX 2025
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* Notification Icon with Badge */}
        <IconButton color="inherit" onClick={handleNotificationOpen}>
          <Badge badgeContent={notificationCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {/* Notification Popover */}
        <Popover
          open={isNotificationOpen}
          anchorEl={anchorNotificationEl}
          onClose={handleNotificationClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ mt: 1 }}
        >
          <List sx={{ width: 350 }}>
            {sampleNotifications.map((notification, index) => (
              <React.Fragment key={notification.id}>
                <ListItemButton
                  onClick={() => handleNotificationClick(notification.link)}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                    p: 2,
                  }}
                >
                  <ListItemIcon>{notification.icon}</ListItemIcon>
                  <CardContent sx={{ p: 0 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {notification.text}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {notification.description}
                    </Typography>
                  </CardContent>
                </ListItemButton>
                {index < sampleNotifications.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Popover>
        {/* Profile Icon */}
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          sx={{ mt: 1 }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/home/profile");
            }}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/home/change-password");
            }}
          >
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
          {/* Improved Menu Item: Product Category as Card Box */}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/home/product-category");
            }}
            disableGutters
            sx={{ p: 1 }}
          >
            <Card
              sx={{
                display: "flex",
                width: "100%",
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                "&:hover": {
                  boxShadow: 6,
                },
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <ListItemIcon>
                    <CategoryIcon fontSize="small" />
                  </ListItemIcon>
                </Grid>
                <Grid item xs={10}>
                  <CardContent sx={{ p: 0 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Product Category
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      Manage product categories.
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </MenuItem>
          {/* Improved Menu Item: Export Data as Card Box */}
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/home/export-data");
            }}
            disableGutters
            sx={{ p: 1 }}
          >
            <Card
              sx={{
                display: "flex",
                width: "100%",
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                "&:hover": {
                  boxShadow: 6,
                },
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <ListItemIcon>
                    <DownloadIcon fontSize="small" />
                  </ListItemIcon>
                </Grid>
                <Grid item xs={10}>
                  <CardContent sx={{ p: 0 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Export Data
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      Export reports in various formats.
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              navigate("/logout");
            }}
          >
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
