import React, { useEffect, useState } from "react";
import MuPb from "../widgets/MuPb";
import { func } from "prop-types";
import { deleteUser } from "../utils/util";
import { ADMIN } from "../utils/constants";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    logoutUser();
  }, []);
  function logoutUser() {
    setTimeout(() => {
      deleteUser(ADMIN);
      navigate("/login");
    }, 200);
  }
  if (loading) return <MuPb />;
  return <div></div>;
};

export default Logout;
