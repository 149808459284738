import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill"; // Importing ReactQuill
import "react-quill/dist/quill.snow.css"; // Importing Quill CSS

const RegBadgePreview = () => {
  const [terms, setTerms] =
    useState(`<p><strong>Thank you for registering!</strong></p><p>An email is sent to you confirming your registration for <strong>IMTEX 2025</strong></p><p><br></p><p><strong>Badge Background</strong></p><p>VENIL MOTTANA<br>METROMINDZ</p><p><br></p><p>Please show your E-Badge at the Registration Counter to collect your Printed Badge.</p><p><br></p><p>Once you activate the link you can login and start using the IMTEX 2025 online portal.</p><p><br></p><p><strong>Visitor Badge:</strong> You may use fast-track registration at the event, to do so please take the print copy of your badge from your profile, by using the <strong>Print Badge</strong> button.</p><p><br></p><p><strong>Advantages of Pre-registering on Expoplanner:</strong></p><ul><li>Avoiding queues</li><li>Safe &amp; Secure entry to the venue</li><li>Know the products displayed</li><li>Search the booths from the floor plan</li><li>Connect and schedule your meetings</li></ul><p><br></p><p><strong>IMTEX Team</strong></p><p><br></p><p><em>Note:</em> If you haven't received our email, please check your spam or junk folder. Sometimes, emails might be mistakenly classified as spam.</p>
    `);
  const [successMessage, setSuccessMessage] = useState("");

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    setSuccessMessage("Terms and Conditions updated successfully");
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Update Registration Badge Preview
        </Typography>
        <Typography variant="body1" gutterBottom>
          Use{" "}
          <b>
            <code>{`{badge}`}</code>
          </b>{" "}
          to place the badge
        </Typography>

        <form onSubmit={handleSubmit}>
          <ReactQuill
            value={terms}
            onChange={setTerms}
            theme="snow" // Quill editor theme
            style={{ height: "300px", marginBottom: "20px" }}
          />

          {/* Submit Button */}
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Save Changes
            </Button>
          </Box>

          {/* Success Message */}
          {successMessage && (
            <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
              {successMessage}
            </Typography>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default RegBadgePreview;
