import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import {
  extractDate,
  extractTime,
  getHeader,
  toastError,
} from "../../utils/util";
import axios from "axios";
import * as XLSX from "xlsx";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
const ExportVisitorsButton = () => {
  const [loading, setLoading] = useState(false);
  const handleExport = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}user/a/get-all-visitors`,
        getHeader(ADMIN)
      );

      const filteredData = data?.map((item) => {
        return {
          "User ID": item.user_id,
          Title: item.title,
          "Barcode ID": item.barcode_id,
          Designation: item.designation,
          "Events List": Array.isArray(item.eventCategory)
            ? item.eventCategory?.join(", ")
            : [],
          "First Name": item.firstName,
          "Last Name": item.lastName,
          "Phone Number": item.mobileNo,
          Email: item.email,
          "Company Name": item.companyName,
          Address: item.address,
          Country: item.country?.name,
          State: item.stateRegion?.name,
          City: item.city?.name,
          "Postal Code": item.pinZip,
          Website: item.website,
          Telephone: item.telephoneNo,
          Department: item.department,
          "Event Discovery": item.eventDiscovery,
          "Industry Sector": item.industrySector,
          "Product Categories": Array.isArray(item.productCategories)
            ? item.productCategories?.join(", ")
            : [],
          "QR Link": item.qrLink,
          "Profile Image": item.profile_image,
          Date: extractDate(item.createdOn),
          Time: extractTime(item.createdOn),
        };
      });

      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Visitors");
      XLSX.writeFile(wb, "visitors.xlsx");
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <LoadingButton
        onClick={handleExport}
        variant="outlined"
        loading={loading}
        startIcon={<FileDownloadIcon />}
      >
        Export to Excel
      </LoadingButton>
    </>
  );
};

export default ExportVisitorsButton;
