import React, { useEffect, useRef, useMemo } from "react";
import Quill from "quill";
import { debounce } from "lodash";
import { API_ENDPOINT } from "../utils/constants";

const MyQuill = ({ readOnly, value, onChange, onSelectionChange }) => {
  const containerRef = useRef(null);
  const quillRef = useRef(null); // Internal reference for the Quill instance
  const onChangeRef = useRef(onChange);
  const onSelectionChangeRef = useRef(onSelectionChange);

  // Keep the onChange and onSelectionChange refs in sync
  useEffect(() => {
    onChangeRef.current = onChange;
    onSelectionChangeRef.current = onSelectionChange;
  }, [onChange, onSelectionChange]);

  // Enable or disable the editor based on the readOnly prop
  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.enable(!readOnly);
    }
  }, [readOnly]);

  // Memoize the toolbar options to avoid re-creating on every render
  const toolbarOptions = useMemo(
    () => [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ align: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { direction: "rtl" }],
      [{ color: [] }, { background: [] }],
    ],
    []
  );

  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = document.createElement("div");
    container.appendChild(editorContainer);

    // Initialize Quill
    const quill = new Quill(editorContainer, {
      modules: {
        toolbar: {
          container: toolbarOptions,
          handlers: {
            image: function () {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.click();

              input.onchange = async () => {
                const file = input.files[0];
                if (file) {
                  const formData = new FormData();
                  formData.append("image", file);
                  try {
                    const response = await fetch(
                      `${API_ENDPOINT}user/upload-image-file-tmp`,
                      {
                        method: "POST",
                        body: formData,
                      }
                    );
                    const data = await response.json();
                    const imageUrl = data.url;
                    const range = quill.getSelection(true);
                    quill.insertEmbed(range.index, "image", imageUrl);
                  } catch (error) {
                    console.error("Image upload failed", error);
                  }
                }
              };
            },
          },
        },
      },
      theme: "snow",
    });

    quillRef.current = quill;

    // Synchronize the initial value if provided
    if (value) {
      quill.root.innerHTML = value;
    }

    // Listen for text changes and call the onChange prop (debounced)
    const handleTextChange = debounce(() => {
      const content = quill.root.innerHTML;
      if (content !== value) {
        onChangeRef.current?.(content);
      }
    }, 300);

    quill.on(Quill.events.TEXT_CHANGE, handleTextChange);

    // Listen for selection changes and call the onSelectionChange prop
    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      if (quillRef.current && quillRef.current.hasFocus()) {
        onSelectionChangeRef.current?.(...args);
      }
    });

    return () => {
      quill.off(Quill.events.TEXT_CHANGE, handleTextChange);
      quill.off(Quill.events.SELECTION_CHANGE);
      quillRef.current = null;
      container.innerHTML = "";
    };
  }, [toolbarOptions]);

  // Update the editor content if the `value` prop changes externally
  useEffect(() => {
    if (quillRef.current && quillRef.current.root.innerHTML !== value) {
      const selection = quillRef.current.getSelection(true);
      quillRef.current.root.innerHTML = value || "";
      quillRef.current.setSelection(selection);
    }
  }, [value]);

  return <div ref={containerRef}></div>;
};

export default MyQuill;
