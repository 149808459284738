import axios from "axios";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getErrorResponse, toastError } from "../../utils/util";
import OnsiteFooter from "./OnsiteFooter";
import { useParams } from "react-router-dom";

function SecurityScan() {
  const { id } = useParams();
  const [scannedUsers, setCurrentUsers] = useState([]);
  const inputRef = useRef();

  useEffect(() => {
    //fetchUsers();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      //   const scannedValue = event.target.value;
      //   fetchUserDetails(scannedValue);
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };
  const fetchUserDetails = async (bid) => {
    try {
      const { data } = await axios.get(
        `https://apis.expoplanner.in/event/symposium-security-scan?barcode_id=${bid}`
      );
      toast.success(data.message, { autoClose: 1 });
      fetchUsers();
    } catch (error) {
      toast.error(getErrorResponse(error), { autoClose: 1 });
    }
  };
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(
        `https://apis.expoplanner.in/event/get-symposium-scanned-users-show`
      );
      setCurrentUsers(data);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Box>
      <Typography variant="h6">Scanned List</Typography>
      <TableContainer
        component={Paper}
        style={{ flexGrow: 1, maxHeight: "192px", overflowY: "auto" }}
      >
        <Table aria-label="scanned list table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Bar Code</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Scanned Date</TableCell>
              <TableCell>Scanned Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scannedUsers?.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row?.title} {row?.firstName} {row?.lastName} {row?.name}
                </TableCell>
                <TableCell>{row.barcode_id}</TableCell>
                <TableCell>{row?.role}</TableCell>
                <TableCell>
                  {new Date(row.latestScan).toLocaleDateString("en-IN")}
                </TableCell>
                <TableCell>
                  {new Date(row.latestScan).toLocaleTimeString("en-IN", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display={"flex"} justifyContent={"center"} mt={4} mb={4}>
        <input
          ref={inputRef}
          id="barcodeInput"
          type="text"
          // value={barcodeId}
          //   onChange={handleScanInput}
          onKeyDown={handleKeyDown}
          placeholder="Scan Badge"
          style={{
            padding: "10px",
            fontSize: "16px",
            width: "300px",
            textAlign: "center",
            borderRadius: "4px",
            border: "2px solid #000",
          }}
        />
      </Box>
    </Box>
  );
}

export default SecurityScan;
