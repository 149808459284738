import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const PurchaseItems = ({ onDelete, itemsList = [], isEdit = false }) => {
  const handleAddToCart = (item) => {};
  const handleDeleteItem = (item) => {
    if (onDelete) onDelete(item);
  };

  return (
    <Box p={2}>
      {itemsList.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" color="text.secondary">
            No items available. Please add items here!
          </Typography>
        </Box>
      )}
      <Grid container spacing={2}>
        {itemsList?.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
            <Card sx={{ boxShadow: 3 }}>
              <CardMedia
                component="img"
                height="200"
                image={item.image}
                alt={item.name}
                sx={{ objectFit: "cover" }}
              />
              <CardContent>
                <Typography gutterBottom variant="body1" component="div">
                  {item.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  ₹{item.price}
                </Typography>
              </CardContent>
              <CardActions>
                <Stack width={"100%"} direction={"row"} gap={1}>
                  <Box flex={1}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<ShoppingCartIcon fontSize="small" />}
                      onClick={() => handleAddToCart(item)}
                      fullWidth
                    >
                      Add
                    </Button>
                  </Box>

                  {isEdit && (
                    <IconButton
                      onClick={() => handleDeleteItem(item)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PurchaseItems;
