import React, { useState, useEffect, useMemo } from "react";
import parse from "html-react-parser";
import debounce from "lodash.debounce";

const ParagraphDescription = ({ properties }) => {
  const [debouncedLabel, setDebouncedLabel] = useState(properties.label);

  // Debounce the label update
  const debouncedUpdate = useMemo(
    () => debounce((label) => setDebouncedLabel(label), 300), // 300ms debounce delay
    []
  );
  useEffect(() => {
    debouncedUpdate(properties.label);
  }, [properties.label, debouncedUpdate]);

  // Clean up the debounce function on unmount
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  const parsedLabel = useMemo(() => parse(debouncedLabel), [debouncedLabel]);

  return <>{parsedLabel}</>;
};

export default ParagraphDescription;
