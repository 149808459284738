import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const initialData = {
  droppable1: {
    id: "droppable1",
    items: ["item 1", "item 2"],
    nestedDroppable: "droppable2",
  },
  droppable2: {
    id: "droppable2",
    items: ["item 3", "item 4"],
  },
};

const DragDroppable = () => {
  const [data, setData] = useState(initialData);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    console.log("end", result);

    if (!destination) {
      return;
    }

    const sourceDroppable = data[source.droppableId];
    const destDroppable = data[destination.droppableId];

    if (source.droppableId === destination.droppableId) {
      const items = Array.from(sourceDroppable.items);
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);

      setData({
        ...data,
        [source.droppableId]: {
          ...sourceDroppable,
          items,
        },
      });
    } else {
      const sourceItems = Array.from(sourceDroppable.items);
      const [removed] = sourceItems.splice(source.index, 1);
      const destItems = Array.from(destDroppable.items);
      destItems.splice(destination.index, 0, removed);

      setData({
        ...data,
        [source.droppableId]: {
          ...sourceDroppable,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destDroppable,
          items: destItems,
        },
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable1">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              padding: 16,
              border: "1px solid lightgrey",
              marginBottom: 16,
            }}
          >
            {data.droppable1.items.map((item, index) => (
              <Draggable key={item} draggableId={item} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      padding: 8,
                      border: "1px solid darkgrey",
                      marginBottom: 8,
                      ...provided.draggableProps.style,
                    }}
                  >
                    {item}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <Droppable droppableId="droppable2">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    padding: 16,
                    border: "1px solid lightgrey",
                    marginTop: 16,
                  }}
                >
                  {data.droppable2.items.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            padding: 8,
                            border: "1px solid darkgrey",
                            marginBottom: 8,
                            ...provided.draggableProps.style,
                          }}
                        >
                          {item}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDroppable;
