import loader from "../assets/gifs/loader.gif";
const CustomCircularP = ({ show }) => {
  return (
    <div
      style={{
        display: show ? "table" : "none",
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: 99,
        transform: "translate(-50%, -50%)",
      }}
    >
      <img src={loader} alt="" />
    </div>
  );
};
export default CustomCircularP;
