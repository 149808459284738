import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  CircularProgress,
  Avatar,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";

const CountrywiseRegistrationsCard = () => {
  const { data: countrywiseData, isLoading: isCountrywiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-countrywise-registrations`);

  const dataByCountry = useMemo(() => {
    if (!countrywiseData || !Array.isArray(countrywiseData.registrations))
      return [];

    // Filter out items where `item.country` is null or undefined
    return countrywiseData.registrations
      .filter((item) => item.country) // Keep only items with a valid country
      .map((item) => ({
        country: item.country,
        count: item.count,
        flag: item.countryDetails.flag,
      }));
  }, [countrywiseData]);

  const chartHeight = dataByCountry.length * 50;

  if (isCountrywiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={chartHeight}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  // Custom YAxis tick component
  const RenderYAxisTick = (props) => {
    const { x, y, payload } = props;
    const country = payload.value;
    const countryData = dataByCountry.find((item) => item.country === country);

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject x={-150} y={-10} width={150} height={20}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              src={countryData?.flag}
              alt={country}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">{country}</Typography>
          </Stack>
        </foreignObject>
      </g>
    );
  };

  // Custom Tooltip component
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            background: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              src={data.flag}
              alt={data.country}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">
              {data.country}: {data.count} visitors
            </Typography>
          </Stack>
        </div>
      );
    }
    return null;
  };

  return (
    <Card>
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          <Typography flex={1} variant="h6">
            Registrations by Country
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              exportToExcel(
                dataByCountry.map(({ country, count }) => ({
                  country,
                  count,
                })),
                "Registrations_by_Country"
              )
            }
          >
            Export
          </Button>
        </Stack>
        <ResponsiveContainer width="100%" height={chartHeight || 250}>
          <BarChart
            data={dataByCountry}
            layout="vertical"
            margin={{ left: 20, right: 60 }} // Increased right margin
          >
            <XAxis type="number" />
            <YAxis
              type="category"
              dataKey="country"
              width={150}
              tick={<RenderYAxisTick />}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="count" fill="#82ca9d">
              <LabelList dataKey="count" position="right" offset={20} />{" "}
              {/* Adjusted offset */}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default CountrywiseRegistrationsCard;
