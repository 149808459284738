import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  Paper,
  Container,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const CategoryBuilder = () => {
  const [categories, setCategories] = useState([
    { subcategories: [{ subsubcategories: [""] }] },
  ]);

  const handleAddCategory = () => {
    setCategories([
      ...categories,
      { subcategories: [{ subsubcategories: [""] }] },
    ]);
  };

  const handleRemoveCategory = (index) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
  };

  const handleAddSubcategory = (categoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.push({
      subsubcategories: [""],
    });
    setCategories(updatedCategories);
  };

  const handleRemoveSubcategory = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.splice(subcategoryIndex, 1);
    setCategories(updatedCategories);
  };

  const handleAddSubsubcategory = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[
      subcategoryIndex
    ].subsubcategories.push("");
    setCategories(updatedCategories);
  };

  const handleRemoveSubsubcategory = (
    categoryIndex,
    subcategoryIndex,
    subsubcategoryIndex
  ) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[
      subcategoryIndex
    ].subsubcategories.splice(subsubcategoryIndex, 1);
    setCategories(updatedCategories);
  };

  const handleCategoryChange = (event, categoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].name = event.target.value;
    setCategories(updatedCategories);
  };

  const handleSubcategoryChange = (event, categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[subcategoryIndex].name =
      event.target.value;
    setCategories(updatedCategories);
  };

  const handleSubsubcategoryChange = (
    event,
    categoryIndex,
    subcategoryIndex,
    subsubcategoryIndex
  ) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[
      subcategoryIndex
    ].subsubcategories[subsubcategoryIndex] = event.target.value;
    setCategories(updatedCategories);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(categories);
  };

  return (
    <Box>
      <Container>
        <Paper sx={{ p: 2, mt: 2 }}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" gutterBottom>
              Category Builder
            </Typography>
            {categories.map((category, categoryIndex) => (
              <Box key={categoryIndex} sx={{ marginBottom: 2 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      label={`Main Category ${categoryIndex + 1}`}
                      variant="outlined"
                      fullWidth
                      value={category.name || ""}
                      onChange={(event) =>
                        handleCategoryChange(event, categoryIndex)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => handleRemoveCategory(categoryIndex)}
                    >
                      <Remove sx={{ color: "red" }} />
                    </IconButton>
                  </Grid>
                </Grid>
                {category.subcategories.map((subcategory, subcategoryIndex) => (
                  <Box
                    key={subcategoryIndex}
                    sx={{ marginLeft: 4, marginTop: 1 }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={10}>
                        <TextField
                          size="small"
                          label={`Subcategory ${subcategoryIndex + 1}`}
                          variant="outlined"
                          fullWidth
                          value={subcategory.name || ""}
                          onChange={(event) =>
                            handleSubcategoryChange(
                              event,
                              categoryIndex,
                              subcategoryIndex
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            handleRemoveSubcategory(
                              categoryIndex,
                              subcategoryIndex
                            )
                          }
                        >
                          <Remove sx={{ color: "red" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {subcategory.subsubcategories.map(
                      (subsubcategory, subsubcategoryIndex) => (
                        <Box
                          key={subsubcategoryIndex}
                          sx={{ marginLeft: 4, marginTop: 1 }}
                        >
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={10}>
                              <TextField
                                size="small"
                                label={`Subsubcategory ${
                                  subsubcategoryIndex + 1
                                }`}
                                variant="outlined"
                                fullWidth
                                value={subsubcategory || ""}
                                onChange={(event) =>
                                  handleSubsubcategoryChange(
                                    event,
                                    categoryIndex,
                                    subcategoryIndex,
                                    subsubcategoryIndex
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() =>
                                  handleRemoveSubsubcategory(
                                    categoryIndex,
                                    subcategoryIndex,
                                    subsubcategoryIndex
                                  )
                                }
                              >
                                <Remove sx={{ color: "red" }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    )}
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Add />}
                      onClick={() =>
                        handleAddSubsubcategory(categoryIndex, subcategoryIndex)
                      }
                      sx={{ marginLeft: 4, marginTop: 1 }}
                    >
                      Add Subsubcategory
                    </Button>
                  </Box>
                ))}
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => handleAddSubcategory(categoryIndex)}
                  sx={{ marginLeft: 4, marginTop: 1 }}
                >
                  Add Subcategory
                </Button>
              </Box>
            ))}
            <Button
              size="small"
              variant="outlined"
              startIcon={<Add />}
              onClick={handleAddCategory}
              sx={{ marginTop: 2 }}
            >
              Add Main Category
            </Button>
            <Box gap={2} display={"flex"} justifyContent={"end"}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginTop: 2 }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                sx={{ marginTop: 2 }}
              >
                Export
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default CategoryBuilder;
