import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Card,
  Button,
} from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import {
  Circle,
  Group,
  Image as KonvaImage,
  Layer,
  Line,
  Rect,
  Stage,
  Transformer,
} from "react-konva";
import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINT, TRANSPARENCY } from "../../utils/constants";
import { getHeader, hexToRGBA, toastError, useQuery } from "../../utils/util";
import axios from "axios";
import StandZoneForm from "./StandZoneForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseButton from "../../widgets/CloseButton";
import { ADMIN } from "../../utils/constants";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { getImageDimen } from "./GeneralFloorPlan";
export const CONTAINER_WIDTH = 1280;
export const CONTAINER_HEIGHT = 560;
export const CONTAINER_AR = 1280 / 600;
export const getRelativePointerPosition = (node) => {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  return transform.point(pos);
};
const AddStand = () => {
  const { id } = useParams();
  const queryParams = useQuery();
  const sentId = queryParams.get("editId");
  const navigate = useNavigate();
  const [editId, setEditId] = useState();
  const [realodFp, setReloadFp] = useState(false);
  const rectRef = useRef(null);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-stands?id=${id}&r=${realodFp}`,
    0
  );
  const RECTANGLE = "Rectangle";
  const POLYGON = "Polygon";
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationV, setConfirmationV] = useState("");
  const [showStand, setShowStand] = useState(false);
  const [image, setImage] = useState(null);

  const [stageScale, setStageScale] = useState(1);
  const [points, setPoints] = useState([]);
  const [dots, setDots] = useState([]);
  const [rectangle, setRectangle] = useState(null);

  const [currentHall, setCurrentHall] = useState();

  const [polygonClosed, setPolygonClosed] = useState(false);
  const [selectedMode, setSelectedMode] = useState("");
  const [drawing, setDrawing] = useState(false);
  const [resizing, setResizing] = useState(false);
  const transformerRef = React.useRef(null);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [strokeColor, setStrokeColor] = useState("#1876d1");
  const stageRef = useRef(null);

  const increaseStrokeWidth = () => {
    setStrokeWidth((prevCount) => prevCount + 1);
  };

  const decreaseStrokeWidth = () => {
    if (strokeWidth > 0) {
      setStrokeWidth((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    if (data && data.hall && !image) {
      const img = new window.Image();
      img.src = data.hall.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [data]);
  useEffect(() => {
    if (sentId) {
      setEditId(sentId);
    }
  }, [sentId]);

  useEffect(() => {
    if (editId && data?.stands) {
      const hallToEdit = data.stands.find((hall) => hall._id === editId);
      if (hallToEdit) {
        setCurrentHall(hallToEdit);
        if (hallToEdit.shape.type === POLYGON) {
          const hallPoints = [...hallToEdit.shape.shape]; // Assuming this is an array of points
          setPoints(hallPoints);

          const formattedDots = [];
          for (let i = 0; i < hallPoints.length; i += 2) {
            formattedDots.push({ x: hallPoints[i], y: hallPoints[i + 1] });
          }
          setStrokeWidth(hallToEdit.strokeWidth);
          setStrokeColor(hallToEdit.color);
          setDots(formattedDots);
          setPolygonClosed(true);
          setSelectedMode(POLYGON);
        } else if (hallToEdit.shape.type === RECTANGLE) {
          setRectangle({ ...hallToEdit.shape.shape });
          setStrokeWidth(hallToEdit.strokeWidth);
          setStrokeColor(hallToEdit.color);
          setSelectedMode(RECTANGLE);
        }
      }
    }
  }, [editId, data?.stands]);

  const handleStageClick = (e) => {
    if (polygonClosed || selectedMode !== POLYGON) return;
    if (strokeWidth < 1) {
      toast.info("Please add stroke width");
      return;
    }
    const stage = e.target.getStage();
    const mousePos = getRelativePointerPosition(stage);

    // Account for the zoom level and offsets
    const newPoints = [...points, mousePos.x, mousePos.y];
    setPoints(newPoints);
    setDots([...dots, { x: mousePos.x, y: mousePos.y }]);

    // Check if user clicked near the starting point to close the polygon
    if (newPoints.length >= 6) {
      const dx = newPoints[0] - mousePos.x;
      const dy = newPoints[1] - mousePos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < 10) {
        // Set the last point to be equal to the first point for perfect closure
        const adjustedPoints = [...newPoints];
        adjustedPoints[adjustedPoints.length - 2] = adjustedPoints[0];
        adjustedPoints[adjustedPoints.length - 1] = adjustedPoints[1];

        setPoints(adjustedPoints);
        setDots([...dots, { x: adjustedPoints[0], y: adjustedPoints[1] }]);

        setPolygonClosed(true);
      }
    }
  };
  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.08;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    const minScale = 0.5;
    const maxScale = 3;
    const finalScale = Math.max(minScale, Math.min(maxScale, newScale));

    setStageScale(finalScale);
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: pointer.x - mousePointTo.x * finalScale,
      y: pointer.y - mousePointTo.y * finalScale,
    };

    setStagePos(newPos);
  };

  const startDrawing = (e) => {
    if (rectangle || selectedMode !== RECTANGLE) return;
    setDrawing(true);

    const pos = getRelativePointerPosition(stageRef.current);
    setRectangle({
      x: pos.x,
      y: pos.y,
      width: 0,
      height: 0,
    });
  };
  const updateDrawing = (e) => {
    if (!drawing || !rectangle) return;
    const pos = getRelativePointerPosition(stageRef.current);

    const newRect = {
      ...rectangle,
      width: pos.x - rectangle.x,
      height: pos.y - rectangle.y,
    };

    setRectangle(newRect);
  };
  const endDrawing = () => {
    setDrawing(false);
  };

  const handleDragMove = (e) => {
    if (resizing) {
      const node = e.target;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // Update the actual width and height based on the scale factors
      setRectangle({
        x: node.x(),
        y: node.y(),
        width: Math.min(50, node.width() * scaleX),
        height: Math.min(50, node.height() * scaleY),
      });

      // Reset scale factors
      node.scaleX(1);
      node.scaleY(1);
    }
  };
  const handleModeChange = (event) => {
    let cm = event.target.value;
    if (selectedMode !== "") {
      setOpenConfirmation(true);
      setConfirmationV(cm);
    } else {
      setSelectedMode(cm);
    }
  };

  const handleDragMovePolygon = (index, event) => {
    const newDots = dots.slice(); // Create a copy of the dots array
    newDots[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };

    // Update the points array for the polygon
    const newPoints = newDots.reduce(
      (acc, dot) => acc.concat([dot.x, dot.y]),
      []
    );
    setPoints(newPoints);
    setDots(newDots);
  };

  const handlePolygonDragEnd = (e) => {
    const groupNode = e.target;
    const dx = groupNode.x();
    const dy = groupNode.y();
    // Adjust the points based on the group's drag position
    const newPoints = points.map((point, index) =>
      index % 2 === 0 ? point + dx : point + dy
    );
    // Update dots based on the new points
    const newDots = [];
    for (let i = 0; i < newPoints.length; i += 2) {
      newDots.push({ x: newPoints[i], y: newPoints[i + 1] });
    }
    // Update state
    setPoints(newPoints);
    setDots(newDots);
    // Reset group position (so it won't move further)
    groupNode.x(0);
    groupNode.y(0);
  };

  const chooseColor = () => {
    setShowColorPicker(true);
  };
  const onColorChange = (color, event) => {
    setStrokeColor(color.hex);
  };
  const onConfirmSwitchTool = () => {
    setOpenConfirmation(false);
    setSelectedMode(confirmationV);
    resetDrawing();
  };
  const onSave = async () => {
    if (
      selectedMode === "" ||
      (!(points.length >= 6 && polygonClosed) && !rectangle)
    ) {
      toast.error(`Please draw the shape first ${points.length >= 6}}`);
    } else {
      if (editId && currentHall) {
        const transformedRect = transformerRef?.current?.nodes()[0];

        if (selectedMode === RECTANGLE && !transformedRect) {
          toast.error("Please draw the rectangle first");
          return;
        }

        const shape = {
          type: selectedMode,
          shape:
            selectedMode === RECTANGLE
              ? {
                  x: transformedRect.x(),
                  y: transformedRect.y(),
                  width: transformedRect.width() * transformedRect.scaleX(),
                  height: transformedRect.height() * transformedRect.scaleY(),
                  rotation: transformedRect.rotation(),
                }
              : points,
        };

        const dataToEdit = {
          color: strokeColor,
          strokeWidth: strokeWidth,
          shape: shape,
        };
        try {
          const { data } = await axios.post(
            `${API_ENDPOINT}admin/update-stand?id=${currentHall._id}`,
            dataToEdit,
            getHeader(ADMIN)
          );
          toast.success(data.message);
          setReloadFp((prev) => !prev);
        } catch (error) {
          toastError(error);
        } finally {
          resetDrawing();
        }
      } else {
        const transformedRect = transformerRef?.current?.nodes()[0];
        if (transformedRect && selectedMode === RECTANGLE) {
          setRectangle({
            x: transformedRect.x(),
            y: transformedRect.y(),
            width: transformedRect.width() * transformedRect.scaleX(),
            height: transformedRect.height() * transformedRect.scaleY(),
            rotation: transformedRect.rotation(),
          });
          transformedRect.scaleX(1);
          transformedRect.scaleY(1);
          transformedRect.rotation(0);
        }
        setShowStand(true);
      }
    }
  };
  const onAdded = () => {
    setShowStand(false);
    setReloadFp((prev) => !prev);
  };
  function resetDrawing() {
    setEditId(null);
    setCurrentHall(null);
    setSelectedMode("");
    setRectangle(null);
    setPoints([]);
    setDots([]);
  }
  return (
    <Box maxWidth={1280} m="8px auto">
      <RdMyModal
        showModal={showStand}
        setShowModal={setShowStand}
        modalC={
          <StandZoneForm
            color={strokeColor}
            strokeWidth={strokeWidth}
            hallId={id}
            shape={{
              type: selectedMode,
              shape: selectedMode === "Rectangle" ? rectangle : points,
            }}
            onUpdated={onAdded}
            show={showStand}
            setShow={setShowStand}
            resetDrawing={resetDrawing}
          />
        }
      />
      <Box>
        <ConfirmationModal
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={onConfirmSwitchTool}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" flex={1} alignItems="center" gap={2}>
            <Button
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(`/home/hall-plan/${id}`, { replace: true });
              }}
              startIcon={<ArrowBackIcon fontSize="small" />}
            >
              {data?.hall?.name}
            </Button>
            <Typography variant="h6">
              {" "}
              {editId && currentHall ? `Edit ${currentHall.name}` : "Add Stand"}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" flex={1} gap={3}>
            <Paper
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#E8F4F9",
              }}
            >
              <Stack direction="column" alignItems="center">
                <Typography variant="body2" align="center">
                  Color
                </Typography>
                <IconButton
                  onClick={chooseColor}
                  sx={{ color: strokeColor }}
                  size="small"
                >
                  <CircleIcon />
                </IconButton>
                {showColorPicker && (
                  <Draggable>
                    <div style={{ position: "absolute", zIndex: 100 }}>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <CloseButton
                          myStyle={{ bottom: "-12px", left: "12px" }}
                          onClose={() => setShowColorPicker(false)}
                        />
                      </div>
                      <SketchPicker
                        color={strokeColor}
                        onChange={onColorChange}
                      />
                    </div>
                  </Draggable>
                )}
              </Stack>
            </Paper>

            <Paper
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#E8F4F9",
              }}
            >
              <Typography variant="body2" align="center">
                Stroke Width
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={decreaseStrokeWidth}
                  color="primary"
                  size="small"
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
                <Typography variant="subtitle1">{strokeWidth}</Typography>
                <IconButton
                  onClick={increaseStrokeWidth}
                  color="primary"
                  size="small"
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Paper>

            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#E8F4F9",
              }}
            >
              <RadioGroup row value={selectedMode} onChange={handleModeChange}>
                <FormControlLabel
                  value={RECTANGLE}
                  control={<Radio />}
                  label={RECTANGLE}
                />
                <FormControlLabel
                  value={POLYGON}
                  control={<Radio />}
                  label={POLYGON}
                />
              </RadioGroup>
            </Paper>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <LoadingButton
              onClick={onSave}
              size="small"
              variant="contained"
              startIcon={<SaveAsIcon />}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
        <Card
          variant="outlined"
          sx={{
            mt: 1,
            ml: "auto",
            mr: "auto",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {image && (
            <Stage
              width={1280}
              height={560}
              scaleX={stageScale}
              scaleY={stageScale}
              ref={stageRef}
              x={stagePos.x}
              y={stagePos.y}
              onWheel={handleWheel}
              draggable={!drawing}
              onMouseDown={startDrawing}
              onMouseMove={updateDrawing}
              onMouseUp={endDrawing}
              onClick={handleStageClick}
            >
              <Layer>
                <KonvaImage
                  image={image.img}
                  width={getImageDimen(image.width, image.height).width}
                  height={getImageDimen(image.width, image.height).height}
                  x={0}
                  y={0}
                />

                {data?.stands
                  ?.filter(
                    (hall) =>
                      hall.shape.type === "Polygon" && hall._id !== editId
                  )
                  .map((hall) => (
                    <Group>
                      <Line
                        points={hall.shape.shape}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        closed={true}
                        onClick={(e) => {
                          // This is where your click logic goes
                          console.log(`Clicked on hall with id: ${hall}`);
                          e.cancelBubble = true;
                          setEditId(hall._id);
                        }}
                      />
                    </Group>
                  ))}
                {data?.stands
                  ?.filter(
                    (hall) =>
                      hall.shape.type === "Rectangle" && hall._id !== editId
                  )
                  .map((hall) => (
                    <Rect
                      x={hall.shape.shape.x}
                      y={hall.shape.shape.y}
                      rotation={hall.shape.shape.rotation || 0}
                      width={hall.shape.shape.width}
                      height={hall.shape.shape.height}
                      fill={hexToRGBA(hall.color, TRANSPARENCY)}
                      stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                      strokeWidth={hall.strokeWidth}
                      onClick={(e) => {
                        e.cancelBubble = true;
                        // navigate(`/fp-add-stand/${id}?editId=${hall._id}`, {
                        //   replace: true,
                        // });
                        setEditId(hall._id);
                      }}
                    />
                  ))}

                {selectedMode === POLYGON && (
                  <Group draggable onDragEnd={handlePolygonDragEnd}>
                    <Line
                      points={points}
                      fill={hexToRGBA(strokeColor, TRANSPARENCY)}
                      stroke={hexToRGBA(strokeColor, TRANSPARENCY)}
                      strokeWidth={strokeWidth}
                      closed={polygonClosed}
                    />
                    {dots.map((dot, index) => (
                      <Circle
                        key={index}
                        x={dot.x}
                        y={dot.y}
                        radius={5}
                        fill="red"
                        draggable
                        onDragStart={(e) => {
                          e.cancelBubble = true;
                        }}
                        onDragMove={(e) => {
                          e.cancelBubble = true;
                          handleDragMovePolygon(index, e);
                        }}
                        onDragEnd={(e) => {
                          e.cancelBubble = true;
                        }}
                      />
                    ))}
                  </Group>
                )}

                {rectangle && selectedMode === RECTANGLE && (
                  <Rect
                    ref={rectRef}
                    x={rectangle.x}
                    y={rectangle.y}
                    rotation={rectangle.rotation || 0}
                    width={rectangle.width}
                    height={rectangle.height}
                    fill={hexToRGBA(strokeColor, TRANSPARENCY)}
                    draggable
                    onDragStart={() => setResizing(false)}
                    onDragEnd={handleDragMove}
                    onClick={(e) => {
                      e.cancelBubble = true;
                      transformerRef.current.nodes([e.target]);
                      transformerRef.current.getLayer().batchDraw();
                      setResizing(true);
                    }}
                  />
                )}
                {rectangle && selectedMode === RECTANGLE && (
                  <Transformer
                    ref={transformerRef}
                    rotateEnabled={true} // Disable rotation
                    boundBoxFunc={(oldBox, newBox) => {
                      if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                      }
                      return newBox;
                    }}
                  />
                )}
              </Layer>
            </Stage>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default AddStand;
