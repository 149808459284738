import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
  Chip,
  Alert,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import useCommonGetInit from "../hooks/useCommonGetInit";
import MuPb from "../widgets/MuPb";
import { getErrorResponse } from "../utils/util";
import { API_ENDPOINT } from "../utils/constants";
import BackBtn from "../widgets/BackBtn";

// Styled component for the banner image
const BannerImage = styled("img")({
  width: "100%",
  height: "300px",
  objectFit: "cover",
  borderRadius: "8px",
});

const EventDetail = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}super/get-event?id=${id}`
  );

  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  // Render the event details
  return (
    <Box p={4}>
      <BackBtn />
      <Container>
        {data.eventBannerUrl && (
          <BannerImage
            src={data.eventBannerUrl}
            alt={`${data.eventName} Banner`}
          />
        )}
        <Card sx={{ mt: 4, p: 3 }}>
          <CardContent>
            {/* Event Name and Subdomain */}
            <Typography variant="h4" gutterBottom>
              {data.eventName}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Subdomain: <strong>{data.eventSubdomain}</strong>
            </Typography>

            {/* Event Date and Location */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <strong>Event Dates:</strong>{" "}
                  {new Date(data.eventDateFrom).toLocaleDateString()} -{" "}
                  {new Date(data.eventDateTo).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <strong>Location:</strong> {data.city}, {data.country}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <strong>Phone Code:</strong> {data.phoneCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <strong>Session Lifetime:</strong> {data.sessionLifetime}{" "}
                  minutes
                </Typography>
              </Grid>
            </Grid>

            {/* Event Images */}
            <Box display="flex" alignItems="center" sx={{ mt: 4 }}>
              {/* Event Logo */}
              {data.eventLogoUrl && (
                <Avatar
                  src={data.eventLogoUrl}
                  alt={`${data.eventName} Logo`}
                  sx={{ width: 100, height: 100, mr: 3 }}
                />
              )}

              {/* Event Favicon */}
              {data.faviconUrl && (
                <Avatar
                  src={data.faviconUrl}
                  alt={`${data.eventName} Favicon`}
                  sx={{ width: 50, height: 50 }}
                />
              )}
            </Box>

            {/* Module Requirements */}
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Module Requirements
            </Typography>
            <Grid container spacing={2}>
              {data.isFloorPlanRequired === "true" && (
                <Grid item>
                  <Chip label="Floor Plan Required" color="primary" />
                </Grid>
              )}
              {data.isOesFormRequired === "true" && (
                <Grid item>
                  <Chip label="OES Form Required" color="secondary" />
                </Grid>
              )}
              {data.isOnsiteModuleRequired === "true" && (
                <Grid item>
                  <Chip label="Onsite Module Required" color="success" />
                </Grid>
              )}
              {data.isMeetingSchedulerRequired === "true" && (
                <Grid item>
                  <Chip label="Meeting Scheduler Required" color="warning" />
                </Grid>
              )}
              {data.isMobileAppsRequired === "true" && (
                <Grid item>
                  <Chip label="Mobile Apps Required" color="info" />
                </Grid>
              )}
            </Grid>

            {/* Created and Updated Info */}
            <Box mt={4}>
              <Typography variant="body2" color="textSecondary">
                <strong>Created On:</strong>{" "}
                {new Date(data.createdOn).toLocaleDateString()} at{" "}
                {new Date(data.createdOn).toLocaleTimeString()}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <strong>Last Updated On:</strong>{" "}
                {new Date(data.updatedOn).toLocaleDateString()} at{" "}
                {new Date(data.updatedOn).toLocaleTimeString()}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default EventDetail;
