import { Box, Container } from "@mui/material";
import React from "react";
import OnsiteFooter from "./OnsiteFooter";
import OnsiteHeader from "./OnsiteHeader";
import { Route, Routes } from "react-router-dom";
import SpotRegistration from "./SpotRegistration";
import SecurityScan from "./SecurityScan";
import OnsiteReuseContainer from "./OnsiteReuseContainer";
import SelfPrinting from "./SelfPrinting";
import BulkPrinting from "./BulkPrinting";

const OnsiteContainer = () => {
  return (
    <OnsiteReuseContainer>
      <Routes>
        <Route path="spot-registration/:id" element={<SpotRegistration />} />
        <Route path="security-scan/:id" element={<SecurityScan />} />
        <Route path="self-printing/:id" element={<SelfPrinting />} />
        <Route path="bulk-printing/:id" element={<BulkPrinting />} />
        {/* 
          
          <Route
            path="/onsite/footfall-report/:id"
            element={<SpotRegistration />}
          />
          <Route
            path="/onsite/search-print/:id"
            element={<SpotRegistration />}
          /> */}
      </Routes>
    </OnsiteReuseContainer>
  );
};

export default OnsiteContainer;
