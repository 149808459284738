import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Switch,
  Stack,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import MoreIcon from "@mui/icons-material/More";
import HistoryIcon from "@mui/icons-material/History";

const KeyValueForm = ({ headers, items, onSave, currentList = [] }) => {
  const [rows, setRows] = useState(
    currentList.length > 0
      ? currentList
      : [{ ...items.reduce((acc, key) => ({ ...acc, [key]: "" }), {}) }]
  );

  const [currentRow, setCurrentRow] = useState(null);

  useEffect(() => {
    if (currentList.length > 0) {
      setRows(currentList);
    }
  }, [currentList]);

  const handleInputChange = (rowIndex, key, value) => {
    setRows((prevRows) =>
      prevRows.map((row, index) =>
        index === rowIndex ? { ...row, [key]: value } : row
      )
    );
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        ...items.reduce(
          (acc, key) => ({ ...acc, [key]: "", enabled: true }),
          {}
        ),
      },
    ]);
  };

  const handleDeleteRow = (rowIndex) => {
    setRows((prevRows) => prevRows.filter((_, index) => index !== rowIndex));
  };

  const handleSave = () => {
    if (onSave) {
      onSave(rows);
      toast.info("Saved locally! Please save form to save the data");
    }
  };

  const hasInputCheckbox = (idx) => {
    return headers
      .slice(idx)
      .some(
        (item) =>
          item.type === "input checkbox" || item.type === "label checkbox"
      );
  };
  const handleEnableItem = (rowIndex, itemIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const cRow = { ...updatedRows[rowIndex] };
      cRow.disabledItems = cRow.disabledItems || [];
      if (cRow.disabledItems.includes(itemIndex)) {
        cRow.disabledItems = cRow.disabledItems.filter(
          (index) => index !== itemIndex
        );
      } else {
        cRow.disabledItems.push(itemIndex);
      }
      updatedRows[rowIndex] = cRow;
      return updatedRows;
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {items.map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {items.map((key) => (
                  <TableCell key={key}>
                    <TextField
                      sx={{ minWidth: "100px" }}
                      size="small"
                      value={row[key]}
                      onChange={(e) =>
                        handleInputChange(rowIndex, key, e.target.value)
                      }
                    />
                  </TableCell>
                ))}
                {hasInputCheckbox(rowIndex) && (
                  <TableCell>
                    <IconButton
                      onClick={() => setCurrentRow(rowIndex)}
                      size="small"
                    >
                      {rowIndex === currentRow ? (
                        <HistoryIcon color="success" fontSize="small" />
                      ) : (
                        <MoreIcon fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                )}
                <TableCell>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleDeleteRow(rowIndex)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={items.length + 1}>
                <IconButton size="small" color="primary" onClick={handleAddRow}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {currentRow !== null && currentRow >= 0 && (
        <>
          <Box mt={1} mb={1}>
            <Typography>Disable items from Row{currentRow + 1}</Typography>
            {headers
              .filter(
                (item) =>
                  item.type === "input checkbox" ||
                  item.type === "label checkbox"
              )
              .map((item) => {
                const originalIndex = headers.findIndex(
                  (header) => header === item
                );
                const cRow = rows[currentRow];
                return (
                  <FormControlLabel
                    key={originalIndex}
                    control={
                      <Switch
                        checked={
                          !(
                            cRow?.disabledItems &&
                            cRow.disabledItems.includes(originalIndex)
                          )
                        }
                        onChange={() =>
                          handleEnableItem(currentRow, originalIndex)
                        }
                      />
                    }
                    label={item.header}
                  />
                );
              })}
          </Box>
        </>
      )}

      <Box sx={{ mt: 2 }}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default KeyValueForm;
