import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { getHeader, toastError } from "../../utils/util";
import axios from "axios";
import * as XLSX from "xlsx";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
const ExportExhibitorsButton = () => {
  const [loading, setLoading] = useState(false);
  const handleExport = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${API_ENDPOINT}user/a/get-all-exhibitors`,
        getHeader(ADMIN)
      );

      const filteredData = data.map((item) => {
        const myCats = (item.productCategories.value || []).reduce(
          (acc, cat) => {
            const { mainCat, subSubCat } = cat;
            if (!acc[mainCat]) {
              acc[mainCat] = [];
            }
            acc[mainCat].push(subSubCat);
            return acc;
          },
          {}
        );
        const formattedString = Object.entries(myCats)
          .map(
            ([mainCat, subSubCats]) => `${mainCat}: ${subSubCats.join(", ")}`
          )
          .join(", ");

        const aboutString = (item.about?.value || "").replace(
          /<\/?[^>]+(>|$)/g,
          ""
        );

        return {
          "Exhibitor ID": item.user_id,
          profile_image: item.profile_image,
          Username: item.username,
          Password: item.password,
          [item.companyName.label]: item.companyName.value,
          [item.about?.label || "About"]: aboutString,
          [item.contactSalutation.label]: item.contactSalutation.value,
          [item.contactFirstName.label]: item.contactFirstName.value,
          [item.contactLastName.label]: item.contactLastName.value,
          [item.contactDesignation.label]: item.contactDesignation.value,
          [item.mobile.label]: item.mobile.value,
          [item.companyEmail.label]: item.companyEmail.value,
          [item.contactEmail.label]: item.contactEmail.value,
          [item.address1.label]: item.address1.value,
          [item.address2.label]: item.address2.value,
          [item.address3.label]: item.address3.value,
          [item.country.label]: item.country?.value?.name,
          [item.state.label]: item.state?.value?.name,
          [item.city.label]: item.city?.value?.name,
          [item.pin.label]: item.pin.value,
          [item.phone.label]: item.phone.value,
          [item.fax.label]: item.fax.value,
          [item.website.label]: item.website.value,
          [item.companyShortName.label]: item.companyShortName.value,
          [item.fascia.label]: item.fascia.value,
          [item.weAre.label]: item.weAre.value,
          [item.industrySector.label]: (item.industrySector.value || [])?.join(
            ", "
          ),
          [item.exhibitorCategory.label]: item.exhibitorCategory.value,
          [item.productCategories.label]: formattedString,
          [item.hallNumber.label]: item.hallNumber.value,
          [item.standNumber.label]: item.standNumber.value,
          [item.spaceType.label]: item.spaceType.value,
          [item.exhibitArrival.label]: item.exhibitArrival.value,
          [item.exhibitArrivalTimeFrom.label]:
            item.exhibitArrivalTimeFrom.value,
          [item.exhibitArrivalTimeTo.label]: item.exhibitArrivalTimeTo.value,
          [item.materialArrival.label]: item.materialArrival.value,
          [item.materialArrivalTimeFrom.label]:
            item.materialArrivalTimeFrom.value,
          [item.materialArrivalTimeTo.label]: item.materialArrivalTimeTo.value,
          [item.dismantling.label]: item.dismantling.value,
          [item.dismantlingTimeFrom.label]: item.dismantlingTimeFrom.value,
          [item.dismantlingTimeTo.label]: item.dismantlingTimeTo.value,
          [item.standSize.label]: item.standSize.value,
        };
      });

      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Exhibitors");
      XLSX.writeFile(wb, "exhibitors.xlsx");
    } catch (error) {
      console.log(error);
      toastError(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <LoadingButton
        onClick={handleExport}
        variant="outlined"
        loading={loading}
        startIcon={<FileDownloadIcon />}
      >
        Export to Excel
      </LoadingButton>
    </>
  );
};

export default ExportExhibitorsButton;
