import { Box, Typography } from "@mui/material";
import React from "react";

const OesFormPreview = () => {
  return (
    <Box>
      <Typography fontSize={"17px"} color={"grey"}>
        No Preview
      </Typography>
    </Box>
  );
};

export default OesFormPreview;
