import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";

const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#8dd1e1"]; // Color palette for PieChart

const CategorywiseRegistrationsCard = () => {
  const { data: categorywiseData, isLoading: isCategorywiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-categorywise-registrations`);

  // Transform the data to match the format for Recharts
  const dataByCategory = useMemo(() => {
    if (!categorywiseData) return [];

    return categorywiseData.registrations.map((item) => ({
      category: item.category,
      visitors: item.count, // Change 'count' to 'visitors' for better chart display
    }));
  }, [categorywiseData]);

  // Handle loading state
  if (isCategorywiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          <Typography flex={1} variant="h6">
            Product Category wise chart
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              exportToExcel(
                dataByCategory.map(({ category, visitors }) => ({
                  category,
                  visitors,
                })),
                "Registrations_by_Category"
              )
            }
          >
            Export
          </Button>
        </Stack>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Pie
              data={dataByCategory}
              dataKey="visitors"
              nameKey="category"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {dataByCategory.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default CategorywiseRegistrationsCard;
