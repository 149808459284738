import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import { debounce } from "lodash";
import Editor from "../quill/Editor";

const templates = [
  {
    id: 1,
    name: "Welcome Email",
    subject: "Welcome to {{company}}",
    body: "Dear {{name}},\n\nThank you for joining us!",
  },
  {
    id: 2,
    name: "Reminder Email",
    subject: "Don't forget about {{event}}",
    body: "Hi {{name}},\n\nThis is a reminder for {{event}}.",
  },
];

const EmailTemplateEditor = () => {
  const quillRef = useRef();
  const [subject, setSubject] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [notification, setNotification] = useState({
    message: "",
    open: false,
  });

  useEffect(() => {
    if (selectedTemplate) {
      const template = templates.find(
        (t) => t.id === parseInt(selectedTemplate)
      );
      if (template) {
        setSubject(template.subject);
        setEmailBody(template.body);
      }
    }
  }, [selectedTemplate]);

  const handleSaveTemplate = debounce(() => {
    // Add logic to save the email template to local storage or a database
    console.log("Template saved:", { subject, fromEmail, toEmail, emailBody });
    setNotification({ message: "Template saved successfully!", open: true });
  }, 1000);

  const handleSendEmail = () => {
    if (!fromEmail || !toEmail || !subject) {
      setNotification({
        message: "Please fill all required fields!",
        open: true,
      });
      return;
    }

    const filledEmailBody = emailBody
      .replace(/{{name}}/g, "John Doe")
      .replace(/{{company}}/g, "Awesome Co.")
      .replace(/{{event}}/g, "Annual Conference");

    console.log("Email sent:", {
      subject,
      fromEmail,
      toEmail,
      emailBody: filledEmailBody,
    });
    setNotification({ message: "Email sent successfully!", open: true });
  };

  const handleTemplateDelete = () => {
    // Add logic to delete the email template
    setNotification({ message: "Template deleted!", open: true });
    setSelectedTemplate("");
    setSubject("");
    setEmailBody("");
  };

  return (
    <Paper
      elevation={3}
      style={{ padding: "20px", maxWidth: "1000px", margin: "auto" }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Email Templates
      </Typography>

      <FormControl fullWidth style={{ marginBottom: "16px" }}>
        <InputLabel>Select Template</InputLabel>
        <Select
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
          label="Select Template"
        >
          {templates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="From"
          variant="outlined"
          fullWidth
          value={fromEmail}
          onChange={(e) => setFromEmail(e.target.value)}
        />
        <TextField
          label="To"
          variant="outlined"
          fullWidth
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
        />
        <TextField
          label="Subject"
          variant="outlined"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <Typography variant="subtitle1" gutterBottom>
          Email Body (Supports dynamic fields like {`{{name}}`}, {`{{company}}`}
          , {`{{event}}`})
        </Typography>
        <Editor ref={quillRef} />

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          marginTop="20px"
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleTemplateDelete}
            disabled={!selectedTemplate}
          >
            Delete Template
          </Button>
          <Box display="flex" gap={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSaveTemplate}
            >
              Save Template
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendEmail}
            >
              Send Email
            </Button>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity="success"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default EmailTemplateEditor;
