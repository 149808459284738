import React, { useState } from "react";
import { TextField, Button, IconButton, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { INPUT_TYPES } from "../oesform/OesFormBuilderDemo";
const HeaderEditorDT = ({ headers, onHeadersChange }) => {
  const [localHeaders, setLocalHeaders] = useState(headers);

  const handleHeaderChange = (index, key, value) => {
    const updatedHeaders = localHeaders.map((header, i) =>
      i === index ? { ...header, [key]: value } : header
    );
    setLocalHeaders(updatedHeaders);
    onHeadersChange(updatedHeaders);
  };

  const handleAddHeader = () => {
    const newHeader = {
      header: "New Header",
      type: INPUT_TYPES[0],
      key: `key${localHeaders.length + 1}`,
    };
    const updatedHeaders = [...localHeaders, newHeader];
    setLocalHeaders(updatedHeaders);
    onHeadersChange(updatedHeaders);
  };

  const handleDeleteHeader = (index) => {
    const updatedHeaders = localHeaders.filter((_, i) => i !== index);
    setLocalHeaders(updatedHeaders);
    onHeadersChange(updatedHeaders);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {localHeaders.map((header, index) => (
        <div
          key={header.key}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <TextField
            fullWidth
            size="small"
            label="Header Label"
            value={header.header}
            onChange={(e) =>
              handleHeaderChange(index, "header", e.target.value)
            }
            style={{ marginRight: "10px" }}
          />
          <Select
            size="small"
            value={header.type}
            onChange={(e) => handleHeaderChange(index, "type", e.target.value)}
            style={{ marginRight: "10px" }}
          >
            {INPUT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          <IconButton size="small" onClick={() => handleDeleteHeader(index)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ))}
      <Button
        size="small"
        onClick={handleAddHeader}
        variant="contained"
        color="secondary"
      >
        Add Header
      </Button>
    </div>
  );
};

export default HeaderEditorDT;
