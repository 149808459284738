import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";

const DesignationwiseRegistrationsCard = () => {
  const { data: designationwiseData, isLoading: isDesignationwiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-designationwise-registrations`);

  // Transform the data to match the format for Recharts
  const dataByDesignation = useMemo(() => {
    if (
      !designationwiseData ||
      !Array.isArray(designationwiseData.registrations)
    )
      return [];

    // Filter out items where `item.designation` is null, undefined, or empty
    return designationwiseData.registrations
      .filter((item) => item.designation) // Keep only items with a valid designation
      .map((item) => ({
        designation: item.designation,
        count: item.count,
      }));
  }, [designationwiseData]);

  // Set a dynamic height based on the number of designations (50px height per designation)
  const chartHeight = dataByDesignation.length * 50;

  // Handle loading state
  if (isDesignationwiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          <Typography flex={1} variant="h6">
            Registrations by Designations
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              exportToExcel(
                dataByDesignation.map(({ designation, count }) => ({
                  designation,
                  count,
                })),
                "Registrations_by_Designation"
              )
            }
          >
            Export
          </Button>
        </Stack>
        <ResponsiveContainer width="100%" height={chartHeight || 250}>
          <BarChart data={dataByDesignation} layout="vertical">
            <XAxis type="number" />
            <YAxis type="category" dataKey="designation" width={150} />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#82ca9d">
              <LabelList dataKey="count" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default DesignationwiseRegistrationsCard;
