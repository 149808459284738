import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const TeamMembers = () => {
  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h5">
            Team members list will come here..
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TeamMembers;
