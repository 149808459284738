import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

const MultiSelectDropdown = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedSingleOption, setSelectedSingleOption] = useState(""); // For the single select
  const [selectedSimpleOption, setSelectedSimpleOption] = useState(""); // For the simple select

  const options = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Option 4",
    "The Battle of Waterloo was fought on Sunday 18 June 1815",
    "The Battle of Waterloo was fought on Sunday 18 June 1815 3",
    "The Battle of Waterloo was fought on Sunday 18 June 1815778",
  ];
  const handleMultiChange = (event, value) => {
    setSelectedOptions(value);
  };

  // For single-select
  const handleSingleChange = (event, value) => {
    setSelectedSingleOption(value);
  };

  // For simple select
  const handleSimpleSelectChange = (event) => {
    setSelectedSimpleOption(event.target.value);
  };

  return (
    <Box m={2}>
      {/* Multi-Select Dropdown */}
      <Autocomplete
        size="small"
        multiple
        id="multi-select-dropdown"
        options={options}
        value={selectedOptions}
        onChange={handleMultiChange}
        renderInput={(params) => (
          <TextField {...params} label="Select Multiple Options" />
        )}
      />

      {/* Single-Select Dropdown */}
      <Autocomplete
        sx={{ mt: 2 }}
        size="small"
        id="single-select-dropdown"
        options={options}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField {...params} label="Select One Option" variant="outlined" />
        )}
      />
      <FormControl fullWidth size="small" margin="normal">
        <InputLabel id="simple-select-label">Select an Option</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={selectedSimpleOption}
          onChange={handleSimpleSelectChange}
          label="Select an Option"
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MultiSelectDropdown;
