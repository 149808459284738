import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import MyDatePicker from "./DatePicker";
import dayjs from "dayjs";

const MyDateRange = ({
  labelFrom = "Date From",
  labelTo = "Date to",
  min,
  max,
  dateToVal,
  dateFromVal,
  onChangeDateToVal,
  onChangeDateFromVal,
}) => {
  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);

  const handleDateToVal = (newVal) => {
    if (onChangeDateToVal) {
      onChangeDateToVal(newVal);
    } else {
      setDateTo(newVal);
    }
  };
  const handleDateFromVal = (newVal) => {
    if (onChangeDateFromVal) {
      onChangeDateFromVal(newVal);
    } else {
      setDateFrom(newVal);
    }
  };
  console.log(dateToVal);

  return (
    <Stack direction={"column"} gap={1}>
      <MyDatePicker
        min={min ? dayjs(min) : undefined}
        max={max ? dayjs(max) : undefined}
        value={dateToVal ? dateToVal : dateTo}
        onChange={handleDateToVal}
        label={labelFrom}
      />
      <MyDatePicker
        min={min ? dayjs(min) : undefined}
        max={max ? dayjs(max) : undefined}
        value={dateFromVal ? dateFromVal : dateFrom}
        onChange={handleDateFromVal}
        label={labelTo}
      />
    </Stack>
  );
};

export default MyDateRange;
