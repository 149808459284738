import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Card,
  Button,
} from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Circle,
  Group,
  Image as KonvaImage,
  Layer,
  Line,
  Rect,
  Stage,
  Transformer,
} from "react-konva";
import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";
import UploadHall from "./UploadHall";
import { getHeader, hexToRGBA, useQuery } from "../../utils/util";
import { ADMIN, API_ENDPOINT, TRANSPARENCY } from "../../utils/constants";
import axios from "axios";
import { toastError } from "../../utils/util";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import BackBtn from "../../widgets/BackBtn";
import CloseButton from "../../widgets/CloseButton";
import { useNavigate } from "react-router-dom";
import { getImageDimen } from "./GeneralFloorPlan";
export const CONTAINER_WIDTH = 1280;
export const CONTAINER_HEIGHT = 560;
export const CONTAINER_AR = 1280 / 600;
const AddHall = () => {
  const query = useQuery();
  const id = query.get("id");
  const [hallId, setHallId] = useState();
  const navigate = useNavigate();
  const [reloadFp, setReloadFp] = useState(false);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-floor?id=1&r=${reloadFp}`,
    0
  );

  const RECTANGLE = "Rectangle";
  const POLYGON = "Polygon";
  const ADD_HALL = "add_hall";
  const SAVE_HALL = "save_hall";
  const [currentView, setCurrentView] = useState(ADD_HALL);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [currentHall, setCurrentHall] = useState();
  const [confirmationV, setConfirmationV] = useState("");
  const [image, setImage] = useState(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });

  const [points, setPoints] = useState([]);
  const [dots, setDots] = useState([]);
  const [rectangle, setRectangle] = useState(null);
  const [polygonClosed, setPolygonClosed] = useState(false);
  const [selectedMode, setSelectedMode] = useState("");
  const [drawing, setDrawing] = useState(false);

  const [resizing, setResizing] = useState(false);
  const transformerRef = useRef(null);
  const stageRef = useRef(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [strokeColor, setStrokeColor] = useState("#1876d1");

  const increaseStrokeWidth = () => {
    setStrokeWidth((prevCount) => prevCount + 1);
  };

  const decreaseStrokeWidth = () => {
    if (strokeWidth > 0) {
      setStrokeWidth((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    if (data && !image) {
      const img = new window.Image();
      img.src = data.image;
      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [data]);
  useEffect(() => {
    if (id) {
      setHallId(id);
    }
  }, [id]);
  useEffect(() => {
    if (hallId) {
      const hallToEdit = data.halls.find((hall) => hall._id === hallId);
      if (hallToEdit) {
        setCurrentHall(hallToEdit);
        if (hallToEdit.shape.type === POLYGON) {
          const hallPoints = [...hallToEdit.shape.shape];
          setPoints(hallPoints);
          const formattedDots = [];
          for (let i = 0; i < hallPoints.length; i += 2) {
            formattedDots.push({ x: hallPoints[i], y: hallPoints[i + 1] });
          }
          setStrokeWidth(hallToEdit.strokeWidth);
          setStrokeColor(hallToEdit.color);
          setDots(formattedDots);
          setPolygonClosed(true);
          setSelectedMode(POLYGON);
        } else if (hallToEdit.shape.type === RECTANGLE) {
          setRectangle({ ...hallToEdit.shape.shape });
          setStrokeWidth(hallToEdit.strokeWidth);
          setStrokeColor(hallToEdit.color);
          setSelectedMode(RECTANGLE);
        }
      }
    }
  }, [hallId]);

  const getRelativePointerPosition = (node) => {
    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();
    return transform.point(pos);
  };
  const handleStageClick = (e) => {
    if (polygonClosed || selectedMode !== POLYGON) return;
    if (strokeWidth < 1) {
      toast.info("Please add stroke width");
      return;
    }
    const stage = e.target.getStage();
    const mousePos = getRelativePointerPosition(stage);
    const newPoints = [...points, mousePos.x, mousePos.y];
    setPoints(newPoints);
    setDots([...dots, { x: mousePos.x, y: mousePos.y }]);
    if (newPoints.length >= 6) {
      const dx = newPoints[0] - mousePos.x;
      const dy = newPoints[1] - mousePos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < 10) {
        const adjustedPoints = [...newPoints];
        adjustedPoints[adjustedPoints.length - 2] = adjustedPoints[0];
        adjustedPoints[adjustedPoints.length - 1] = adjustedPoints[1];
        setPoints(adjustedPoints);
        setDots([...dots, { x: adjustedPoints[0], y: adjustedPoints[1] }]);
        setPolygonClosed(true);
      }
    }
  };

  const handleDragMovePolygon = (index, event) => {
    const newDots = dots.slice(); // Create a copy of the dots array
    newDots[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };

    // Update the points array for the polygon
    const newPoints = newDots.reduce(
      (acc, dot) => acc.concat([dot.x, dot.y]),
      []
    );
    setPoints(newPoints);
    setDots(newDots);
  };

  const handlePolygonDragEnd = (e) => {
    const groupNode = e.target;
    const dx = groupNode.x();
    const dy = groupNode.y();
    // Adjust the points based on the group's drag position
    const newPoints = points.map((point, index) =>
      index % 2 === 0 ? point + dx : point + dy
    );
    // Update dots based on the new points
    const newDots = [];
    for (let i = 0; i < newPoints.length; i += 2) {
      newDots.push({ x: newPoints[i], y: newPoints[i + 1] });
    }
    // Update state
    setPoints(newPoints);
    setDots(newDots);
    // Reset group position (so it won't move further)
    groupNode.x(0);
    groupNode.y(0);
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.08;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    const minScale = 0.5;
    const maxScale = 3;
    const finalScale = Math.max(minScale, Math.min(maxScale, newScale));

    setStageScale(finalScale);
    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newPos = {
      x: pointer.x - mousePointTo.x * finalScale,
      y: pointer.y - mousePointTo.y * finalScale,
    };

    setStagePos(newPos);
  };

  const startDrawing = (e) => {
    if (rectangle || selectedMode !== RECTANGLE) return;
    setDrawing(true);
    const pos = getRelativePointerPosition(stageRef.current);

    setRectangle({
      x: pos.x,
      y: pos.y,
      width: 0,
      height: 0,
    });
  };

  const updateDrawing = (e) => {
    if (!drawing || !rectangle) return;
    const pos = getRelativePointerPosition(stageRef.current);

    const newRect = {
      ...rectangle,
      width: pos.x - rectangle.x,
      height: pos.y - rectangle.y,
    };

    setRectangle(newRect);
  };

  const endDrawing = () => {
    setDrawing(false);
  };

  const handleDragMove = (e) => {
    if (resizing) {
      const node = e.target;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      setRectangle({
        x: node.x(),
        y: node.y(),
        width: Math.max(50, node.width() * scaleX),
        height: Math.max(50, node.height() * scaleY),
      });
      node.scaleX(1);
      node.scaleY(1);
    }
  };

  const handleModeChange = (event) => {
    let cm = event.target.value;
    if (selectedMode !== "") {
      setOpenConfirmation(true);
      setConfirmationV(cm);
    } else {
      setSelectedMode(cm);
    }
  };

  const chooseColor = () => {
    setShowColorPicker(true);
  };

  const onColorChange = (color, event) => {
    setStrokeColor(color.hex);
  };

  const onConfirmSwitchTool = () => {
    setOpenConfirmation(false);
    setSelectedMode(confirmationV);
    resetDrawing();
  };
  function resetDrawing() {
    setHallId(null);
    setCurrentHall(null);
    setSelectedMode("");
    setRectangle(null);
    setPoints([]);
    setDots([]);
  }

  const onSave = async () => {
    if (
      selectedMode === "" ||
      (!(points.length >= 6 && polygonClosed) && !rectangle)
    ) {
      toast.error(`Please draw the shape first`);
    } else {
      if (hallId && currentHall) {
        const transformedRect = transformerRef?.current?.nodes()[0];
        if (selectedMode === RECTANGLE && !transformedRect) {
          toast.error("Please draw the rectangle first");
          return;
        }

        const shape = {
          type: selectedMode,
          shape:
            selectedMode === RECTANGLE
              ? {
                  x: transformedRect.x(),
                  y: transformedRect.y(),
                  width: transformedRect.width() * transformedRect.scaleX(),
                  height: transformedRect.height() * transformedRect.scaleY(),
                  rotation: transformedRect.rotation(),
                }
              : points,
        };
        const dataToEdit = {
          color: strokeColor,
          strokeWidth: strokeWidth,
          shape: shape,
        };
        try {
          const { data } = await axios.post(
            `${API_ENDPOINT}admin/update-hall?id=${currentHall._id}`,
            dataToEdit,
            getHeader(ADMIN)
          );
          toast.success(data.message);
          setReloadFp((prev) => !prev);
        } catch (error) {
          toastError(error);
        } finally {
          resetDrawing();
        }
      } else {
        const transformedRect = transformerRef?.current?.nodes()[0];
        if (transformedRect && selectedMode === RECTANGLE) {
          setRectangle({
            x: transformedRect.x(),
            y: transformedRect.y(),
            width: transformedRect.width() * transformedRect.scaleX(),
            height: transformedRect.height() * transformedRect.scaleY(),
            rotation: transformedRect.rotation(),
          });
          transformedRect.scaleX(1);
          transformedRect.scaleY(1);
          transformedRect.rotation(0);
        }
        setCurrentView(SAVE_HALL);
      }
    }
  };

  const onBack = () => {
    setCurrentView(ADD_HALL);
  };

  return (
    <Box maxWidth={1280} m="8px auto">
      {currentView === ADD_HALL ? (
        <Box>
          <ConfirmationModal
            open={openConfirmation}
            handleClose={() => setOpenConfirmation(false)}
            onCancel={() => setOpenConfirmation(false)}
            onConfirm={onConfirmSwitchTool}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flex={1} alignItems="center" gap={2}>
              <Button
                sx={{ textTransform: "none" }}
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate("/home/floor-plan-creator", { replace: true });
                }}
                startIcon={<ArrowBackIcon fontSize="small" />}
              >
                General Plan
              </Button>
              <Typography variant="h6">
                {hallId && currentHall
                  ? `Edit ${currentHall.name}`
                  : "Add Hall"}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center" flex={1} gap={3}>
              <Paper
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: "#E8F4F9",
                }}
              >
                <Stack direction="column" alignItems="center">
                  <Typography variant="body2" align="center">
                    Color
                  </Typography>
                  <IconButton
                    onClick={chooseColor}
                    sx={{ color: strokeColor }}
                    size="small"
                  >
                    <CircleIcon />
                  </IconButton>
                  {showColorPicker && (
                    <Draggable>
                      <div style={{ position: "absolute", zIndex: 100 }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <CloseButton
                            myStyle={{ bottom: "-12px", left: "12px" }}
                            onClose={() => setShowColorPicker(false)}
                          />
                        </div>
                        <SketchPicker
                          color={strokeColor}
                          onChange={onColorChange}
                        />
                      </div>
                    </Draggable>
                  )}
                </Stack>
              </Paper>

              <Paper
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: "#E8F4F9",
                }}
              >
                <Typography variant="body2" align="center">
                  Stroke Width
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton
                    onClick={decreaseStrokeWidth}
                    color="primary"
                    size="small"
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="subtitle1">{strokeWidth}</Typography>
                  <IconButton
                    onClick={increaseStrokeWidth}
                    color="primary"
                    size="small"
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Paper>

              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: "#E8F4F9",
                }}
              >
                <RadioGroup
                  row
                  value={selectedMode}
                  onChange={handleModeChange}
                >
                  <FormControlLabel
                    value={RECTANGLE}
                    control={<Radio />}
                    label={RECTANGLE}
                  />
                  <FormControlLabel
                    value={POLYGON}
                    control={<Radio />}
                    label={POLYGON}
                  />
                </RadioGroup>
              </Paper>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                onClick={onSave}
                size="small"
                variant="contained"
                startIcon={<SaveAsIcon />}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              mt: 1,
              mx: "auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {image && (
              <Stage
                width={1280}
                height={560}
                scaleX={stageScale}
                scaleY={stageScale}
                x={stagePos.x}
                y={stagePos.y}
                ref={stageRef}
                onWheel={handleWheel}
                draggable={!drawing}
                onMouseDown={startDrawing}
                onMouseMove={updateDrawing}
                onMouseUp={endDrawing}
                onClick={handleStageClick}
              >
                <Layer>
                  <KonvaImage
                    image={image.img}
                    width={getImageDimen(image.width, image.height).width}
                    height={getImageDimen(image.width, image.height).height}
                    x={0}
                    y={0}
                  />

                  {data?.halls
                    ?.filter(
                      (hall) =>
                        hall.shape.type === "Polygon" && hall._id !== hallId
                    )
                    .map((hall, index) => (
                      <Line
                        key={index}
                        points={hall.shape.shape}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        closed
                        onClick={() => {
                          setHallId(hall._id);
                        }}
                      />
                    ))}

                  {data?.halls
                    ?.filter(
                      (hall) =>
                        hall.shape.type === "Rectangle" && hall._id !== hallId
                    )
                    .map((hall, index) => (
                      <Rect
                        key={index}
                        x={hall.shape.shape.x}
                        y={hall.shape.shape.y}
                        width={hall.shape.shape.width}
                        height={hall.shape.shape.height}
                        rotation={hall.shape.shape.rotation || 0}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        onClick={() => {
                          setHallId(hall._id);
                        }}
                      />
                    ))}

                  {rectangle && selectedMode === RECTANGLE && (
                    <>
                      <Rect
                        x={rectangle.x}
                        y={rectangle.y}
                        rotation={rectangle.rotation || 0}
                        width={rectangle.width}
                        height={rectangle.height}
                        fill={hexToRGBA(strokeColor, TRANSPARENCY)}
                        draggable
                        onDragStart={() => setResizing(false)}
                        onDragEnd={handleDragMove}
                        onClick={(e) => {
                          e.cancelBubble = true;
                          transformerRef.current.nodes([e.target]);
                          transformerRef.current.getLayer().batchDraw();
                          setResizing(true);
                        }}
                      />
                      <Transformer
                        ref={transformerRef}
                        rotateEnabled={true}
                        boundBoxFunc={(oldBox, newBox) => {
                          if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                          }
                          return newBox;
                        }}
                      />
                    </>
                  )}

                  {selectedMode === POLYGON && (
                    <Group draggable onDragEnd={handlePolygonDragEnd}>
                      <Line
                        points={points}
                        fill={hexToRGBA(strokeColor, TRANSPARENCY)}
                        stroke={hexToRGBA(strokeColor, TRANSPARENCY)}
                        strokeWidth={strokeWidth}
                        closed={polygonClosed}
                      />
                      {dots.map((dot, index) => (
                        <Circle
                          key={index}
                          x={dot.x}
                          y={dot.y}
                          radius={5}
                          fill="red"
                          draggable
                          onDragStart={(e) => {
                            e.cancelBubble = true;
                          }}
                          onDragMove={(e) => {
                            e.cancelBubble = true;
                            handleDragMovePolygon(index, e);
                          }}
                          onDragEnd={(e) => {
                            e.cancelBubble = true;
                          }}
                        />
                      ))}
                    </Group>
                  )}
                </Layer>
              </Stage>
            )}
          </Card>
        </Box>
      ) : (
        <UploadHall
          color={strokeColor}
          strokeWidth={strokeWidth}
          onBack={onBack}
          points={points}
          rectangle={rectangle}
          selectedMode={selectedMode}
        />
      )}
    </Box>
  );
};

export default AddHall;
