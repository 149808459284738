import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

export function CitySelector({
  countryCode,
  stateCode,
  selectedCity,
  onChange,
}) {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!countryCode || !stateCode) {
      setCities([]);
      return;
    }

    const fetchCities = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://apis.expoplanner.in/visitor/get-cities/${countryCode}/${stateCode}`
        );
        const data = await response.json();
        const cityData = data.map((city) => ({
          name: city.name,
        }));
        setCities(cityData);
      } catch (error) {
        console.error("Error fetching cities:", error);
        setCities([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCities();
  }, [countryCode, stateCode]);

  const handleChange = (event, value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Autocomplete
      size="small"
      options={cities}
      value={selectedCity || null}
      onChange={handleChange}
      getOptionLabel={(option) => (option ? option.name : "")}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      loading={loading}
      disabled={!countryCode || !stateCode}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select City"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default CitySelector;
