// Import necessary modules
import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { getErrorResponse, getMultipartHeader } from "../../utils/util";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";

const PdfUploadWidget = ({ onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSnackbarMessage("Please select a PDF file.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleUploadClick = async () => {
    if (selectedFile) {
      setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const response = await axios.post(
          `${API_ENDPOINT}user/a/upload-pdf`,
          formData,
          getMultipartHeader(ADMIN)
        );

        const fileUrl = response.data.fileUrl;
        setUploadUrl(fileUrl);
        onFileUpload(fileUrl);
        setSnackbarMessage("File uploaded successfully");
        setSnackbarSeverity("success");
      } catch (error) {
        console.error("Error uploading file:", error);
        setSnackbarMessage(getErrorResponse(error));
        setSnackbarSeverity("error");
      } finally {
        setIsUploading(false);
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage(
        "No file selected. Please select a PDF file to upload."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={3}
      border="2px dashed #1976d2"
      borderRadius={2}
      bgcolor="#f5f5f5"
      boxShadow={3}
    >
      <input
        accept=".pdf"
        style={{ display: "none" }}
        id="pdf-upload"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="pdf-upload">
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
          sx={{ mb: 2 }}
        >
          Choose PDF
        </Button>
      </label>
      {selectedFile && (
        <Typography variant="body2" mt={1} mb={2}>
          Selected File: {selectedFile.name}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleUploadClick}
        disabled={isUploading}
        sx={{ mb: 2 }}
      >
        {isUploading ? <CircularProgress size={24} /> : "Upload"}
      </Button>
      {uploadUrl && (
        <Typography variant="body2" mt={2} color="green">
          File uploaded successfully:{" "}
          <a href={uploadUrl} target="_blank" rel="noopener noreferrer">
            View PDF
          </a>
        </Typography>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PdfUploadWidget;
