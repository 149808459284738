import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";

function ConfirmationModal({ open, handleClose, onCancel, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <WarningIcon style={{ color: "orange", marginRight: "8px" }} />
        Are you sure you want to change the drawing tool?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you change the way you mark the hall, the already drawn area is
          removed and you can draw another.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
