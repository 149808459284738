import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import {
  SaveAlt,
  People,
  Business,
  Assignment,
  Category,
  Group,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import BackBtn from "../../widgets/BackBtn";

const dataSamples = {
  visitorList: [
    { name: "John Doe", company: "XYZ Ltd.", email: "john@example.com" },
    { name: "Jane Doe", company: "ABC Corp.", email: "jane@example.com" },
  ],
  exhibitorList: [
    {
      exhibitorName: "Expo Booths Ltd.",
      location: "Hall A",
      contact: "expo@example.com",
    },
  ],
  oesFormsData: [
    { formId: "12345", description: "OES Form 1", status: "Pending" },
    { formId: "67890", description: "OES Form 2", status: "Approved" },
  ],
  productList: [
    { productName: "Product 1", category: "Machinery", price: "1000 USD" },
    { productName: "Product 2", category: "Automation", price: "5000 USD" },
  ],
  teamMembersList: [
    { name: "Alice", role: "Developer", email: "alice@example.com" },
    { name: "Bob", role: "Manager", email: "bob@example.com" },
  ],
};

const ExportDataPage = () => {
  // Function to handle Excel export
  const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const exportItems = [
    {
      data: dataSamples.visitorList,
      fileName: "Visitor_List",
      title: "Visitor List",
      description: "Export the list of all visitors in Excel format.",
      icon: <People fontSize="large" color="primary" />,
      color: "primary",
    },
    {
      data: dataSamples.exhibitorList,
      fileName: "Exhibitor_List",
      title: "Exhibitor List",
      description: "Export the list of all exhibitors in Excel format.",
      icon: <Business fontSize="large" color="secondary" />,
      color: "secondary",
    },
    {
      data: dataSamples.oesFormsData,
      fileName: "OES_Forms_Data",
      title: "OES Forms Data",
      description: "Export OES forms data in Excel format.",
      icon: <Assignment fontSize="large" color="success" />,
      color: "success",
    },
    {
      data: dataSamples.productList,
      fileName: "Product_List",
      title: "Product List",
      description: "Export the list of products in Excel format.",
      icon: <Category fontSize="large" color="warning" />,
      color: "warning",
    },
    {
      data: dataSamples.teamMembersList,
      fileName: "Team_Members_List",
      title: "Team Members List",
      description: "Export the list of team members in Excel format.",
      icon: <Group fontSize="large" color="info" />,
      color: "info",
    },
  ];

  return (
    <Box>
      <BackBtn />
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Export Data to Excel
        </Typography>
        <Grid container spacing={3}>
          {exportItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item.icon}
                    <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="contained"
                    color={item.color}
                    startIcon={<SaveAlt />}
                    onClick={() => exportToExcel(item.data, item.fileName)}
                  >
                    Export
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ExportDataPage;
