import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  CircularProgress,
  Alert,
  IconButton,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../utils/constants";
import { Link } from "react-router-dom";
import BackBtn from "../widgets/BackBtn";

const SuperPreview = () => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}super/get-events`
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  // Handlers for options menu
  const handleMenuClick = (event, eventId) => {
    setSelectedEvent(eventId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Loading state
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Error state
  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Alert severity="error">{error.message}</Alert>
      </Box>
    );
  }

  // Render the list of events
  return (
    <Box p={3}>
      <BackBtn />
      <Container>
        {/* Information Alert */}
        <Alert severity="warning" sx={{ mb: 4 }}>
          Please ensure all necessary resources are deployed before approving
          events.
        </Alert>

        {/* Event List Title */}
        <Typography variant="h4" gutterBottom>
          Event List
        </Typography>

        {/* Event Grid */}
        <Grid container spacing={3}>
          {data &&
            data.map((event) => (
              <Grid item xs={12} sm={6} md={4} key={event._id}>
                <Card sx={{ position: "relative" }}>
                  {/* Event Banner */}
                  {event.eventBannerUrl && (
                    <CardMedia
                      component="img"
                      height="200"
                      image={event.eventBannerUrl}
                      alt={`${event.eventName} Banner`}
                      sx={{ borderRadius: "4px 4px 0 0" }}
                    />
                  )}

                  {/* Event Content */}
                  <CardContent>
                    {/* Event Name */}
                    <Typography variant="h5" gutterBottom>
                      {event.eventName}
                    </Typography>

                    {/* Event Dates */}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      <strong>Event Dates:</strong>{" "}
                      {new Date(event.eventDateFrom).toLocaleDateString()} -{" "}
                      {new Date(event.eventDateTo).toLocaleDateString()}
                    </Typography>

                    {/* Event Location */}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      <strong>Location:</strong> {event.city}, {event.country}
                    </Typography>

                    {/* View Detail Link */}
                    <Typography variant="body2" color="primary" gutterBottom>
                      <Link
                        to={`/home/super-event-preview/${event._id}`}
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        View Detail
                      </Link>
                    </Typography>

                    {/* Options Button */}
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        onClick={(e) => handleMenuClick(e, event._id)}
                        aria-label="options"
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={menuOpen && selectedEvent === event._id}
                        onClose={handleMenuClose}
                        PaperProps={{
                          elevation: 1,
                          sx: {
                            width: 200,
                          },
                        }}
                      >
                        <MenuItem onClick={handleMenuClose}>Approve</MenuItem>
                        <MenuItem onClick={handleMenuClose}>Reject</MenuItem>
                        <MenuItem
                          component={Link}
                          to={`/home/super-event-preview/${event._id}`}
                        >
                          View Detail
                        </MenuItem>
                      </Menu>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default SuperPreview;
