import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
  Divider,
  Autocomplete,
  FormLabel,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import ImageIcon from "@mui/icons-material/Image";
import CameraRollIcon from "@mui/icons-material/CameraRoll";
import ReactQuill from "react-quill";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TableRowsIcon from "@mui/icons-material/TableRows";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { MuiTelInput } from "mui-tel-input";
import MySlider from "../MySlider";
import RegistrationContainer from "./RegistrationContainer";
import {
  CHECKBOX,
  DATEPICKER,
  DESCRIPTION,
  MULTICHECKBOX,
  MULTISELECT,
  PHONE,
  RADIO_SELECT,
  SELECT,
  TEXT,
  TIMEPICKER,
} from "./constants";
import SelectDropDown from "./SelectDropDown";
import LogoutButton from "../widgets/LogoutButton";
import BackBtn from "../widgets/BackBtn";
const paletteElements = [
  {
    id: "1",
    type: TEXT,
    label: "Text Field",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TextFieldsIcon />,
  },
  {
    id: "2",
    type: SELECT,
    label: "Select Dropdown",
    settings: {
      required: false,
      isAutoSelect: false,
      options: ["Option 1", "Option 2"],
      grid: 12,
    },
    icon: <ArrowDropDownCircleIcon />,
  },
  {
    id: "3",
    type: MULTISELECT,
    label: "Multiselect Dropdown",
    settings: { required: false, options: ["Option 1", "Option 2"], grid: 12 },
    icon: <ArrowDropDownCircleIcon />,
  },
  {
    id: "4",
    type: CHECKBOX,
    label: "Checkbox",
    settings: { required: false, grid: 12 },
    icon: <CheckBoxIcon />,
  },
  {
    id: "8",
    type: PHONE,
    label: "Phone",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <PhoneEnabledIcon />,
  },
  {
    id: "11",
    type: RADIO_SELECT,
    label: "Radio Button",
    settings: { required: false, options: ["Option 1", "Option 2"], grid: 12 },
    icon: <RadioButtonCheckedIcon />,
  },
  {
    id: "9",
    type: DATEPICKER,
    label: "Date Picker",
    settings: { required: false, grid: 12 },
    icon: <EventIcon />,
  },
  {
    id: "10",
    type: TIMEPICKER,
    label: "Time Picker",
    settings: { required: false, grid: 12 },
    icon: <AccessTimeIcon />,
  },
];
const widgetElements = [
  {
    id: "w1",
    type: "image",
    label: "Image",
    settings: {
      placeholder: "",
      required: false,
      grid: 12,
      width: 300,
      height: 200,
    },
    icon: <ImageIcon />,
  },
  {
    id: "w2",
    type: "image_slider",
    label: "Image Slider",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <CameraRollIcon />,
  },
  {
    id: "w3",
    type: "static_table",
    label: "Static Table",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TableRowsIcon />,
  },
  {
    id: "w4",
    type: "static_table",
    label: "Dynamic Table",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <TableRowsIcon />,
  },
  {
    id: "w5",
    type: "date_range",
    label: "Date Range",
    settings: { placeholder: "", required: false, grid: 12 },
    icon: <DateRangeIcon />,
  },
];

const FormBuilder = () => {
  const [formElements, setFormElements] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isSection, setIsSection] = useState(false);
  const [currentEditElement, setCurrentEditElement] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [myTab, setMyTab] = useState(0);
  const [value, setValue] = useState("");

  const handleTabChange = (event, newValue) => {
    setMyTab(newValue);
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const finalA = paletteElements.concat(widgetElements);
    if (
      result.source.droppableId !== "form-elements" &&
      result.destination.droppableId === "form-elements"
    ) {
      const element = finalA.find((el) => el.id === result.draggableId);
      if (element) {
        const items = Array.from(formElements);
        let elm = { ...element, id: `${element.id}-${Date.now()}` };
        items.splice(result.destination.index, 0, elm);
        setFormElements(items);
      }
    }

    if (
      result.source.droppableId === "form-elements" &&
      result.destination.droppableId === "form-elements"
    ) {
      const items = Array.from(formElements);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setFormElements(items);
    }
  };

  const handleEditClick = (element) => {
    setCurrentEditElement(element);
    setEditDialogOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, [name]: value },
    });
  };

  const handleEditSave = () => {
    setFormElements((prev) =>
      prev.map((el) =>
        el.id === currentEditElement.id ? currentEditElement : el
      )
    );
    setEditDialogOpen(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    console.log(name, checked);
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, [name]: checked },
    });
  };

  const handleOptionsChange = (e) => {
    const { value } = e.target;
    setCurrentEditElement({
      ...currentEditElement,
      settings: { ...currentEditElement.settings, options: value.split("\n") },
    });
  };
  const handleDelete = () => {
    const items = Array.from(formElements);
    const item = items.find((item) => item.id === currentEditElement.id);
    const index = items.indexOf(item);
    if (index !== -1) {
      items.splice(index, 1);
    }
    setFormElements(items);
    setEditDialogOpen(false);
  };
  return (
    <Box m={"auto"} maxWidth={1200}>
      <Typography p={2} variant="h5" textAlign={"center"} gutterBottom>
        Registration Form Builder
      </Typography>
      <BackBtn bstyle={{ position: "fixed", left: 2, top: 2 }} />
      <LogoutButton bstyle={{ position: "fixed", top: 2, right: 2 }} />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box p={1} borderRadius={1} border={"1px solid #ddd"}>
              <Typography textAlign={"center"} fontSize={17}>
                Form Elements
              </Typography>
              <Box height={"76vh"} overflow={"auto"}>
                <Box mt={1}>
                  <Droppable
                    droppableId="palette-elements"
                    isDropDisabled={true}
                  >
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        {paletteElements.map((element, index) => (
                          <Draggable
                            key={element.id}
                            draggableId={element.id}
                            index={index}
                          >
                            {(provided) => (
                              <Paper
                                sx={{ p: 1, m: 1 }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Stack direction={"row"} gap={1}>
                                  {element.icon}
                                  <Typography fontSize={15}>
                                    {element.label}
                                  </Typography>
                                </Stack>
                              </Paper>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              height={"86vh"}
              display={"flex"}
              flexDirection={"column"}
              bgcolor={"#f4f2f3"}
              borderRadius={2}
              p={2}
            >
              <Tabs
                sx={{ mb: 1 }}
                value={selectedTab}
                onChange={handleChange}
                centered
              >
                <Tab label="Edit/Update" />
                <Tab label="Preview" />
              </Tabs>
              <Box flex={1} overflow={"auto"}>
                {selectedTab === 0 ? (
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSection}
                          onChange={() => setIsSection((prev) => !prev)}
                          size="small"
                        />
                      }
                      label="is this group a section?"
                    />
                    {isSection && (
                      <Box mt={2} mb={2}>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          label="Enter section title"
                        />
                      </Box>
                    )}
                    <Droppable droppableId="form-elements">
                      {(provided) => (
                        <Grid
                          container
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            border: "1px solid grey",
                            borderRadius: 1,
                          }}
                        >
                          {formElements.length === 0 && (
                            <Typography
                              m={2}
                              variant="body1"
                              color="textSecondary"
                            >
                              Drag form fields here to build your form
                            </Typography>
                          )}
                          {formElements.map((element, index) => (
                            <Draggable
                              key={element.id}
                              draggableId={element.id}
                              index={index}
                            >
                              {(provided) => (
                                <Grid item xs={element.settings.grid}>
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    m={1}
                                    p={1}
                                    sx={{
                                      bgcolor: "#f9f9f9",
                                      borderRadius: 1,
                                      border: "1px solid #d4cfcf",
                                      position: "relative",
                                    }}
                                  >
                                    {element.type === CHECKBOX ? (
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        label={element.label}
                                      />
                                    ) : element.type === RADIO_SELECT ? (
                                      <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                          {element.label}
                                        </FormLabel>
                                        <RadioGroup
                                          aria-label="gender"
                                          name="gender"
                                          row
                                        >
                                          {element.settings.options.map(
                                            (option) => (
                                              <FormControlLabel
                                                key={option}
                                                value={option}
                                                control={
                                                  <Radio
                                                    inputProps={{
                                                      "aria-label": option,
                                                    }}
                                                  />
                                                }
                                                label={option}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    ) : element.type === PHONE ? (
                                      <MuiTelInput
                                        fullWidth
                                        size="small"
                                        label={element.label}
                                        defaultCountry="IN"
                                        forceCallingCode
                                      />
                                    ) : element.type === SELECT ? (
                                      <SelectDropDown formEl={element} />
                                    ) : element.type === MULTISELECT ? (
                                      <Autocomplete
                                        size="small"
                                        multiple
                                        id="multi-select-dropdown"
                                        options={element.settings.options}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={element.label}
                                          />
                                        )}
                                      />
                                    ) : element.type === DESCRIPTION ? (
                                      <ReactQuill
                                        theme="snow"
                                        value={value}
                                        onChange={setValue}
                                      />
                                    ) : element.type === TEXT ? (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={element.label}
                                        type={element.type}
                                        placeholder={
                                          element.settings.placeholder
                                        }
                                      />
                                    ) : element.type === DATEPICKER ? (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={element.label}
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true, // This ensures the label doesn't overlap with the date input
                                        }}
                                      />
                                    ) : element.type === TIMEPICKER ? (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={element.label}
                                        type="time"
                                        InputLabelProps={{
                                          shrink: true, // This ensures the label doesn't overlap with the date input
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <IconButton
                                      size="small"
                                      onClick={() => handleEditClick(element)}
                                      sx={{
                                        position: "absolute",
                                        top: 1,
                                        right: 1,
                                        bgcolor: "#e2e0e1", // Background color for the default state
                                        "&:hover": {
                                          bgcolor: "darkgrey", // Background color for the hover state
                                        },
                                      }}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Grid>
                      )}
                    </Droppable>

                    <Stack mt={2} direction={"row"}>
                      <Box flex={1}></Box>
                      <Button variant="contained" size="small">
                        Save
                      </Button>
                    </Stack>
                  </Box>
                ) : (
                  <RegistrationContainer
                    onAddElements={() => setSelectedTab(0)}
                    height="100%"
                    mw="100%"
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DragDropContext>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Customize Field</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            margin="dense"
            label="Label"
            name="label"
            value={currentEditElement?.label || ""}
            onChange={(e) =>
              setCurrentEditElement({
                ...currentEditElement,
                label: e.target.value,
              })
            }
          />

          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                size="small"
                checked={currentEditElement?.settings.required || false}
                onChange={handleCheckboxChange}
                name="required"
              />
            }
            label="is Required?"
          />
          {currentEditElement?.type === SELECT && (
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  size="small"
                  checked={currentEditElement?.settings.isAutoSelect || false}
                  onChange={handleCheckboxChange}
                  name="isAutoSelect"
                />
              }
              label="is Auto Select(in auto select you can search and select)?"
            />
          )}

          {currentEditElement?.type === SELECT ||
          currentEditElement?.type === MULTISELECT ||
          currentEditElement?.type === RADIO_SELECT ? (
            <TextField
              sx={{ mt: 2 }}
              multiline
              size="small"
              fullWidth
              rows={4}
              margin="dense"
              label="Options (new line separated)"
              value={currentEditElement?.settings.options.join("\n") || ""}
              onChange={handleOptionsChange}
            />
          ) : null}
          <TextField
            sx={{ mt: 2 }}
            size="small"
            fullWidth
            label="Grid Columns (1-12)"
            name="grid"
            type="number"
            value={currentEditElement?.settings.grid || 12}
            onChange={handleEditChange}
            inputProps={{ min: 1, max: 12 }}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setEditDialogOpen(false)}>
            Cancel
          </Button>
          <Button size="small" color="error" onClick={handleDelete}>
            Delete
          </Button>
          <Button size="small" onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FormBuilder;
