import { Box, TextField, Typography, Button, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import axios from "axios";
import { getHeader, toastError } from "../../utils/util";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

const AddFormItem = ({ onAdded, show, mode = "add", editItem }) => {
  // State management for form fields
  const [name, setName] = useState("");
  const [serial, setSerial] = useState(0);
  const [expiryDate, setExpiryDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === "edit") {
      setName(editItem.name);
      setSerial(editItem.serial || 0);
      setExpiryDate(dayjs(editItem.expiry_date));
    } else {
      setName("");
      setSerial(0);
      setExpiryDate(null);
    }
  }, [mode, show]);

  // Handle form submission
  const handleAddFormItem = async () => {
    if (name === "") {
      toast.error("Name is required");
      return;
    }
    if (expiryDate === null) {
      toast.error("Expiry date is required");
      return;
    }
    try {
      setLoading(true);
      if (mode === "add") {
        const { data } = await axios.post(
          `${API_ENDPOINT}admin/add-form-item`,
          { name, serial: serial, expiry_date: expiryDate, mode: "add" },
          getHeader(ADMIN)
        );
        toast.success(data.message);
        onAdded();
      } else {
        const { data } = await axios.post(
          `${API_ENDPOINT}admin/add-form-item`,
          {
            name,
            serial: serial,
            expiry_date: expiryDate,
            id: editItem._id,
            mode: "edit",
          },
          getHeader(ADMIN)
        );
        toast.success(data.message);
        onAdded();
      }
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper sx={{ padding: 3, maxWidth: 500, margin: "auto" }}>
        <Typography variant="h5" align="center" gutterBottom>
          {mode === "add" ? `Add Form Item` : `Edit Form Item`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              label="Serial Number"
              helperText="This serial no. will decide the order of the forms"
              value={serial}
              onChange={(e) => setSerial(Number(e.target.value) || "")}
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              sx={{ width: "100%" }}
              format="DD/MM/YYYY"
              label="Expiry Date"
              slotProps={{
                textField: {
                  helperText: "dd/mm/yyyy",
                },
              }}
              value={expiryDate}
              onChange={(newDate) => setExpiryDate(newDate)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ marginBottom: 2 }} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
              onClick={handleAddFormItem}
              sx={{ marginBottom: 2 }}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
};

export default AddFormItem;
