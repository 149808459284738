import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";

const IndustrySectorwiseRegistrationsCard = () => {
  const { data: sectorwiseData, isLoading: isSectorwiseLoading } =
    useCommonGetInit(
      `${API_ENDPOINT}user/a/get-industry-sectorwise-registrations`
    );

  // Transform the data to match the format for Recharts
  const dataBySector = useMemo(() => {
    if (!sectorwiseData || !Array.isArray(sectorwiseData.registrations))
      return [];

    // Filter out items where `item.industrySector` is null, undefined, or empty after converting to a string
    return sectorwiseData.registrations
      .filter(
        (item) =>
          typeof item.industrySector === "string" && item.industrySector.trim()
      ) // Keep only valid strings that are not empty
      .map((item) => ({
        industrySector: item.industrySector.trim(), // Ensure industrySector is a trimmed string
        count: item.count,
      }));
  }, [sectorwiseData]);

  // Set a dynamic height based on the number of industry sectors (50px height per sector)
  const chartHeight = dataBySector.length * 50;

  // Handle loading state
  if (isSectorwiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          <Typography flex={1} variant="h6">
            Registrations by Industry Sector
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              exportToExcel(
                dataBySector.map(({ industrySector, count }) => ({
                  industrySector,
                  count,
                })),
                "Registrations_by_Industry_Sector"
              )
            }
          >
            Export
          </Button>
        </Stack>
        <ResponsiveContainer width="100%" height={chartHeight || 250}>
          <BarChart data={dataBySector} layout="vertical">
            <XAxis type="number" />
            <YAxis type="category" dataKey="industrySector" width={200} />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#82ca9d">
              <LabelList dataKey="count" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default IndustrySectorwiseRegistrationsCard;
