import { Box, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import RdMyModal from "../widgets/myModal/RdMyModal";
import UploadImageForm from "./UploadImageForm";

const RegistrationContainer = ({
  height = "100vh",
  mw = "1200px",
  formElements,
  onAddElements,
}) => {
  const [showModal, setShowModal] = useState(false);
  const conStyle = {
    display: "block",
    maxWidth: mw,
    marginLeft: "auto",
    marginRight: "auto",
  };
  const onAddImage = (type) => {
    setShowModal((prev) => !prev);
  };
  return (
    <Box
      height={height}
      display={"flex"}
      overflow={"auto"}
      flexDirection={"column"}
    >
      <RdMyModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalC={<UploadImageForm show={showModal} setShow={setShowModal} />}
      />
      <Box position={"relative"}>
        <img
          style={conStyle}
          src="https://via.placeholder.com/1200x176"
          alt="header"
        />
        <IconButton
          onClick={() => onAddImage("header")}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <AddAPhotoIcon />
        </IconButton>
      </Box>
      <Box sx={{ ...conStyle }} width={"100%"} p={2} flexGrow={1}>
        <Button variant="contained" onClick={onAddElements}>
          Add Form Elements
        </Button>
      </Box>
      <Box position={"relative"}>
        <img
          style={conStyle}
          src="https://via.placeholder.com/1200x176"
          alt="footer"
        />
        <IconButton
          onClick={() => onAddImage("footer")}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <AddAPhotoIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default RegistrationContainer;
