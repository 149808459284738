// src/context/UserContext.js
import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { ADMIN, API_ENDPOINT } from "../utils/constants";
import { getHeader } from "../utils/util";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to load user data from the backend
  const loadUser = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${API_ENDPOINT}user/get-profile`,
        getHeader(ADMIN)
      ); // Update with your API endpoint
      setUser(data);
    } catch (error) {
      console.error("Failed to load user", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  // Load user data when the component mounts
  useEffect(() => {
    loadUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading, reloadUser: loadUser }}>
      {children}
    </UserContext.Provider>
  );
};
