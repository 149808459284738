import { Cropper } from "react-cropper";
import { useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid, Button, Typography, Input, Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toastError } from "../utils/util";
function UploadImageForm({
  url = "",
  show,
  setShow,
  aspect_ratio = 1200 / 176,
}) {
  const cropperRef = useRef(null);
  const image_selector = useRef();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);

  const onInputChange = (event) => {
    const file = event.target.files[0];
    if (!file) return; // If no file is selected, return
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    if (!show) {
      setImage(null); // Reset image
      if (image_selector.current) {
        image_selector.current.value = ""; // Clear the file input field
      }
    }
  }, [show]);

  const cancelCrop = () => {
    setShow(false); // Close the modal/form
  };

  const uploadImage = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const base64Image = cropper.getCroppedCanvas().toDataURL();
    const base64ImageContent = base64Image.replace(
      /^data:image\/(png|jpg);base64,/,
      ""
    );
    const blob = base64toBlob(base64ImageContent, "image/png");
    const sizeInKB = blob.size / 1024;

    if (sizeInKB > 1024) {
      toast.error(
        "Image size is more than 500KB. Please choose a smaller image or reduce its quality."
      );
      return;
    }

    let formData = new FormData();
    formData.append("file", blob);
    setUploading(true);
    try {
      const { data } = await axios.post(url, formData);
      setUploading(false);
      toast.success("Uploaded Successfully");
      setTimeout(() => {
        document.location.reload();
      }, 5);
    } catch (error) {
      toastError(error);
    } finally {
      setUploading(false);
    }
  };

  const base64toBlob = (base64Data, contentType = "") => {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return (
    <Box style={{ height: "70vh" }}>
      <Box
        sx={{
          display: "table",
          color: "#777",
          fontSize: "14px",
          padding: "8px",
          border: "1px dotted #ddd",
          marginBottom: "16px",
        }}
      >
        <Input
          key={show ? 1 : 2}
          type="file"
          inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
          onChange={onInputChange}
          ref={image_selector} // Referencing the input for clearing it
        />
        <Typography variant="body2">
          Aspect Ratio: {Math.round(aspect_ratio)}:1 (Max size: 500KB)
        </Typography>
        <Typography variant="body2">
          Supported Formats: PNG, JPEG, JPG
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Cropper
            key={show ? 1 : 2}
            src={image}
            style={{ height: 300, width: "100%" }}
            initialAspectRatio={aspect_ratio}
            aspectRatio={aspect_ratio}
            preview=".img-preview"
            ref={cropperRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align="center">Preview</Typography>
          <div
            key={show ? 1 : 2}
            className="img-preview"
            style={{
              width: "100%",
              height: "172px",
              overflow: "hidden",
              display: "inline-block",
            }}
          ></div>
        </Grid>
      </Grid>

      <Stack mt={2} gap={2} direction={"row"}>
        <Box flex={1}></Box>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={cancelCrop}
        >
          Cancel
        </Button>
        <LoadingButton
          size="small"
          loading={uploading}
          variant="contained"
          color="primary"
          onClick={uploadImage}
        >
          Upload
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default UploadImageForm;
