// Install Dependencies:

// npm install @mui/material @emotion/react @emotion/styled react-beautiful-dnd uuid @mui/icons-material @mui/x-date-pickers date-fns

// Import Statements:

import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Drawer,
  IconButton,
  Slider,
  Divider,
  Rating,
  Box,
  Grid,
  Card,
  CardContent,
  Container,
  Stack,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";

import TextFieldsIcon from "@mui/icons-material/TextFields";
import SubjectIcon from "@mui/icons-material/Subject";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StarIcon from "@mui/icons-material/Star";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import EditIcon from "@mui/icons-material/Edit";
import TableViewIcon from "@mui/icons-material/TableView";
import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import DescriptionIcon from "@mui/icons-material/Description";
import { SaveOutlined } from "@mui/icons-material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BackBtn from "../../widgets/BackBtn";
import PaymentsIcon from "@mui/icons-material/Payments";
import PropertyListPopover from "./Proplist";
import MyQuill from "../../quill/MyQuill";
import ParagraphDescription from "./ParagraphDescription";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import SelectSuppliers from "./SelectSuppliers";
import PdfUploadWidget from "../widgets/PdfUploadWidget";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { getHeader, toastError } from "../../utils/util";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicRowTable from "../widgets/DynamicRowTable";
import HeaderEditorDT from "../widgets/HeaderEditorDT";
import Labelinput from "../widgets/Labelinput";
import MyDatePicker from "../DatePicker";
import MyTimePicker from "../MyTimePicker";
import MyDateRange from "../MyDateRange";
import BadgeIcon from "@mui/icons-material/Badge";
import Groups3Icon from "@mui/icons-material/Groups3";
import HeaderEditorRateTable, {
  RATED_TYPES,
} from "../widgets/HeaderEditorRateTable";
import PurchaseItems from "../widgets/PurchaseItems";
import AddProductItem from "../widgets/AddProductItem";
import RatedItemsRender from "../widgets/RatedItemsRender";
import KeyValueForm from "../widgets/KeyValueForm";
export const INPUT_TYPES = [
  "text", // Basic single-line text input
  "number", // Input for numerical values (with optional step, min, max attributes)
  "password", // Masked text input (e.g., for passwords)
  "email", // Input for email addresses
  "search", // Search field with a cancel button (in some browsers)
  "tel", // Input for telephone numbers
  "url", // Input for URLs
  "date", // Input for date values (calendar picker in some browsers)
  "datetime-local", // Input for date and time values without a time zone
  "month", // Input for month and year
  "week", // Input for week number and year
  "time", // Input for time values
  "color", // Input for color values (color picker in some browsers)
];

/* 

till 15 sqm eligible for 3 Badges
15  3
25sqm 4

35 5

45 6

*/

export function OesFormBuilderDemo() {
  const { id } = useParams();
  const [reloadForm, setReloadForm] = useState(false);
  const {
    data: formItem,
    isLoading,
    isError,
    error,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-form-item?id=${id}&reload=${reloadForm}`
  );

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Create Form", "Add Suppliers", "Review & Submit"];

  const [availableFields, setAvailableFields] = useState([
    {
      id: "TextField",
      type: "TextField",
      label: "Text Field",
      icon: <TextFieldsIcon />,
    },
    {
      id: "TextArea",
      type: "TextArea",
      label: "Text Area",
      icon: <SubjectIcon />,
    },
    {
      id: "PlainText",
      type: "PlainText",
      label: "Plain Text",
      icon: <FormatColorTextIcon />,
    },
    {
      id: "ParagraphDescription",
      type: "ParagraphDescription",
      label: "Paragraph/Description",
      icon: <DescriptionIcon />,
    },
    {
      id: "Checkbox",
      type: "Checkbox",
      label: "Checkbox",
      icon: <CheckBoxIcon />,
    },
    {
      id: "LabelInput",
      type: "LabelInput",
      label: "Label Input",
      icon: <LabelOutlinedIcon />,
    },
    {
      id: "RadioGroup",
      type: "RadioGroup",
      label: "Radio Group",
      icon: <RadioButtonCheckedIcon />,
    },
    {
      id: "DocPDF",
      type: "DocPDF",
      label: "Upload PDF",
      icon: <PictureAsPdfIcon />,
    },
    {
      id: "DynamicTable",
      type: "DynamicTable",
      label: "Dynamic Table",
      icon: <TableViewIcon />,
    },
    {
      id: "Select",
      type: "Select",
      label: "Select",
      icon: <TextFieldsIcon />,
    },

    {
      id: "DatePicker",
      type: "DatePicker",
      label: "Date Picker",
      icon: <CalendarTodayIcon />,
    },
    {
      id: "DateRange",
      type: "DateRange",
      label: "Date Range",
      icon: <EventRepeatIcon />,
    },
    {
      id: "TimePicker",
      type: "TimePicker",
      label: "Time Picker",
      icon: <AccessTimeIcon />,
    },

    {
      id: "RatedItems",
      type: "RatedItems",
      label: "Rated Items",
      icon: <PaymentsIcon />,
    },
    {
      id: "PurchaseItems",
      type: "PurchaseItems",
      label: "Purchase Items",
      icon: <AddShoppingCartIcon />,
    },
    {
      id: "FileUpload",
      type: "FileUpload",
      label: "File Upload",
      icon: <AttachFileIcon />,
    },
    {
      id: "Rating",
      type: "Rating",
      label: "Rating",
      icon: <StarIcon />,
    },
    {
      id: "TeamMembers",
      type: "TeamMembers",
      label: "Team Members",
      icon: <Groups3Icon />,
    },
    // {
    //   id: "TeamMembersAdder",
    //   type: "TeamMembersAdder",
    //   label: "Team Members Adder",
    //   icon: <GroupAddIcon />,
    // },
    // {
    //   id: "BadgeEntitlementInfo",
    //   type: "BadgeEntitlementInfo",
    //   label: "Badge Entitlement Info",
    //   icon: <BadgeIcon />,
    // },
    // {
    //   id: "Switch",
    //   type: "Switch",
    //   label: "Switch",
    //   icon: <ToggleOnIcon />,
    // },
    // {
    //   id: "Slider",
    //   type: "Slider",
    //   label: "Slider",
    //   icon: <LinearScaleIcon />,
    // },
    {
      id: "Divider",
      type: "Divider",
      label: "Divider",
      icon: <HorizontalRuleIcon />,
    },
  ]);
  const [formFields, setFormFields] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  useEffect(() => {
    if (formItem) {
      if (formFields.length === 0) {
        setFormFields(formItem.form || []);
        setSuppliers(formItem.suppliers || []);
      }
    }
  }, [formItem]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (destination.droppableId !== "formFields") return;

    if (source.droppableId === "availableFields") {
      const sourceField = availableFields[source.index];
      const newField = {
        ...sourceField,
        id: uuidv4(),
        properties: getDefaultProperties(sourceField.type),
      };
      const newFormFields = Array.from(formFields);
      newFormFields.splice(destination.index, 0, newField);
      setFormFields(newFormFields);
    } else {
      const reorderedFields = Array.from(formFields);
      const [movedField] = reorderedFields.splice(source.index, 1);
      reorderedFields.splice(destination.index, 0, movedField);
      setFormFields(reorderedFields);
    }
  };

  const handleFieldEdit = (fieldId) => {
    setSelectedFieldId(fieldId);
    setIsDrawerOpen(true);
  };

  const handleFieldDelete = (fieldId) => {
    setFormFields((prevFields) =>
      prevFields.filter((field) => field.id !== fieldId)
    );
  };

  const updateFieldProperties = (fieldId, newProperties) => {
    setFormFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId
          ? { ...field, properties: { ...field.properties, ...newProperties } }
          : field
      )
    );
  };

  const getDefaultProperties = (type) => {
    switch (type) {
      case "TextField":
        return {
          label: "Text Field",
          type: "text",
          types: INPUT_TYPES,
          placeholder: "",
          required: false,
        };
      case "TeamMembers":
        return {
          label: "Team Members will come here along with relevant details",
          type: "TeamMembers",
        };
      case "LabelInput":
        return {
          label: "Label input",
          type: "text",
          disabled: false,
          types: INPUT_TYPES,
          placeholder: "",
          required: false,
        };
      case "DocPDF":
        return {
          label: "Upload PDF",
          type: "DocPDF",
          link: "",
        };
      case "DynamicTable":
        return {
          label: "Dynamic Table",
          types: INPUT_TYPES,
          headers: [
            {
              header: "Header1",
              type: "text",
              key: "key1",
            },
            {
              header: "Header2",
              type: "text",
              key: "key2",
            },
          ],
        };
      case "RatedItems":
        return {
          label: "Rated Items",
          showTotal: false,
          totalVar: "",
          types: RATED_TYPES,
          headers: [
            {
              header: "Header1",
              type: RATED_TYPES[0],
              key: "key1",
            },
            {
              header: "Header2",
              type: RATED_TYPES[0],
              key: "key2",
            },
          ],
          items: [],
        };

      case "PurchaseItems":
        return {
          label: "Purchase Items",
          items: [],
        };

      case "PlainText":
        return {
          label: "Plain Text",
          variant: "body1",
          options: [
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "subtitle1",
            "subtitle2",
            "body1",
            "body2",
            "caption",
            "button",
            "overline",
            "inherit",
          ],
        };
      case "ParagraphDescription":
        return { label: "Paragraph/Description" };
      case "TextArea":
        return {
          label: "Text Area",
          placeholder: "",
          required: false,
          rows: 4,
        };

      case "Checkbox":
        return { label: "Checkbox", required: false };
      case "RadioGroup":
        return {
          label: "Radio Group",
          options: ["Option 1", "Option 2"],
          required: false,
        };
      case "Select":
        return {
          label: "Select",
          options: ["Option 1", "Option 2"],
          required: false,
        };
      case "Switch":
        return { label: "Switch", required: false };
      case "DatePicker":
        return { label: "Date Picker", required: false };
      case "DateRange":
        return { label: "Date Range", required: false };
      case "TimePicker":
        return { label: "Time Picker", required: false };
      case "Slider":
        return { label: "Slider", required: false, min: 0, max: 100, step: 1 };
      case "FileUpload":
        return { label: "File Upload", required: false };
      case "Rating":
        return { label: "Rating", required: false };
      case "Divider":
        return { label: "Divider" };

      default:
        return {};
    }
  };

  const handleSubmit = () => {
    handleSaveDraft();
  };
  const handleSaveDraft = async () => {
    const newArr = formFields.map(({ icon, ...rest }) => rest);
    const dataToUpload = { form: newArr, suppliers: suppliers };
    try {
      setSavingDraft(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}user/a/save-oes-draft?id=${id}`,
        dataToUpload,
        getHeader(ADMIN)
      );
      toast.success(data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setSavingDraft(false);
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Droppable droppableId="availableFields" isDropDisabled>
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps}>
                        <Typography variant="h6">Available Fields</Typography>{" "}
                        {availableFields.map((field, index) => (
                          <Draggable
                            key={field.id}
                            draggableId={field.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  padding: "8px",
                                  margin: "4px",
                                  backgroundColor: "#f0f0f0",
                                  borderRadius: "4px",
                                  cursor: "grab",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {field.icon}
                                <Typography
                                  variant="body1"
                                  style={{ marginLeft: "8px" }}
                                >
                                  {field.label}
                                </Typography>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="h6">
                    Editing: {formItem?.name}
                  </Typography>
                  <Box p={1} m={1} border={"1px solid #ccc"}>
                    <Droppable droppableId="formFields">
                      {(provided) => (
                        <Grid
                          container
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {formFields.length === 0 && (
                            <Typography
                              m={2}
                              variant="body1"
                              color="textSecondary"
                            >
                              Drag form fields here to build your form
                            </Typography>
                          )}

                          {formFields.map((field, index) => (
                            <Draggable
                              key={field.id}
                              draggableId={field.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Grid
                                  item
                                  xs={
                                    field?.properties?.grid
                                      ? parseInt(field.properties.grid)
                                      : 12
                                  }
                                >
                                  <Box
                                    m={1}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={provided.draggableProps.style}
                                  >
                                    <div
                                      {...provided.dragHandleProps}
                                      style={{
                                        padding: "8px",
                                        marginBottom: "8px",
                                        backgroundColor: snapshot.isDragging
                                          ? "#e0f7fa"
                                          : "#fff",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "grab",
                                      }}
                                    >
                                      <Box flex={1}>
                                        {renderFormElement(field)}
                                      </Box>

                                      <div
                                        style={{
                                          marginLeft: "auto",
                                          opacity: 0.7,
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleFieldEdit(field.id)
                                          }
                                          aria-label="edit"
                                        >
                                          <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleFieldDelete(field.id)
                                          }
                                          aria-label="delete"
                                        >
                                          <DeleteOutlineIcon fontSize="small" />
                                        </IconButton>
                                      </div>
                                    </div>
                                  </Box>
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Grid>
                      )}
                    </Droppable>
                  </Box>
                </Grid>
              </Grid>
            </DragDropContext>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              sx={{ "& .MuiDrawer-paper": { width: 550 } }}
            >
              {renderEditingPanel()}
            </Drawer>
          </>
        );
      case 1:
        return (
          <SelectSuppliers
            value={suppliers}
            onChange={(newSelectedSuppliers) => {
              setSuppliers(newSelectedSuppliers);
            }}
          />
        );
      case 2:
        return (
          <div>
            <Typography variant="h6" textAlign={"center"}>
              Review Your Form
            </Typography>

            <Container>
              <Card>
                <CardContent>{renderFinalForm()}</CardContent>
              </Card>
              <Box mt={2}>
                <Card>
                  <CardContent>
                    <Box mt={2}>
                      <strong>Selected Suppliers:</strong>
                      <ul>
                        {suppliers.map((supplier) => (
                          <li key={supplier._id}>{supplier.supplierName}</li>
                        ))}
                      </ul>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Container>
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  const renderFormElement = (field) => {
    const { type, properties } = field;
    switch (type) {
      case "TextField":
        return (
          <TextField
            type={properties.type}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
            disabled
          />
        );
      case "TeamMembers":
        return <Typography variant="h6">{properties.label}</Typography>;
      case "LabelInput":
        return (
          <Labelinput
            type={properties.type}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            disabled={true}
          />
        );
      case "DocPDF":
        return (
          <>
            {properties.link !== "" ? (
              <a href={properties.link} target="_blank">
                <Button
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  size="small"
                >
                  {properties.label}
                </Button>
              </a>
            ) : (
              <Stack direction={"row"} gap={2}>
                <PictureAsPdfIcon />
                <Typography variant="body1">{properties.label}</Typography>
              </Stack>
            )}
          </>
        );
      case "DynamicTable":
        return <DynamicRowTable headers={properties.headers} />;
      case "TextArea":
        return (
          <TextField
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={properties.rows}
            disabled
          />
        );

      case "RatedItems":
        return (
          <RatedItemsRender
            min={properties.minDate}
            max={properties.maxDate}
            headers={properties.headers}
            itemsList={properties.items}
            totalRequired={properties.showTotal}
            totalVar={properties.totalVar}
          />
        );
      case "PurchaseItems":
        return (
          <PurchaseItems
            onDelete={(it) => {
              console.log(it, properties.items);
              updateFieldProperties(field.id, {
                items: properties.items.filter(
                  (item) => item.productId !== it.productId
                ),
              });
            }}
            itemsList={properties.items}
            isEdit={true}
          />
        );
      case "PlainText":
        return (
          <Typography variant={properties.variant}>
            {properties.label}
          </Typography>
        );
      case "ParagraphDescription":
        return <ParagraphDescription properties={properties} />;

      case "Checkbox":
        return (
          <FormControlLabel
            control={<Checkbox disabled />}
            label={properties.label}
          />
        );
      case "RadioGroup":
        return (
          <FormControl component="fieldset">
            <Typography variant="subtitle1">{properties.label}</Typography>
            <RadioGroup>
              {properties.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio disabled />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "Select":
        return (
          <FormControl variant="outlined" fullWidth size="small" disabled>
            <InputLabel>{properties.label}</InputLabel>
            <Select label={properties.label}>
              {properties.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "Switch":
        return (
          <FormControlLabel
            control={<Switch disabled />}
            label={properties.label}
          />
        );
      case "DatePicker":
        return (
          <MyDatePicker
            min={properties.minDate}
            max={properties.maxDate}
            label={properties.label}
          />
        );
      case "TimePicker":
        return <MyTimePicker label={properties.label} />;
      case "DateRange":
        return <MyDateRange label={properties.label} />;

      case "Slider":
        return (
          <div style={{ width: "100%", padding: "0 16px" }}>
            <Typography gutterBottom>{properties.label}</Typography>
            <Slider
              value={properties.min}
              min={properties.min}
              max={properties.max}
              step={properties.step}
              disabled
            />
          </div>
        );
      case "FileUpload":
        return (
          <TextField
            label={properties.label}
            type="file"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            size="small"
            disabled
          />
        );
      case "Rating":
        return (
          <div>
            <Typography component="legend">{properties.label}</Typography>
          </div>
        );
      case "Divider":
        return <Divider />;
      default:
        return null;
    }
  };

  const renderFinalForm = () => {
    return (
      <form>
        <Grid container width={"100%"}>
          {formFields.map((field) => (
            <Grid
              item
              xs={
                field?.properties?.grid ? parseInt(field.properties.grid) : 12
              }
            >
              <Box key={field.id} m={1}>
                {renderFormElementInFinalForm(field)}
              </Box>
            </Grid>
          ))}
        </Grid>
      </form>
    );
  };

  const renderFormElementInFinalForm = (field) => {
    const { type, properties } = field;
    switch (type) {
      case "TextField":
        return (
          <TextField
            type={properties.type}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
          />
        );
      case "TeamMembers":
        return <Typography variant="h6">{properties.label}</Typography>;
      case "RatedItems":
        return (
          <RatedItemsRender
            min={properties.minDate}
            max={properties.maxDate}
            headers={properties.headers}
            itemsList={properties.items}
            totalRequired={properties.showTotal}
            totalVar={properties.totalVar}
          />
        );
      case "PlainText":
        return (
          <Typography variant={properties.variant}>
            {properties.label}
          </Typography>
        );
      case "LabelInput":
        return (
          <Labelinput
            type={properties.type}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
          />
        );
      case "DynamicTable":
        return <DynamicRowTable headers={properties.headers} />;
      case "PurchaseItems":
        return <PurchaseItems itemsList={properties.items} isEdit={false} />;
      case "DocPDF":
        return (
          <>
            {properties.link !== "" ? (
              <a href={properties.link} target="_blank">
                <Button
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  size="small"
                >
                  {properties.label}
                </Button>
              </a>
            ) : (
              <></>
            )}
          </>
        );
      case "ParagraphDescription":
        return <ParagraphDescription properties={properties} />;
      case "TextArea":
        return (
          <TextField
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={properties.rows}
          />
        );

      case "Checkbox":
        return (
          <FormControlLabel
            control={<Checkbox />}
            label={properties.label}
            required={properties.required}
          />
        );
      case "RadioGroup":
        return (
          <FormControl component="fieldset" required={properties.required}>
            <Typography variant="subtitle1">{properties.label}</Typography>
            <RadioGroup>
              {properties.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "Select":
        return (
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            required={properties.required}
          >
            <InputLabel>{properties.label}</InputLabel>
            <Select label={properties.label}>
              {properties.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "Switch":
        return (
          <FormControlLabel
            control={<Switch />}
            label={properties.label}
            required={properties.required}
          />
        );
      case "DatePicker":
        return (
          <MyDatePicker
            min={properties.minDate}
            max={properties.maxDate}
            label={properties.label}
            required={properties.required}
          />
        );
      case "TimePicker":
        return (
          <MyTimePicker
            label={properties.label}
            required={properties.required}
          />
        );
      case "Slider":
        return (
          <div style={{ width: "100%", padding: "0 16px" }}>
            <Typography gutterBottom>{properties.label}</Typography>
            <Slider
              defaultValue={(properties.min + properties.max) / 2}
              min={properties.min}
              max={properties.max}
              step={properties.step}
              marks
              valueLabelDisplay="auto"
            />
          </div>
        );
      case "FileUpload":
        return (
          <TextField
            label={properties.label}
            type="file"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            size="small"
            required={properties.required}
          />
        );
      case "Rating":
        return (
          <div>
            <Typography component="legend">{properties.label}</Typography>
            <Rating name={field.id} />
            <Typography variant="body2">Rating component goes here</Typography>
          </div>
        );
      case "Divider":
        return <Divider />;

      default:
        return null;
    }
  };

  const renderEditingPanel = () => {
    const field = formFields.find((f) => f.id === selectedFieldId);
    if (!field) return null;

    const { type, properties } = field;

    const handlePropertyChange = (propName) => (event) => {
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      updateFieldProperties(field.id, { [propName]: value });
    };

    const handlQuillChange = (propName, newV) => {
      updateFieldProperties(field.id, { [propName]: newV });
    };

    const handleOptionsChange = (index) => (event) => {
      const newOptions = [...properties.options];
      newOptions[index] = event.target.value;
      updateFieldProperties(field.id, { options: newOptions });
    };

    const addOption = () => {
      updateFieldProperties(field.id, { options: [...properties.options, ""] });
    };

    const removeOption = (index) => {
      const newOptions = properties.options.filter((_, i) => i !== index);
      updateFieldProperties(field.id, { options: newOptions });
    };

    const IsRequiredWidget = () => {
      return (
        <Box mt={1} mb={1}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={properties.required}
                onChange={handlePropertyChange("required")}
              />
            }
            label="is this Field required?"
          />
        </Box>
      );
    };
    return (
      <div style={{ padding: "20px" }}>
        <Typography variant="h6">Edit Field</Typography>
        {type === "ParagraphDescription" && (
          <Box mt={1}>
            <PropertyListPopover />
            <MyQuill
              value={properties.label}
              onChange={(newV) => {
                handlQuillChange("label", newV);
              }}
            />
          </Box>
        )}

        {type === "DynamicTable" && (
          <>
            <HeaderEditorDT
              headers={properties.headers}
              onHeadersChange={(newH) => {
                handlQuillChange("headers", newH);
              }}
            />
          </>
        )}
        {type === "RatedItems" && (
          <>
            <HeaderEditorRateTable
              headers={properties.headers}
              onHeadersChange={(newH) => {
                handlQuillChange("headers", newH);
              }}
            />
            <Box mt={2}>
              <Box>
                <KeyValueForm
                  headers={properties.headers}
                  currentList={properties.items}
                  items={properties.headers
                    .filter(
                      (item) =>
                        item.type === "label" || item.type === "label checkbox"
                    ) // Select only items where type is "text"
                    .map((item) => item.key)}
                  onSave={(items) => {
                    handlQuillChange("items", items);
                  }}
                />
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={properties.showTotal}
                    onChange={handlePropertyChange("showTotal")}
                  />
                }
                label={"show total?"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={properties.columnSelection}
                    onChange={handlePropertyChange("columnSelection")}
                  />
                }
                label={"is single column selection?"}
              />
              {properties.showTotal && (
                <TextField
                  size="small"
                  sx={{ mt: 1 }}
                  value={properties.totalVar}
                  onChange={handlePropertyChange("totalVar")}
                  fullWidth
                  variant="outlined"
                  label="Enter total key"
                />
              )}
            </Box>
          </>
        )}

        {type === "DocPDF" && (
          <PdfUploadWidget
            onFileUpload={(fileUrl) => {
              handlQuillChange("link", fileUrl);
              handlQuillChange("label", "Uploaded PDF");
            }}
          />
        )}

        {type !== "ParagraphDescription" &&
          type !== "TeamMembers" &&
          type !== "Divider" && (
            <>
              <TextField
                size="small"
                label="Label"
                value={properties.label}
                onChange={handlePropertyChange("label")}
                fullWidth
                margin="normal"
              />
              {(type === "TextField" ||
                type === "TextArea" ||
                type === "FileUpload") && (
                <TextField
                  size="small"
                  label="Placeholder"
                  value={properties.placeholder}
                  onChange={handlePropertyChange("placeholder")}
                  fullWidth
                  margin="normal"
                />
              )}
              {type === "TextArea" && (
                <TextField
                  size="small"
                  label="Rows"
                  type="number"
                  value={properties.rows}
                  onChange={handlePropertyChange("rows")}
                  fullWidth
                  margin="normal"
                />
              )}
              {type === "Slider" && (
                <>
                  <TextField
                    size="small"
                    label="Minimum Value"
                    type="number"
                    value={properties.min}
                    onChange={handlePropertyChange("min")}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    size="small"
                    label="Maximum Value"
                    type="number"
                    value={properties.max}
                    onChange={handlePropertyChange("max")}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Step"
                    type="number"
                    value={properties.step}
                    onChange={handlePropertyChange("step")}
                    fullWidth
                    margin="normal"
                  />
                </>
              )}
              {(type === "RadioGroup" || type === "Select") && (
                <>
                  <Typography variant="body1">Options</Typography>
                  {properties.options.map((option, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        marginBottom: "8px",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        size="small"
                        value={option}
                        onChange={handleOptionsChange(index)}
                        fullWidth
                      />
                      <IconButton
                        size="small"
                        onClick={() => removeOption(index)}
                        color="secondary"
                        aria-label="remove option"
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))}
                  <Box display={"flex"} justifyContent={"end"}>
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      variant="outlined"
                      onClick={addOption}
                      color="primary"
                    >
                      Add
                    </Button>
                  </Box>
                </>
              )}

              {type !== "PurchaseItems" &&
                type !== "Divider" &&
                type !== "DynamicTable" &&
                type !== "RatedItems" &&
                type !== "SectionHeader" &&
                type !== "Rating" && <IsRequiredWidget />}
            </>
          )}

        {type === "TeamMembers" && (
          <Box>
            <TextField
              size="small"
              type="number"
              helperText="baseSqm"
              label="Base sqm for badge entitlement"
              value={properties.basesqm}
              onChange={handlePropertyChange("basesqm")}
              fullWidth
              margin="normal"
            />
            <TextField
              size="small"
              type="number"
              label="Base number of badge entitlement"
              value={properties.basenum}
              onChange={handlePropertyChange("basenum")}
              fullWidth
              helperText="baseNum"
              margin="normal"
            />
            <TextField
              size="small"
              type="number"
              label="Extra sqm premium entitlement"
              value={properties.extrasqm}
              onChange={handlePropertyChange("extrasqm")}
              fullWidth
              helperText="extraSqm"
              margin="normal"
            />
            <TextField
              size="small"
              type="number"
              label="Extra number premium entitlement"
              value={properties.extranum}
              onChange={handlePropertyChange("extranum")}
              fullWidth
              helperText="extraNum"
              margin="normal"
            />
          </Box>
        )}

        {(type === "DatePicker" || type === "RatedItems") && (
          <Stack direction={"row"} gap={2}>
            <MyDatePicker
              label="Min Date"
              value={properties.minDate}
              onChange={(newV) => {
                handlQuillChange("minDate", newV);
              }}
            />
            <MyDatePicker
              label="Max Date"
              value={properties.maxDate}
              onChange={(newV) => {
                handlQuillChange("maxDate", newV);
              }}
            />
          </Stack>
        )}

        {type === "PurchaseItems" && (
          <AddProductItem
            onAdded={(item) => {
              updateFieldProperties(field.id, {
                items: [
                  ...properties.items,
                  {
                    ...item,
                    price: parseFloat(item.price),
                    productId: uuidv4(),
                  },
                ],
              });
            }}
          />
        )}
        {type === "PlainText" && (
          <FormControl sx={{ mt: 1, mb: 1 }} size="small" fullWidth>
            <InputLabel>Select variant</InputLabel>
            <Select
              size="small"
              value={properties.variant}
              label="Select variant"
              onChange={handlePropertyChange("variant")}
            >
              {properties.options.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {(type === "TextField" || type === "LabelInput") && (
          <FormControl sx={{ mt: 1, mb: 1 }} size="small" fullWidth>
            <InputLabel>Select input type</InputLabel>
            <Select
              size="small"
              value={properties.type}
              label="Select input type"
              onChange={handlePropertyChange("type")}
            >
              {properties.types.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {type === "Rating" && <IsRequiredWidget />}
        <TextField
          sx={{ mt: 2 }}
          size="small"
          fullWidth
          label="Grid Columns (1-12)"
          name="grid"
          type="number"
          value={properties.grid || 12}
          onChange={handlePropertyChange("grid")}
          inputProps={{ min: 1, max: 12 }}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setIsDrawerOpen(false)}
          style={{ marginTop: "20px" }}
        >
          Done
        </Button>
      </div>
    );
  };

  return (
    <Box>
      <Stack direction={"row"}>
        <Box flex={1}>
          <BackBtn />
        </Box>
        <LoadingButton
          variant="outlined"
          size="small"
          loading={savingDraft}
          onClick={handleSaveDraft}
          startIcon={<SaveOutlined fontSize="small" />}
        >
          Save Draft
        </LoadingButton>
      </Stack>
      <Box mt={2}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ marginTop: "20px" }}>
          {getStepContent(activeStep)}
          <Box display={"flex"} justifyContent={"end"} mt={3}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              style={{ marginRight: "10px" }}
            >
              Back
            </Button>
            {activeStep < steps.length - 1 && (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <LoadingButton
                loading={savingDraft}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </LoadingButton>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
}
