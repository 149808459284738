import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Link as MuiLink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const eventsArray = [
  {
    eventId: "E1",
    logo: "https://www.imtex.in/images/imtex-2024.png",
    name: "Imtex Forming 2024",
    link: "https://imtex2024-imtma.expoplanner.in/",
    eventTags: ["Technology", "Innovation", "Startups"],
    country: "India",
    city: "Bangalore",
    startDate: "2024-01-19",
    endDate: "2024-01-23",
    type: "Technology",
    attachedTo: "Tech Conference 2023",
    action: "Clone",
  },
  {
    eventId: "E2",
    logo: "https://apis.expoplanner.in/images/pic1694666542898f480c029-5e41-4bf9-ad34-c4181932d400.png",
    name: "Pune Machine Tool Expo 2024",
    link: "https://pmtx2024-imtma.expoplanner.in/event/login",
    eventTags: ["Renewable Energy", "Sustainability", "Environment"],
    country: "India",
    city: "Pune",
    startDate: "2024-05-23",
    endDate: "2024-05-26",
    type: "Machine Tools",
    attachedTo: "World Energy Summit",
    action: "Clone",
  },
];

const AllEvents = () => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="events table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Logo</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Date From</TableCell>
            <TableCell>Date To</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="center">Manage</TableCell>
            <TableCell align="center">Clone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventsArray.map((expo, i) => (
            <TableRow key={i} hover>
              <TableCell align="center">
                <img
                  src={expo.logo}
                  alt={`${expo.name} Logo`}
                  style={{ height: "60px", width: "auto" }}
                />
              </TableCell>
              <TableCell>
                <MuiLink component={Link} to={expo.link}>
                  {expo.name}
                </MuiLink>
              </TableCell>
              <TableCell>{`${expo.city}, ${expo.country}`}</TableCell>
              <TableCell>
                {format(new Date(expo.startDate), "MMM dd, yyyy")}
              </TableCell>
              <TableCell>
                {format(new Date(expo.endDate), "MMM dd, yyyy")}
              </TableCell>
              <TableCell>{expo.type}</TableCell>
              <TableCell align="center">
                <Button
                  disabled
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none" }}
                  to={`/event-settings?eventId=${expo.eventId}`}
                  component={Link}
                >
                  Manage
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button
                  disabled
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: "none" }}
                  to={`/event-settings?eventId=${expo.eventId}`}
                  component={Link}
                >
                  Clone
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllEvents;
