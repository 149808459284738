import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { exportToExcel } from "../../utils/util";

const MonthwiseRegistrationsCard = () => {
  const { data: monthwiseData, isLoading: isMonthwiseLoading } =
    useCommonGetInit(`${API_ENDPOINT}user/a/get-monthwise-registrations`);

  const dataByMonth = useMemo(() => {
    if (!monthwiseData) return [];

    // Create a placeholder array with all 12 months to ensure missing months show zero registrations
    const initialData = Array.from({ length: 12 }, (_, index) => ({
      month: new Date(0, index).toLocaleString("en", { month: "short" }),
      visitors: 0,
    }));

    // Map the data returned from the API into the initialData array
    monthwiseData.registrations.forEach((item) => {
      const monthIndex = item.month - 1; // month is 1-indexed, so convert to 0-indexed
      initialData[monthIndex].visitors = item.count;
    });

    return initialData;
  }, [monthwiseData]);

  // Handle loading state
  if (isMonthwiseLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack direction={"row"} spacing={2}>
          <Typography flex={1} variant="h6">
            Registrations by Month
          </Typography>
          <Button
            size="small"
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() => exportToExcel(dataByMonth, "Registrations_by_Month")}
          >
            Export
          </Button>
        </Stack>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={dataByMonth}>
            <XAxis dataKey="month" />
            <YAxis domain={[0, (dataMax) => Math.ceil(dataMax * 1.2)]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="visitors" fill="#8884d8">
              <LabelList dataKey="visitors" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default MonthwiseRegistrationsCard;
