import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Alert,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Card,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  getDbHeader,
  getErrorResponse,
  isUserLoggedIn,
  saveUser,
  toastError,
} from "../utils/util";
import axios from "axios";
import { ADMIN, API_ENDPOINT, USER } from "../utils/constants";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username === "") {
      toast.error("Username is required");
    } else if (password === "") {
      toast.error("Password is required");
    } else {
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${API_ENDPOINT}user/login`,
          { username, password },
          getDbHeader()
        );
        if (data.data.role === ADMIN) {
          saveUser(ADMIN, data.data);
        } else {
          saveUser(USER, data.data);
        }
        navigate(`/home`);
      } catch (error) {
        setError(getErrorResponse(error));
      } finally {
        setLoading(false);
      }
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isUserLoggedIn(ADMIN)) {
      navigate("/home");
    }
  }, []);
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
      maxWidth="sm"
    >
      <Box component="div" sx={{ width: 192, marginBottom: 4 }}>
        <img
          src={
            "https://s3.ap-south-1.amazonaws.com/expoplanner.in/download.png"
          }
          alt="Company Logo"
          width="100%"
        />
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 400,
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          required
          size="small"
          margin="normal"
          value={username} // Bind value to state
          onChange={(e) => setUsername(e.target.value)} // Update state on change
        />

        <FormControl
          variant="outlined"
          size="small"
          required
          fullWidth
          sx={{ mt: 3 }}
        >
          <InputLabel htmlFor="standard-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="standard-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          loading={loading}
        >
          Login
        </LoadingButton>
      </Box>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: (theme) => theme.palette.background.paper,
          p: 1,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 Expoplanner. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;
