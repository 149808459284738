import React, { useState } from "react";
import {
  Typography,
  TextField,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import dayjs from "dayjs";
export const MIN_DATE = dayjs("2024-05-23");
export const MAX_DATE = dayjs("2024-05-26");
const OesAddItems = () => {
  const [items, setItems] = useState(AUDIO_ITEMS);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const newTotal = items.reduce((acc, item) => acc + item.subtotal, 0);
    setTotal(newTotal);
  }, [items]);

  const handleQtyChange = (index, newQty) => {
    updateSubtotal(index, newQty);
  };
  const updateSubtotal = (index, newQty = null) => {
    const updatedItems = [...items];
    if (newQty !== null) updatedItems[index].qty = newQty;
    const startDate = updatedItems[index].dateFrom
      ? dayjs(updatedItems[index].dateFrom)
      : null;
    const endDate = updatedItems[index].dateTo
      ? dayjs(updatedItems[index].dateTo)
      : null;
    const areDatesValid = startDate?.isValid() && endDate?.isValid();
    const days = areDatesValid
      ? Math.max(endDate.diff(startDate, "days"), 0) + 1
      : 0;
    updatedItems[index].subtotal =
      days > 0 ? updatedItems[index].qty * updatedItems[index].rate * days : 0;

    setItems(updatedItems);
  };

  const handleDateChange = (index, type, newValue) => {
    const updatedItems = [...items];
    if (newValue && dayjs(newValue).isValid()) {
      updatedItems[index][type] = newValue.format("YYYY-MM-DD");
      setItems(updatedItems);
      updateSubtotal(index);
    }
  };
  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Services/Items</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Rate/Day</TableCell>
              <TableCell>Date Range</TableCell>
              <TableCell>Sub Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.qty} // Correct the field from item.qty to item.Quantity
                    variant="outlined"
                    type="number"
                    onChange={(e) => handleQtyChange(index, e.target.value)}
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    size="small"
                    value={item.rate}
                    variant="outlined"
                    type="number"
                    disabled
                  />
                </TableCell>
                <TableCell>
                  <DatePicker
                    className="exp_date_picker"
                    slotProps={{
                      textField: {
                        helperText: "dd/mm/yyyy",
                      },
                    }}
                    format="DD/MM/YYYY"
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    label="Date From"
                    size="small"
                    variant="outlined"
                    value={item.dateFrom ? dayjs(item.dateFrom) : null}
                    onChange={(newValue) =>
                      handleDateChange(index, "dateFrom", newValue)
                    }
                  />

                  <DatePicker
                    sx={{ mt: 2 }}
                    className="exp_date_picker"
                    slotProps={{
                      textField: {
                        helperText: "dd/mm/yyyy",
                      },
                    }}
                    format="DD/MM/YYYY"
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    label="Date To"
                    size="small"
                    variant="outlined"
                    value={item.dateTo ? dayjs(item.dateTo) : null}
                    onChange={(newValue) =>
                      handleDateChange(index, "dateTo", newValue)
                    }
                  />
                </TableCell>
                <TableCell>{item.subtotal.toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">Total Amount</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(total)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">18% GST</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(0.18 * total)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} align="right">
                <Typography variant="body1">Total</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">
                  {new Intl.NumberFormat("en-IN", {
                    maximumFractionDigits: 2,
                  }).format(1.18 * total)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </LocalizationProvider>
    </Container>
  );
};

const AUDIO_ITEMS = [
  {
    name: "LED TV 40 / 42 WITH STAND OR WALL MOUNT",
    qty: 0,
    rate: 2000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED TV 50 / 52 WITH STAND OR WALL MOUNT",
    qty: 0,
    rate: 2500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED TV 55 WITH STAND OR WALL MOUNT",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LAPTOP WITH ACCS., CORE I5 WITH O.S.",
    qty: 0,
    rate: 750,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 3.9  INDOOR 07ft  x 09ft (8X10 SIZE)",
    qty: 0,
    rate: 9600,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 08FT X 12FT",
    qty: 0,
    rate: 11520,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 09FT X 16FT",
    qty: 0,
    rate: 19200,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 10FT X 20FT",
    qty: 0,
    rate: 24000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 12FT X 18FT",
    qty: 0,
    rate: 25920,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 15FT X 15FT",
    qty: 0,
    rate: 30720,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED WALL 6MM INDOOR 12FT X 24FT",
    qty: 0,
    rate: 34560,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "DIGITAL STILL CAMERA /DIGITAL CAMERA TO CD CONVERTION 1 No.",
    qty: 0,
    rate: 7500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "H D VIDEO CAMERA [AS PER YOUR REQUIREMENT HARD DISK WILL CHARGE PER MARKET PRICE]",
    qty: 0,
    rate: 10000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "MULTIMEDIA SPEAKER (Small)",
    qty: 0,
    rate: 1600,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LCD PROJECTOR 3,000 ANSI LUMENS WITH SCREEN.",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "4 x 6 / 8 x 6 SCREEN",
    qty: 0,
    rate: 500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "COMPUTER WITH ACCS., CORE 2 DUO",
    qty: 0,
    rate: 1500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "HP LAZER JET PRINTER BLACK",
    qty: 0,
    rate: 2500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "HP LAZER JET PRINTER COLOUR",
    qty: 0,
    rate: 5000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "PAR LIGHT WITH DIMMER",
    qty: 0,
    rate: 750,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "LED PAR LIGHT",
    qty: 0,
    rate: 500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "CORDLESS COLLER / HAND MIKE",
    qty: 0,
    rate: 1000,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "PORTABLE P A SYSTEM WITH 1 MIC & 1 AUX INPUT",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "BUILT IN POWER SPEAKER WITH STAND & 1 CORDLESS HAND MIC",
    qty: 0,
    rate: 2500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "P.A. SYSTEM [AHUJA]- i. Amplifier 1 No./ii. Speakers 2 Nos",
    qty: 0,
    rate: 3500,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
  {
    name: "Flex printing with mounding (as per Size) @ 55/-per sq.fit",
    qty: 0,
    rate: 55,
    subtotal: 0,
    dateFrom: null,
    dateTo: null,
  },
];

export default OesAddItems;
