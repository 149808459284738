import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill"; // Importing ReactQuill
import "react-quill/dist/quill.snow.css"; // Importing Quill CSS

const RegistrationTermsConditions = () => {
  const [terms, setTerms] =
    useState(`<p>I hereby expressly give Consent to the Indian Machine Tools Manufacturers’ Association (“IMTMA”) as per the requirements of the Digital Personal Data Protection Act, 2023.</p><p>I hereby permit IMTMA and its authorized representatives to collect the following details of my (“Personal Data”):</p><ul><li>Name</li><li>E-mail Address</li><li>Contact Number</li></ul><p>I agree that the Personal Data shall be used for security purposes, promotion of IMTMA exhibitions, training programmes, mega events, and all other events, exhibitions and programmes that may be organized by IMTMA or its affiliates.</p><p>This Consent has been made by me unconditionally, out of my free will, not due to any undue influence by any person or party and has been read and fully understood by me.</p><p>This Consent may be withdrawn at any point by a written communication to IMTMA to be addressed to it.blr@imtma.in.</p>
  `);
  const [title, setTitle] = useState(""); // State to hold the title of Terms and Conditions
  const [successMessage, setSuccessMessage] = useState("");

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming an API call or other logic here
    console.log({ title, terms });

    setSuccessMessage("Terms and Conditions updated successfully");
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Update Terms and Conditions
        </Typography>

        <form onSubmit={handleSubmit}>
          {/* Title Field */}
          <TextField
            size="small"
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ mb: 3 }}
          />

          {/* Terms and Conditions Editor */}
          <ReactQuill
            value={terms}
            onChange={setTerms}
            theme="snow" // Quill editor theme
            style={{ height: "300px", marginBottom: "20px" }}
          />

          {/* Submit Button */}
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Save Changes
            </Button>
          </Box>

          {/* Success Message */}
          {successMessage && (
            <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
              {successMessage}
            </Typography>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default RegistrationTermsConditions;
