import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Paper,
  Container,
  Autocomplete,
  Alert,
} from "@mui/material";

import BackBtn from "../../widgets/BackBtn";
import CountrySelector from "../../widgets/CountrySelector";
import StateSelector from "../../widgets/StateSelector";
import CitySelector from "../../widgets/CitySelector";
import MyDatePicker from "../../formbuilder/DatePicker";
import MyTimePicker from "../../formbuilder/MyTimePicker";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import IndustrySectorSelector from "../../widgets/IndustrySectorSelector";
import axios from "axios";
import { getHeader, toastError } from "../../utils/util";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import CustomCircularP from "../../widgets/CustomCircularP";
import { Password } from "@mui/icons-material";

const ExhibitorForm = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-floor?id=1`
  );
  const [stands, setStands] = useState([]);
  const [adding, setAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validId, setValidId] = useState(null);
  const [formValues, setFormValues] = useState({
    user_id: "",
    username: "",
    password: "",
    companyName: "",
    contactSalutation: "",
    contactFirstName: "",
    contactLastName: "",
    contactDesignation: "",
    mobile: "",
    companyEmail: "",
    contactEmail: "",
    address1: "",
    address2: "",
    address3: "",
    country: "",
    state: "",
    city: "",
    pin: "",
    phone: "",
    fax: "",
    website: "",
    companyShortName: "",
    fascia: "",
    weAre: "",
    industrySector: [],
    exhibitorCategory: "",
    hallNumber: "",
    standNumber: "",
    spaceType: "",
    exhibitArrival: null,
    exhibitArrivalTimeFrom: null,
    exhibitArrivalTimeTo: null,
    materialArrival: null,
    materialArrivalTimeFrom: null,
    materialArrivalTimeTo: null,
    dismantling: null,
    dismantlingTimeFrom: null,
    dismantlingTimeTo: null,
    standSize: "",
  });

  useEffect(() => {
    if (formValues.hallNumber) {
      loadStands(formValues.hallNumber);
    }
  }, [formValues.hallNumber]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${API_ENDPOINT}admin/get-user-by-id?id=${id}`,
          getHeader(ADMIN)
        );

        // Update form values with fetched data
        setFormValues((prevValues) => ({
          ...prevValues,
          user_id: data.user_id,
          username: data.username,
          password: data.password,
          companyName: data.companyName?.value || "",
          contactSalutation: data.contactSalutation?.value || "",
          contactFirstName: data.contactFirstName?.value || "",
          contactLastName: data.contactLastName?.value || "",
          contactDesignation: data.contactDesignation?.value || "",
          mobile: data.mobile?.value || "",
          companyEmail: data.companyEmail?.value || "",
          contactEmail: data.contactEmail?.value || "",
          address1: data.address1?.value || "",
          address2: data.address2?.value || "",
          address3: data.address3?.value || "",
          country: data.country?.value || "",
          state: data.state?.value || "",
          city: data.city?.value || "",
          pin: data.pin?.value || "",
          phone: data.phone?.value || "",
          fax: data.fax?.value || "",
          website: data.website?.value || "",
          companyShortName: data.companyShortName?.value || "",
          fascia: data.fascia?.value || "",
          weAre: data.weAre?.value || "",
          industrySector: data.industrySector?.value || [],
          exhibitorCategory: data.exhibitorCategory?.value || "",
          hallNumber: data.hallNumber?.value || "",
          standNumber: data.standNumber?.value || "",
          spaceType: data.spaceType?.value || "",
          exhibitArrival: data.exhibitArrival?.value || null,
          exhibitArrivalTimeFrom: data.exhibitArrivalTimeFrom?.value || null,
          exhibitArrivalTimeTo: data.exhibitArrivalTimeTo?.value || null,
          materialArrival: data.materialArrival?.value || null,
          materialArrivalTimeFrom: data.materialArrivalTimeFrom?.value || null,
          materialArrivalTimeTo: data.materialArrivalTimeTo?.value || null,
          dismantling: data.dismantling?.value || null,
          dismantlingTimeFrom: data.dismantlingTimeFrom?.value || null,
          dismantlingTimeTo: data.dismantlingTimeTo?.value || null,
          standSize: data.standSize?.value || "",
        }));
      } catch (error) {
        toastError(error);
        setValidId(false);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  if (validId !== null && validId === false) {
    return <Alert severity="warning">Invalid request ID!</Alert>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangePropName = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (name, file) => {
    setFormValues({ ...formValues, [name]: file });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate required fields
    const requiredFields = [
      "user_id",
      "username",
      "companyName",
      "contactSalutation",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "mobile",
      "companyEmail",
      "contactEmail",
      "companyShortName",
      "fascia",
    ];

    for (const field of requiredFields) {
      if (!formValues[field]) {
        toast.error(`Please fill out the required field: ${field}`);
        const fieldElement = document.getElementsByName(field)[0];
        if (fieldElement) {
          fieldElement.scrollIntoView({ behavior: "smooth" });
          fieldElement.focus();
        }
        return;
      }
    }

    try {
      setAdding(true);
      const { data } = await axios.post(
        id
          ? `${API_ENDPOINT}admin/edit-exhibitor?id=${id}`
          : `${API_ENDPOINT}admin/add-exhibitor`,
        formValues,
        getHeader(ADMIN)
      );
      toast.success(data.message);
    } catch (error) {
      toastError(error);
    } finally {
      setAdding(false);
    }
  };

  async function loadStands(hallNum) {
    const hall = data?.halls?.find((item) => item.name === hallNum);

    if (hall) {
      try {
        const { data } = await axios.get(
          `${API_ENDPOINT}admin/get-stands-list?id=${hall._id}`,
          getHeader(ADMIN)
        );
        setStands(data);
      } catch (error) {}
    }
  }

  return (
    <Box>
      <BackBtn />
      <CustomCircularP show={loading} />
      <Container maxWidth="md">
        <Box component={Paper} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            {id ? `Update Exhibitor` : `Add Exhibitor`}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* User ID and Username */}
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="User ID"
                  name="user_id"
                  value={formValues.user_id}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Username"
                  name="username"
                  value={formValues.username}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Password"
                  name="password"
                  value={formValues.password}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Company Name"
                  name="companyName"
                  value={formValues.companyName}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              {/* Contact Person */}
              <Grid item xs={12} sm={3}>
                <FormControl size="small" fullWidth required>
                  <InputLabel>Salutation</InputLabel>
                  <Select
                    label="Salutation"
                    name="contactSalutation"
                    value={formValues.contactSalutation}
                    onChange={handleInputChange}
                  >
                    {["Mr.", "Mrs.", "Ms.", "Dr.", "Prof."].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4.5}>
                <TextField
                  size="small"
                  label="First Name"
                  name="contactFirstName"
                  value={formValues.contactFirstName}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4.5}>
                <TextField
                  size="small"
                  label="Last Name"
                  name="contactLastName"
                  value={formValues.contactLastName}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              {/* Contact Designation */}
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Contact Designation"
                  name="contactDesignation"
                  value={formValues.contactDesignation}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              {/* Contact Information */}
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Mobile"
                  name="mobile"
                  value={formValues.mobile}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Phone"
                  name="phone"
                  value={formValues.phone}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Company Email"
                  name="companyEmail"
                  value={formValues.companyEmail}
                  onChange={handleInputChange}
                  type="email"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Contact Email"
                  name="contactEmail"
                  value={formValues.contactEmail}
                  onChange={handleInputChange}
                  type="email"
                  fullWidth
                  required
                />
              </Grid>
              {/* Address */}
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Address Line 1"
                  name="address1"
                  value={formValues.address1}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Address Line 2"
                  name="address2"
                  value={formValues.address2}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Address Line 3"
                  name="address3"
                  value={formValues.address3}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              {/* Country, State, City */}
              <Grid item xs={12} sm={4}>
                <CountrySelector
                  selectedCountryCode={""}
                  selectedCountry={formValues.country || null}
                  onChange={(newV) => {
                    setFormValues({ ...formValues, country: newV });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StateSelector
                  countryCode={formValues.country?.code}
                  selectedState={formValues.state || null}
                  onChange={(newV) => {
                    setFormValues({ ...formValues, state: newV });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CitySelector
                  selectedCity={formValues.city || null}
                  onChange={(newV) => {
                    setFormValues({ ...formValues, city: newV });
                  }}
                  countryCode={formValues.country?.code || null}
                  stateCode={formValues.state?.code || null}
                />
              </Grid>
              {/* Pin, Fax */}
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Pin/Zip"
                  name="pin"
                  value={formValues.pin}
                  onChange={handleInputChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Fax"
                  name="fax"
                  value={formValues.fax}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              {/* Website */}
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Website"
                  name="website"
                  value={formValues.website}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              {/* Company Short Name, Fascia */}
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  label="Company Short Name"
                  name="companyShortName"
                  value={formValues.companyShortName}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fascia"
                  size="small"
                  name="fascia"
                  value={formValues.fascia}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="We Are"
                  name="weAre"
                  value={formValues.weAre}
                  onChange={handleInputChange}
                />
              </Grid>
              {/* Industry Sector */}
              <Grid item xs={12}>
                <IndustrySectorSelector
                  selected={formValues.industrySector}
                  onChange={(newV) =>
                    handleChangePropName("industrySector", newV)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl size="small" fullWidth required>
                  <InputLabel>Exhibitor Category</InputLabel>
                  <Select
                    label="Exhibitor Category"
                    name="exhibitorCategory"
                    value={formValues.exhibitorCategory}
                    onChange={handleInputChange}
                  >
                    {[
                      "IMTEX",
                      "TOOLTECH",
                      "DIGITAL MANUFACTURING",
                      "OTHERS",
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  size="small"
                  options={data?.halls || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    data?.halls.find(
                      (hall) => hall.name === formValues.hallNumber
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: "hallNumber",
                        value: newValue ? newValue.name : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hall Number"
                      name="hallNumber"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  size="small"
                  options={stands || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    stands.find(
                      (stand) => stand.name === formValues.standNumber
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: "standNumber",
                        value: newValue ? newValue.name : "",
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Stand Number"
                      name="standNumber"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Space Type</InputLabel>
                  <Select
                    label="Space Type"
                    name="spaceType"
                    value={formValues.spaceType}
                    onChange={handleInputChange}
                  >
                    {["Shell Space", "Raw Space"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Stand Size */}
              <Grid item xs={12}>
                <TextField
                  label="Stand Size"
                  name="standSize"
                  size="small"
                  type="number"
                  value={formValues.standSize}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              {/* Exhibit Arrival */}
              <Grid item xs={12} sm={4}>
                <MyDatePicker
                  label="Exhibit Arrival Date"
                  value={formValues.exhibitArrival}
                  onChange={(newV) =>
                    handleChangePropName("exhibitArrival", newV)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MyTimePicker
                  label="Exhibit Arrival Time From"
                  value={formValues.exhibitArrivalTimeFrom}
                  onChange={(value) =>
                    handleChangePropName("exhibitArrivalTimeFrom", value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MyTimePicker
                  label="Exhibit Arrival Time To"
                  value={formValues.exhibitArrivalTimeTo}
                  onChange={(value) =>
                    handleChangePropName("exhibitArrivalTimeTo", value)
                  }
                />
              </Grid>
              {/* Material Arrival */}
              <Grid item xs={12} sm={4}>
                <MyDatePicker
                  label="Material Arrival Date"
                  value={formValues.materialArrival}
                  onChange={(value) =>
                    handleChangePropName("materialArrival", value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MyTimePicker
                  label="Material Arrival Time From"
                  value={formValues.materialArrivalTimeFrom}
                  onChange={(value) =>
                    handleChangePropName("materialArrivalTimeFrom", value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MyTimePicker
                  label="Material Arrival Time To"
                  value={formValues.materialArrivalTimeTo}
                  onChange={(value) =>
                    handleChangePropName("materialArrivalTimeTo", value)
                  }
                />
              </Grid>
              {/* Dismantling */}
              <Grid item xs={12} sm={4}>
                <MyDatePicker
                  label="Dismantling Date"
                  value={formValues.dismantling}
                  onChange={(value) =>
                    handleChangePropName("dismantling", value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MyTimePicker
                  label="Dismantling Time From"
                  value={formValues.dismantlingTimeFrom}
                  onChange={(value) =>
                    handleChangePropName("dismantlingTimeFrom", value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MyTimePicker
                  label="Dismantling Time To"
                  value={formValues.dismantlingTimeTo}
                  onChange={(value) =>
                    handleChangePropName("dismantlingTimeTo", value)
                  }
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                  <Typography variant="body1" gutterBottom>
                    Profile Image
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {formValues.profile_image ? (
                      <Avatar
                        src={URL.createObjectURL(formValues.profile_image)}
                        sx={{ width: 80, height: 80, mr: 2 }}
                      />
                    ) : (
                      <Avatar sx={{ width: 80, height: 80, mr: 2 }} />
                    )}
                    <label htmlFor="profile_image">
                      <input
                        accept="image/*"
                        id="profile_image"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange("profile_image", e.target.files[0])
                        }
                      />
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" gutterBottom>
                    Profile Banner
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {formValues.profile_banner ? (
                      <Avatar
                        variant="square"
                        src={URL.createObjectURL(formValues.profile_banner)}
                        sx={{ width: 80, height: 80, mr: 2 }}
                      />
                    ) : (
                      <Avatar
                        variant="square"
                        sx={{ width: 80, height: 80, mr: 2 }}
                      />
                    )}
                    <label htmlFor="profile_banner">
                      <input
                        accept="image/*"
                        id="profile_banner"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange("profile_banner", e.target.files[0])
                        }
                      />
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload
                      </Button>
                    </label>
                  </Box>
                </Grid> */}

              <Grid item xs={12}>
                <LoadingButton
                  loading={adding}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                >
                  {id ? `Update Exhibitor` : `Submit Exhibitor`}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default ExhibitorForm;
