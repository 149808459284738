import { Typography } from "@mui/material";
import React from "react";

const RegistrationPreview = () => {
  return (
    <div>
      <Typography textAlign={"center"} variant="h6" color={"grey"}>
        No Preview Available currently
      </Typography>
    </div>
  );
};

export default RegistrationPreview;
