import React, { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  ListItemText,
  Chip,
} from "@mui/material";
import PropTypes from "prop-types";
import { API_ENDPOINT } from "../../utils/constants";
import useCommonGetInit from "../../hooks/useCommonGetInit";

const SelectSuppliers = ({ value, onChange }) => {
  const {
    data: suppliers,
    isLoading,
    isError,
    error,
  } = useCommonGetInit(`${API_ENDPOINT}admin/get-suppliers`);

  const handleChange = (event) => {
    const selectedIds = event.target.value.map(
      (selectedSupplier) => selectedSupplier._id
    );
    const newSelectedSuppliers = suppliers.filter((supplier) =>
      selectedIds.includes(supplier._id)
    );
    onChange(newSelectedSuppliers);
  };

  if (isLoading) return <Box>Loading...</Box>;
  if (isError) return <Box>Error: {error.message}</Box>;

  return (
    <Box sx={{ minWidth: 300 }}>
      <FormControl fullWidth>
        <InputLabel id="select-suppliers-label">Select Suppliers</InputLabel>
        <Select
          labelId="select-suppliers-label"
          multiple
          label="Select Suppliers"
          value={value}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((supplier) => (
                <Chip key={supplier._id} label={supplier.supplierName} />
              ))}
            </Box>
          )}
        >
          {suppliers.map((supplier) => (
            <MenuItem key={supplier._id} value={supplier}>
              <Checkbox
                checked={value.some(
                  (selected) => selected._id === supplier._id
                )}
              />
              <ListItemText primary={supplier.supplierName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

SelectSuppliers.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      supplierName: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectSuppliers;
