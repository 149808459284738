import {
  Alert,
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import BackBtn from "../../widgets/BackBtn";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import { API_ENDPOINT } from "../../utils/constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import MuPb from "../../widgets/MuPb";
import {
  extractDate,
  extractTime,
  formatMongoDate,
  getErrorResponse,
} from "../../utils/util";
import { LoadingButton } from "@mui/lab";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchBar from "../../widgets/searchBar";
import ExportVisitorsButton from "./ExportVisitorsButton";
import CustomCircularP from "../../widgets/CustomCircularP";
import { Link } from "react-router-dom";
export const ScrollTableContainer = styled(TableContainer)({
  overflowX: "auto",
});
const Visitors = () => {
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}user/a/get-visitors?page=${page}&sq=${sq}&r=${reload}`
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };
  const handleSearch = (e) => {
    const vl = e.target.value;
    setSq(vl); // Update sq state with search query
  };
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  if (isError) {
    return <Alert severity="warning">{getErrorResponse(error)}</Alert>;
  }
  const pageChange = (e, page) => {
    setPage(page);
  };

  return (
    <Box>
      <BackBtn />
      <CustomCircularP show={isLoading} />
      <Grid container mb={2} rowSpacing={2}>
        <Grid item md={2} alignContent={"center"}>
          <Stack direction={"row"} gap={2}>
            <Typography variant="h5">Visitors</Typography>
            <Typography alignSelf={"center"} variant="body1">
              count: {data?.totalItems}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={6}>
          <SearchBar
            placeholder={"Search by name/email"}
            size="small"
            onChange={debouncedHandleSearch}
          />
        </Grid>
        <Grid item md={4} alignContent={"center"}>
          <Stack direction={"row"}>
            <Box flex={1}></Box>
            <ExportVisitorsButton />
          </Stack>
        </Grid>
      </Grid>
      <Box>
        <ScrollTableContainer component={Paper}>
          <Table stickyHeader aria-label="scrollable table">
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>State</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Joined</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              ) : (
                data?.data?.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Avatar src={user.profile_image} alt={user.firstName} />
                    </TableCell>
                    <TableCell>
                      <Link to={`/home/visitor/${user._id}`}>
                        {user.title} {user.firstName} {user.lastName}
                      </Link>
                    </TableCell>
                    <TableCell>{user.designation}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.mobileNo}</TableCell>
                    <TableCell>{user.country?.name}</TableCell>
                    <TableCell>{user.stateRegion?.name}</TableCell>
                    <TableCell>{user.city?.name}</TableCell>
                    <TableCell>
                      {extractDate(user.createdOn)}{" "}
                      {extractTime(user.createdOn)}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </ScrollTableContainer>
      </Box>

      <Box display="flex" justifyContent="center" mt={2} mb={2}>
        {data && (
          <Pagination
            page={page}
            onChange={pageChange}
            count={Math.ceil(data.totalItems / 30)}
          />
        )}
      </Box>
    </Box>
  );
};

export default Visitors;
