import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import { API_ENDPOINT } from "../../utils/constants";
import { toastError } from "../../utils/util";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BackBtn from "../../widgets/BackBtn";

function AddEventForm() {
  const [formValues, setFormValues] = useState({
    eventName: "",
    eventSubdomain: "",
    country: "",
    city: "",
    sessionLifetime: "",
    phoneCode: "",
    eventDateFrom: null,
    eventDateTo: null,
    eventLogo: null,
    eventLogoPreview: null,
    eventBanner: null,
    eventBannerPreview: null,
    favicon: null,
    faviconPreview: null,
    isFloorPlanRequired: true,
    isOesFormRequired: true,
    isOnsiteModuleRequired: true,
    isMeetingSchedulerRequired: true,
    isMobileAppsRequired: true,
  });

  const [loading, setLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleInputChange = (field) => (event) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };
  const handleCheckboxChange = (field) => (event) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: event.target.checked,
    }));
  };

  const handleFileChange = (field, previewField) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setFormValues((prev) => ({
        ...prev,
        [field]: file,
        [previewField]: previewUrl,
      }));
    }
  };

  const handleDateChange = (field) => (date) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Front-end validation
    if (
      !formValues.eventName ||
      !formValues.eventSubdomain ||
      !formValues.country ||
      !formValues.city ||
      !formValues.sessionLifetime ||
      !formValues.phoneCode ||
      !formValues.eventDateFrom ||
      !formValues.eventDateTo ||
      !formValues.eventLogo ||
      !formValues.eventBanner ||
      !formValues.favicon
    ) {
      alert("Please fill out all required fields and upload all images.");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("eventName", formValues.eventName);
      formData.append("eventSubdomain", formValues.eventSubdomain);
      formData.append("country", formValues.country);
      formData.append("city", formValues.city);
      formData.append("sessionLifetime", formValues.sessionLifetime);
      formData.append("phoneCode", formValues.phoneCode);
      formData.append("eventDateFrom", formValues.eventDateFrom.toISOString());
      formData.append("eventDateTo", formValues.eventDateTo.toISOString());
      formData.append("eventLogo", formValues.eventLogo);
      formData.append("eventBanner", formValues.eventBanner);
      formData.append("favicon", formValues.favicon);
      formData.append("organiser", "IMTMA");
      formData.append("isFloorPlanRequired", formValues.isFloorPlanRequired);
      formData.append("isOesFormRequired", formValues.isOesFormRequired);
      formData.append(
        "isOnsiteModuleRequired",
        formValues.isOnsiteModuleRequired
      );
      formData.append(
        "isMeetingSchedulerRequired",
        formValues.isMeetingSchedulerRequired
      );
      formData.append("isMobileAppsRequired", formValues.isMobileAppsRequired);

      // Do not set 'Content-Type' header
      const response = await axios.post(
        `${API_ENDPOINT}super/add-event`,
        formData
      );

      console.log("Event added successfully:", response.data);

      // Show success modal
      setSuccessModalOpen(true);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setSuccessModalOpen(false);

    // Reset form if needed
    setFormValues({
      eventName: "",
      eventSubdomain: "",
      country: "",
      city: "",
      sessionLifetime: "",
      phoneCode: "",
      eventDateFrom: null,
      eventDateTo: null,
      eventLogo: null,
      eventLogoPreview: null,
      eventBanner: null,
      eventBannerPreview: null,
      favicon: null,
      faviconPreview: null,
      isFloorPlanRequired: true,
      isOesFormRequired: true,
      isOnsiteModuleRequired: true,
      isMeetingSchedulerRequired: true,
      isMobileAppsRequired: true,
    });
  };

  return (
    <Box>
      <BackBtn />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ maxWidth: 800, mx: "auto", p: 3 }}
        >
          <Typography variant="h4" gutterBottom>
            Add New Event
          </Typography>
          <Alert severity="info">
            Event you add from here will undergo approval from the Super Admin.{" "}
          </Alert>

          {/* Image Uploads */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Event Images
              </Typography>
              <Grid container spacing={2}>
                {/* Event Logo */}
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1">Event Logo</Typography>
                  {formValues.eventLogoPreview && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={formValues.eventLogoPreview}
                      alt="Event Logo Preview"
                      sx={{ mt: 1 }}
                    />
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Upload Logo
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleFileChange(
                        "eventLogo",
                        "eventLogoPreview"
                      )}
                    />
                  </Button>
                </Grid>

                {/* Event Banner */}
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1">Event Banner</Typography>
                  {formValues.eventBannerPreview && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={formValues.eventBannerPreview}
                      alt="Event Banner Preview"
                      sx={{ mt: 1 }}
                    />
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Upload Banner
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleFileChange(
                        "eventBanner",
                        "eventBannerPreview"
                      )}
                    />
                  </Button>
                </Grid>

                {/* Favicon */}
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1">Favicon</Typography>
                  {formValues.faviconPreview && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={formValues.faviconPreview}
                      alt="Favicon Preview"
                      sx={{ mt: 1 }}
                    />
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Upload Favicon
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleFileChange("favicon", "faviconPreview")}
                    />
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Event Details */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Event Details
              </Typography>
              <Grid container spacing={2}>
                {/* Event Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Event Name"
                    value={formValues.eventName}
                    onChange={handleInputChange("eventName")}
                    fullWidth
                    required
                  />
                </Grid>

                {/* Event Subdomain */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Event Subdomain"
                    value={formValues.eventSubdomain}
                    onChange={handleInputChange("eventSubdomain")}
                    fullWidth
                    required
                    helperText={`Full URL: https://${formValues.eventSubdomain}.imtma.expoplanner.in`}
                  />
                </Grid>

                {/* Country */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Country</InputLabel>
                    <Select
                      value={formValues.country}
                      onChange={handleInputChange("country")}
                      label="Country"
                    >
                      {/* Replace these options with dynamic country data as needed */}
                      <MenuItem value="India">India</MenuItem>
                      <MenuItem value="United States">United States</MenuItem>
                      <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* City */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    value={formValues.city}
                    onChange={handleInputChange("city")}
                    fullWidth
                    required
                  />
                </Grid>

                {/* Session Lifetime */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Session Lifetime (minutes)"
                    type="number"
                    value={formValues.sessionLifetime}
                    onChange={handleInputChange("sessionLifetime")}
                    fullWidth
                    required
                  />
                </Grid>

                {/* Default Phone Code */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel>Default Phone Code</InputLabel>
                    <Select
                      value={formValues.phoneCode}
                      onChange={handleInputChange("phoneCode")}
                      label="Default Phone Code"
                    >
                      {/* Replace these options with dynamic phone code data as needed */}
                      <MenuItem value="+91">+91 (India)</MenuItem>
                      <MenuItem value="+1">+1 (USA)</MenuItem>
                      <MenuItem value="+44">+44 (UK)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Event Dates */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Event Dates
              </Typography>
              <Grid container spacing={2}>
                {/* Event Date From */}
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Event Date From"
                    value={formValues.eventDateFrom}
                    onChange={handleDateChange("eventDateFrom")}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>

                {/* Event Date To */}
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Event Date To"
                    value={formValues.eventDateTo}
                    onChange={handleDateChange("eventDateTo")}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Module Requirements
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isFloorPlanRequired}
                        onChange={handleCheckboxChange("isFloorPlanRequired")}
                      />
                    }
                    label="Floor Plan Required"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isOesFormRequired}
                        onChange={handleCheckboxChange("isOesFormRequired")}
                      />
                    }
                    label="OES Form Required"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isOnsiteModuleRequired}
                        onChange={handleCheckboxChange(
                          "isOnsiteModuleRequired"
                        )}
                      />
                    }
                    label="Onsite Module Required"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isMeetingSchedulerRequired}
                        onChange={handleCheckboxChange(
                          "isMeetingSchedulerRequired"
                        )}
                      />
                    }
                    label="Meeting Scheduler Required"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.isMobileAppsRequired}
                        onChange={handleCheckboxChange("isMobileAppsRequired")}
                      />
                    }
                    label="Mobile Apps Required"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Submit Button */}
          <Button
            variant="contained"
            type="submit"
            size="large"
            fullWidth
            disabled={loading}
          >
            {loading ? "Submitting..." : "Add Event"}
          </Button>
        </Box>

        {/* Success Modal */}
        <Dialog
          open={successModalOpen}
          onClose={handleModalClose}
          maxWidth="xs"
          fullWidth
          aria-labelledby="submission-success-dialog"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={4}
            textAlign="center"
          >
            {/* Success Icon */}
            <CheckCircleIcon
              color="success"
              sx={{ fontSize: 60, mb: 2 }}
              aria-hidden="true"
            />

            {/* Dialog Title */}
            <DialogTitle id="submission-success-dialog">
              Submission Received
            </DialogTitle>

            {/* Dialog Content */}
            <DialogContent>
              <Typography variant="body1">
                Thank you for your submission. We will update you once your
                request is approved.
              </Typography>
            </DialogContent>

            {/* Dialog Actions */}
            <DialogActions>
              <Button
                onClick={handleModalClose}
                variant="contained"
                color="primary"
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </LocalizationProvider>
    </Box>
  );
}

export default AddEventForm;
