import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useCommonGetInit from "../../hooks/useCommonGetInit";
import {
  formatDateMMMddYYYY,
  getErrorResponse,
  getHeader,
  toastError,
} from "../../utils/util";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import AddFormItem from "./AddFormItem";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ADMIN, API_ENDPOINT } from "../../utils/constants";
import EditIcon from "@mui/icons-material/Edit"; // Ensure to install @mui/icons-material for icons
import SimpleYesNoDialog from "../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../widgets/CustomCircularP";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddSupplierItem from "./AddSupplierItem";

const SupplierItems = () => {
  const navigate = useNavigate();
  const [reloadItems, setReloadItems] = useState(false);
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const {
    data: formItems,
    isLoading,
    isError,
    error,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-suppliers?reload=${reloadItems}`
  );
  const [actionMode, setActionMode] = useState("add");
  const [showDeleteItem, setShowDeleteItem] = useState(false);
  const [deletingItem, setDeletingItem] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // State to control menu
  const [selectedItem, setSelectedItem] = useState(null); // Track selected item
  const open = Boolean(anchorEl);

  if (isLoading) {
    return <LinearProgress />;
  }
  if (isError) {
    return <Alert severity="error">{getErrorResponse(error)}</Alert>;
  }

  const handleMenuClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEditItem = (item) => {
    setActionMode("edit");
    setCurrentItem(item);
    handleMenuClose();
    setShowAddSupplier((prev) => !prev);
  };

  const handleDeleteItem = (item) => {
    setCurrentItem(item);
    handleMenuClose();
    setShowDeleteItem((prev) => !prev);
  };
  const handleAddItem = () => {
    setActionMode("add");
    setShowAddSupplier((prev) => !prev);
  };
  const onAddedItem = () => {
    setShowAddSupplier((prev) => !prev);
    setReloadItems((prev) => !prev);
  };
  const onYesDelete = async () => {
    setShowDeleteItem((prev) => !prev);
    if (!currentItem) {
      return;
    }
    try {
      setDeletingItem(true);
      const { data } = await axios.delete(
        `${API_ENDPOINT}admin/delete-supplier?id=${currentItem._id}`,
        getHeader(ADMIN)
      );
      setDeletingItem(false);
      toast.success(data.message);
      setReloadItems((prev) => !prev);
    } catch (error) {
      toastError(error);
    } finally {
      setDeletingItem(false);
    }
  };

  return (
    <Box>
      <CustomCircularP show={deletingItem} />
      <SimpleYesNoDialog
        title="Please confirm!"
        message="Are you sure you want to delete this?"
        show={showDeleteItem}
        setShow={setShowDeleteItem}
        onYes={onYesDelete}
      />
      <RdMyModal
        mWidth={"720px"}
        showModal={showAddSupplier}
        setShowModal={setShowAddSupplier}
        modalC={
          <AddSupplierItem
            onAdded={onAddedItem}
            show={showAddSupplier}
            mode={actionMode}
            editItem={currentItem}
          />
        }
      />
      <Stack direction={"row"} sx={{ marginBottom: 2 }}>
        <Box flex={1}></Box>
        <Button
          onClick={handleAddItem}
          size="small"
          startIcon={<AddIcon />}
          variant="outlined"
        >
          Add Supplier
        </Button>
      </Stack>
      {(formItems || []).length === 0 && (
        <Typography variant="h6" color={"#ccc"}>
          No supplier yet
        </Typography>
      )}
      {formItems?.map((item, index) => (
        <Card
          key={index}
          sx={{
            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 1,
          }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {item.supplierName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatDateMMMddYYYY(item.createdOn)}
            </Typography>
          </CardContent>
          <IconButton
            onClick={(event) => handleMenuClick(event, item)}
            aria-label="action"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open && selectedItem === item}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleEditItem(item)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              Edit Item
            </MenuItem>
            <MenuItem onClick={() => handleDeleteItem(item)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              Delete Item
            </MenuItem>
          </Menu>
        </Card>
      ))}
    </Box>
  );
};

export default SupplierItems;
