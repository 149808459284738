import { Box } from "@mui/material";
import React from "react";

const OnsiteFooter = () => {
  return (
    <Box component="footer">
      <img
        alt="footer"
        src="https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Footer.png"
        style={{
          display: "block",
          maxWidth: "100%",
          height: "auto",
        }}
      />
    </Box>
  );
};

export default OnsiteFooter;
