import { LoginOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import LogoutButton from "../widgets/LogoutButton";

const ChooseOption = () => {
  const navigate = useNavigate();
  return (
    <Box
      height={"100vh"}
      width={"100vw"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      bgcolor={"#f0f2f5"}
    >
      <LogoutButton bstyle={{ position: "fixed", top: 2, right: 2 }} />
      <Box p={3} textAlign="center">
        <Typography variant="h4" gutterBottom>
          What do you want to Build?
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Card
              sx={{
                minWidth: 275,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" component="div">
                  Registration Form
                </Typography>
                <Button
                  onClick={() => navigate("/reg-form-builder")}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Select
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                minWidth: 275,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" component="div">
                  OES Form
                </Typography>
                <Button
                  onClick={() => navigate("/oes-form")}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Select
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChooseOption;
