import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  Modal,
  Paper,
} from "@mui/material";

const templates = [
  {
    id: 1,
    title: "Registration Confirmation Email",
    description: "Confirm your registration with this email template.",
    body: "Dear {{name}},\n\nThank you for registering. Your account has been successfully created!",
  },
  {
    id: 2,
    title: "Password Reset Email",
    description: "Send password reset instructions to users.",
    body: "Hi {{name}},\n\nClick the link below to reset your password.\n\n{{reset_link}}",
  },
  {
    id: 3,
    title: "Forgot Password Email",
    description: "Remind users of their account details with this template.",
    body: "Dear {{name}},\n\nYou have requested to reset your password. Please follow the instructions.",
  },
  {
    id: 4,
    title: "OES Email Format",
    description: "Use this template for OES-related communications.",
    body: "Dear {{name}},\n\nThis is the official communication regarding OES.",
  },
];

const EmailTemplateCards = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);

  const handleOpenPreview = (template) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setSelectedTemplate(null);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Choose an Email Template
      </Typography>
      <Grid container spacing={3}>
        {templates.map((template) => (
          <Grid item xs={12} md={6} lg={4} key={template.id}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">{template.title}</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {template.description}
                </Typography>
                <Typography variant="body2" noWrap>
                  {template.body}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleOpenPreview(template)}
                >
                  Preview
                </Button>
                <Button size="small" variant="contained" color="primary">
                  Choose Template
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal for Full Email Preview */}
      <Modal
        open={openPreview}
        onClose={handleClosePreview}
        aria-labelledby="template-preview-modal"
        aria-describedby="template-preview-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" id="template-preview-modal">
            {selectedTemplate?.title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ whiteSpace: "pre-wrap", marginTop: "16px" }}
          >
            {selectedTemplate?.body}
          </Typography>
          <Box sx={{ textAlign: "center", marginTop: "20px" }}>
            <Button variant="contained" onClick={handleClosePreview}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EmailTemplateCards;
