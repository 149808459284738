import { createTheme } from "@mui/material";

export const ExpoTheme = createTheme({
  palette: {
    primary: {
      main: "#02475f",
    },
    secondary: {
      main: "#f50057",
    },
    lightprimary: {
      main: "#06698b",
      dark: "#0b7497",
    },
    // You can also add secondary, error, warning, info, success colors
  },
  components: {
    // Name of the component
    MuiAppBar: {
      styleOverrides: {
        // Name of the slot
        colorPrimary: {
          backgroundColor: "#02475f", // Your base color
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#f4f4f4", // A lighter color for the Drawer's background
        },
      },
    },
    // You can add more component overrides here
  },
});
