import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tabs,
  Tab,
  Autocomplete,
} from "@mui/material";
import CountrySelector from "../../widgets/CountrySelector";
import StateSelector from "../../widgets/StateSelector";
import CitySelector from "../../widgets/CitySelector";
import { MuiTelInput } from "mui-tel-input";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { toastError } from "../../utils/util";
import axios from "axios";
import { API_ENDPOINT } from "../../utils/constants";
import { getOnsiteHeader } from "./utils";
import { LoadingButton } from "@mui/lab";
import { printRow } from "./ImtexBadgeDimension";

const SPOT_REG_CATEGORIES = [
  "VISITOR",
  "EXHIBITOR",
  "DELEGATION",
  "VIP",
  "EC",
  "SERVICE",
  "MEDIA",
  "STUDENT",
];
const SpotRegistration = () => {
  const { id } = useParams();

  const [registration, setRegistration] = useState({
    title: "",
    firstName: "",
    lastName: "",
    designation: "",
    mobileNo: "",
    email: "",
    companyName: "",
    address: "",
    country: null,
    state: null,
    city: null,
    pinZip: "",
    website: "",
    telephoneNo: "",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/spot-register?type=${SPOT_REG_CATEGORIES[selectedTab]}`,
        registration,
        getOnsiteHeader(id)
      );
      toast.success(data.message);
      setTimeout(() => {
        printRow(data.user);
        setRegistration({
          title: "",
          firstName: "",
          lastName: "",
          designation: "",
          mobileNo: "",
          email: "",
          companyName: "",
          address: "",
          country: null,
          state: null,
          city: null,
          pinZip: "",
          website: "",
          telephoneNo: "",
        });
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Box mb={2}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Registration Categories Tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {SPOT_REG_CATEGORIES.map((category, index) => (
            <Tab key={index} label={category} />
          ))}
        </Tabs>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <FormControl required size="small" fullWidth>
              <InputLabel>Title</InputLabel>
              <Select
                value={registration.title}
                label="Title"
                onChange={(e) =>
                  setRegistration({ ...registration, title: e.target.value })
                }
              >
                {["Mr.", "Mrs.", "Ms.", "Miss.", "Dr.", "Prof."].map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* First Name */}
          <Grid item xs={12} sm={5}>
            <TextField
              size="small"
              required
              label="First Name"
              value={registration.firstName}
              onChange={(e) =>
                setRegistration({
                  ...registration,
                  firstName: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>
          {/* Last Name */}
          <Grid item xs={12} sm={5}>
            <TextField
              required
              size="small"
              label="Last Name"
              value={registration.lastName}
              onChange={(e) =>
                setRegistration({ ...registration, lastName: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={[
                "Chairman",
                "Managing Director",
                "CEO",
                "President",
                "Country Head",
                "Executive Director",
                "Vice President",
                "Sr. Vice President",
                "CTO",
                "COO",
                "CFO",
                "General Manager",
                "Sr. Director",
                "Director",
                "Deputy Director",
                "General Manager",
                "Manager",
                "Production Head",
                "Technician",
                "Engineer",
                "Machine Operator",
                "Production/ Plant/ Technical Head",
                "Trainees",
                "Consultant",
                "Executive",
                "Partner",
                "Advisor",
                "Programmer",
                "Contractor",
                "Engineer",
              ]}
              value={registration.designation}
              onChange={(event, newValue) =>
                setRegistration({ ...registration, designation: newValue })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                  required
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              required
              label={selectedTab === 7 ? "Institute Name" : "Company Name"}
              value={registration.companyName}
              onChange={(e) =>
                setRegistration({
                  ...registration,
                  companyName: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>
          {/* Address */}
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Address"
              value={registration.address}
              onChange={(e) =>
                setRegistration({ ...registration, address: e.target.value })
              }
              fullWidth
              multiline
              rows={2}
            />
          </Grid>
          {/* Country Selector */}
          <Grid item xs={12} sm={4}>
            <CountrySelector
              selectedCountryCode=""
              selectedCountry={registration.country || null}
              onChange={(newV) =>
                setRegistration({ ...registration, country: newV })
              }
            />
          </Grid>
          {/* State Selector */}
          <Grid item xs={12} sm={4}>
            <StateSelector
              countryCode={registration.country?.code}
              selectedState={registration.state || null}
              onChange={(newV) =>
                setRegistration({ ...registration, state: newV })
              }
            />
          </Grid>
          {/* City Selector */}
          <Grid item xs={12} sm={4}>
            <CitySelector
              selectedCity={registration.city || null}
              onChange={(newV) =>
                setRegistration({ ...registration, city: newV })
              }
              countryCode={registration.country?.code || null}
              stateCode={registration.state?.code || null}
            />
          </Grid>
          {/* PIN/ZIP */}
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="PIN/ZIP"
              value={registration.pinZip}
              onChange={(e) =>
                setRegistration({ ...registration, pinZip: e.target.value })
              }
              fullWidth
            />
          </Grid>
          {/* Website */}
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="Website"
              value={registration.website}
              onChange={(e) =>
                setRegistration({ ...registration, website: e.target.value })
              }
              fullWidth
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size="small"
              label="Email"
              type="email"
              value={registration.email}
              onChange={(e) =>
                setRegistration({ ...registration, email: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item sm={4}>
            <MuiTelInput
              fullWidth
              size="small"
              label="Mobile No"
              defaultCountry="IN"
              forceCallingCode
              value={registration.mobileNo}
              onChange={(newValue) =>
                setRegistration({ ...registration, mobileNo: newValue })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              label="Telephone No"
              value={registration.telephoneNo}
              onChange={(e) =>
                setRegistration({
                  ...registration,
                  telephoneNo: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              loading={submitting}
              style={{ marginTop: "20px" }}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SpotRegistration;
